'use strict';

angular.module('register.maaparandus').factory('MaaparandusToimikService', ['$resource', '$q', 'config', function ($resource, $q, config) {

    var maaparandusToimikService = {};

    maaparandusToimikService.maaparandusToimikDto = $resource(config.api + '/v1/public/register/maaparandus/toimik/:protsessId', {});

    maaparandusToimikService.getMaaparandusToimik = function (protsessId) {
        return maaparandusToimikService.maaparandusToimikDto.get({
            'protsessId': protsessId
        });
    };

    return maaparandusToimikService;
}

]);
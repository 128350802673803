'use strict';

angular.module('register.taimekaitse.vagamurgised').controller('TkvVagaMurgisedOtsingCtrl', function ($scope, $routeParams, $filter, $q, ngTableHelperFactory, StateReminderService, notifyService, domeenService, domeenEnum, TkvVagaMurgisedService, restParamUtil) {
        $scope.domeenEnum = domeenEnum;


        $scope.selection = 'tkv-vagamurgised';
        $scope.activeTab = 'tkvVagamurgised';

        $scope.filter = {};


        TkvVagaMurgisedService.getVagaMurgisedTkvdQuery().$promise.then(function (res) {
            $scope.availableTaimekaitsevahendid = res.entity;
        });


        $scope.registerStaatusKl = domeenService.klassifikaatorQuery(domeenEnum.REGISTER_STAATUS.nimi);

        $scope.preserveState = function () {
            StateReminderService.preserveState($scope);
        };
        if (StateReminderService.hasPreviousState()) {
            var state = StateReminderService.getPreviousState();
            $scope.stateGiven = true;
            $scope.tableParams = state.scope.tableParams;
            $scope.filter = state.scope.filter;
            $scope.tableParams.reload();
        }
        else {
            $scope.tableParams = ngTableHelperFactory.newNgTableParamsAutoLoad('/v1/public/registrid/myrgine-tkv-kasutus', $scope.filter, {
                'alguskuupaev': 'desc'
            });
        }
        $scope.search = function () {
            $scope.tableParams.search();
        };

    }
);


'use strict';

angular.module('register.maaparandus').factory('MaaparandusService', [
    '$resource', '$q', 'config',

    function ($resource, $q, config) {

        var MaaparandusService = {};

        MaaparandusService.maaparandusSysteemDto = $resource(config.api + '/v1/public/register/maaparandus/systeem/:kood', {});

        MaaparandusService.getMaaparandusSysteem = function (kood) {
            return MaaparandusService.maaparandusSysteemDto.get({
                'kood': kood
            }, function (result) {

            });
        };

        MaaparandusService.maaparandusEhitisDto = $resource(config.api + '/v1/public/register/maaparandus/systeem/:systeemiKood/ehitis/:kood', {});

        MaaparandusService.getMaaparandusEhitis = function(systeemiKood, kood) {
            return MaaparandusService.maaparandusEhitisDto.get({
                'systeemiKood': systeemiKood,
                'kood': kood

            }, function(result) {});
        };

        MaaparandusService.getMaaparandusEhitis = function(systeemiKood, kood, katastriyksused, tehnilisedAndmed) {
            return MaaparandusService.maaparandusEhitisDto.get({
                'systeemiKood': systeemiKood,
                'kood': kood,
                'katastriyksused': katastriyksused,
                'tehnilisedAndmed': tehnilisedAndmed

            }, function(result) {});
        };


        MaaparandusService.maaparandusEhitisTeenusDto = $resource(config.api + '/v1/public/register/maaparandus/systeem/:systeemiKood/ehitis/:kood/teenused', {});

        MaaparandusService.getMaaparandusEhitisTeenus = function(systeemiKood, kood) {
            return MaaparandusService.maaparandusEhitisTeenusDto.query({
                'systeemiKood': systeemiKood,
                'kood': kood
		}, function(result) {});
        };

        MaaparandusService.maaparandusSysteemTeenusDto = $resource(config.api + '/v1/public/register/maaparandus/systeem/:systeemiKood/teenused', {});

        MaaparandusService.getMaaparandusSysteemTeenus = function(kood) {
            return MaaparandusService.maaparandusSysteemTeenusDto.query({
                'systeemiKood': kood
            }, function(result) {});
        };

        return MaaparandusService;
    }

]);


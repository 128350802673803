'use strict';

angular.module('register.maaparandus').controller ('MaaparandusRegisterHeaderCtrl', function ($scope, $location) {

    $scope.tabs = [
        {title: 'maaparandus.tab.systeemiotsing', path: '/maaparandus/msr/systeemi-otsing', tabId: 'systeemiOtsing', visible: true},
        {title: 'maaparandus.tab.ehitiseotsing', path: '/maaparandus/msr/ehitise-otsing', tabId: 'ehitiseOtsing', visible: true},
        {title: 'maaparandus.tab.toimingud', path: '/maaparandus/msr/toimingud', tabId: 'toimingud', visible: true},
        {title: 'register.maaparandus.tab.raportid', path: '/maaparandus/msr/raportid', tabId: 'raportid', visible: true},
        {title: 'register.maaparandus.tab.toimik', path: '/maaparandus/msr/toimik', tabId: 'toimik', visible: true}

    ];

    if (!$scope.activeTab) {
      $location.path($scope.tabs[0].path);
    }

  }
);

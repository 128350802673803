angular.module(
  'pma.sysparam',
  [
    'ngResource'
  ]
).factory(
  'sysParamService',
  [
    '$resource', 'config',
    function($resource, config) {

      var VaartusQueryTemplate = $resource(
        config.api + '/v1/public/system/parameetrid/:kood/vaartus',
        {},
        {'get': {method:'GET', isArray:false, cache: false}}
      );

      return {
        getValueByCode: function (code, onSuccess, onFailure) {
          return VaartusQueryTemplate.get({kood: code}).$promise.then(onSuccess, onFailure);
        }
      };
    }
  ]
);

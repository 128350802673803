'use strict';

angular.module('register.taimekaitse.taimekaitsevahend.detailvaade').controller('TaimekaitseRegisterDetailvaadeHeaderCtrl', function ($scope, $location, $routeParams) {

        $scope.registerId = $routeParams.registerId;
        $scope.language = $routeParams.lang;


        if ($scope.language === 'en') {
            $scope.language = 'et';
        }
        else {
            $scope.language = 'en';
        }

    }
);

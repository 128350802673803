'use strict';

angular.module('register.maaparandus.teenus').controller('EhituslubaKeeldDetailvaadeCtrl',
    function($scope, $routeParams, TeenusDetail, TeenusMuutmine, MaaparandusTeenusService) {

        $scope.teenusId = parseInt($routeParams.teenusId);
        $scope.independent = true;

        function loadTeenusData(teenusId) {
            TeenusDetail.getDetailsForTeenus(teenusId).then(function(teenusData) {
                $scope.teenus = teenusData;
                $scope.teenusSeadeId = teenusData.teenusSeadistusId;
                $scope.kliendiNr = teenusData.kliendiNr;
                $scope.teenusId = teenusData.teenusId;
            });
        }

        function loadData() {

            loadTeenusData($scope.teenusId);

            MaaparandusTeenusService.getEhituslubaKeeld($scope.teenusId).$promise.then(function(keeldumiseTeatis) {
                $scope.keeldumiseTeatis = keeldumiseTeatis;
            });
        }

        loadData();

        $scope.avaTeenus = function (teenusId) {
            TeenusMuutmine.doRedirect(teenusId, false);
        };

    }
);

'use strict';

angular.module('register.taimekaitse.taimekaitsevahend', []).factory('TaimekaitsevahendService', ['$resource', 'config', '$q', function ($resource, config, $q) {

    
    var taimekaitsevahendService = {};

    taimekaitsevahendService.taimekaitsevahendNimiDto = $resource(config.api + '/v1/public/registrid/taimekaitsevahend/nimi/:partialNimi/', {});

    taimekaitsevahendService.getTaimekaitsevahendTypeaheadQuery = function (tekst) {
        return taimekaitsevahendService.taimekaitsevahendNimiDto.get({
            'partialNimi': tekst
        }, function (result) {

        });
    };

    taimekaitsevahendService.loaValdajaNimiDto = $resource(config.api + '/v1/public/registrid/taimekaitsevahend/valdaja-nimi/:partialNimi/', {});

    taimekaitsevahendService.getLoaValdajanimiTypeaheadQuery = function (tekst) {
        return taimekaitsevahendService.loaValdajaNimiDto.get({
            'partialNimi': tekst
        }, function (result) {

        });
    };

    taimekaitsevahendService.kaubakoodDto = $resource(config.api + '/v1/public/registrid/taimekaitsevahend/kaubakood/', {});

    taimekaitsevahendService.getKaubakood = function () {
        return taimekaitsevahendService.kaubakoodDto.get({
        }, function (result) {

        });
    };

    taimekaitsevahendService.ufiDto = $resource(config.api + '/v1/public/registrid/taimekaitsevahend/ufi/:partialNimi/', {});

    taimekaitsevahendService.getUfiTypeaheadQuery = function (tekst) {
        return taimekaitsevahendService.ufiDto.get({
            'partialNimi': tekst
        }, function (result) {

        });
    };

    return taimekaitsevahendService;
}]);
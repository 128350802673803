'use strict';

angular.module('commons.kaart').directive('kaartKihid', [
    function () {
        return {
            restrict: 'E',
            scope: {
                kaart: '=',
                showZoomInfo: '@'
            },
            controller: ['$scope', '$http', function(scope) {
                if (scope.showZoomInfo) {
                    scope.showZoomInfo = true;
                }
            }],
            replace:true,
            templateUrl: 'modules/commons/kaart/kaart-kihid.html'
        };
    }
]);
'use strict';

var pmaReg = angular.module('register.maaparandus');

pmaReg.controller('MaaparandusToimikCtrl', function ($scope, $routeParams, MaaparandusToimikService, TeenusMuutmine) {

    $scope.protsessId = $routeParams.protsessId;

    MaaparandusToimikService.getMaaparandusToimik($scope.protsessId).$promise.then(function (toimik) {
        $scope.toimik = toimik;
    });

    $scope.avaTeenus = function (teenusId, teenusTyyp) {
        if(teenusTyyp == 'TEENUS'){
            TeenusMuutmine.doRedirect(teenusId, false);
        } else if(teenusTyyp == 'ARHIIV_TAOTLUS'){
            $location.url('/maaparandus/arhiiv/taotlus/' + teenusId);
        } else if(teenusTyyp == 'ARHIIV_TEATIS'){
            $location.url('/maaparandus/arhiiv/teatis/' + teenusId);
        }
    };

});


'use strict';

angular.module('commons.tableutils', []).factory('ngTableHelperFactory', function($resource,
                                                                       $filter,
                                                                       config,
                                                                       generalUtil,
                                                                       restParamUtil,
                                                                       ngTableParams,
                                                                       domeenService) {

    // Factory initsializeerimine toimub vaid üks kord
    ngTableParams.prototype.search = function() {
        this.page(1);
        return this.reload();
    };

    ngTableParams.prototype.reset = function(filter) {
        if (filter) {
            this.settings().customFilter = filter;
        } else {
            generalUtil.undefineObjectProperties(this.settings().customFilter);
        }
        if (this.settings().fetch) {
            this.settings().fetch = false;
            // kui otsing on navigeeritud esimesest lehest edasi, siis esimesele lehele tagasi tulek
            // käivitab search() meetodi läbi ng-table's üles seatud watcher-i automaatselt
            if (this.$params.page === 1) {
                this.search();
            } else {
                this.page(1);
            }
        }
    };

    var initParams = function(conf) { // conf = { url, filter, sort, urlParams, unlimited, fetchOnLoad }
        return new ngTableParams({
            page: 1, // show first page
            count: angular.isDefined(conf.count) ? conf.count : config.paginatorDefaultPageSize,
            sorting: conf.sort
        }, {
            fetch: (typeof conf.fetchOnLoad !== 'undefined' && conf.fetchOnLoad),
            customFilter: conf.filter,
            total: 0,
            counts: config.paginatorPageSizes,
            getData: function($defer, params) {
                if (this.fetch) {
                    $resource(config.api + conf.url, angular.extend({
                        'filter': restParamUtil.encodeFilter(this.customFilter),
                        'sort': restParamUtil.encodeSortParams(params.$params.sorting),
                        'offset': conf.unlimited ? 0 : (params.page() - 1) * params.count(),
                        'limit': conf.unlimited ? 1000000 : params.count()
                    }, conf.urlParams)).get(function(data) {
                        params.total(data.totalCount);
                        if (conf.unlimited) {
                            params.count(data.totalCount);
                        }
                        $defer.resolve(data.entities);
                    }, function() {
                        $defer.resolve([]);
                        params.total(0);
                    });
                } else {
                    $defer.resolve([]);
                    params.total(0);
                }
                this.fetch = true;
            }
        });
    };

    var newLocalNgTableParams_ = function(items, defaultSort) {
        return new ngTableParams({
            page: 1,
            count: 10000,
            sorting: defaultSort
        }, {
            total: undefined,
            getData: function($defer, params) {
                var paramsSorting = params.sorting();
                if (_.isEmpty(paramsSorting)) {
                    return items;
                }
                if (_.size(paramsSorting) > 1) {
                    throw new Error('Toetatud on vaid ühe veeru järgi sorteerimine!');
                }

                var columnExpression = _(paramsSorting).chain().keys().first().value();
                if (columnExpression.startsWith('#')) {
                    var column = columnExpression.slice(1);
                    _.each(items, function(item) {
                        item.$$pmaSort = domeenService.getTekstInCurrentLanguage(_.getProperty(item, column));
                    });
                    var sign = paramsSorting[columnExpression] === 'asc' ? '+' : '-';
                    $defer.resolve($filter('orderBy')(items, [sign + '$$pmaSort']));
                } else {
                    $defer.resolve($filter('orderBy')(items, params.orderBy()));
                }
            }
        });
    };

    var newLocalNgTableParamsWithPaging_ = function(items, defaultSort) {
        return new ngTableParams({
            page: 1,
            count: config.paginatorDefaultPageSize,
            sorting: defaultSort
        }, {
            total: 0,
            getData: function($defer, params) {
                var paramsSorting = params.sorting();
                params.total(items.length);

                var offset = (params.page() - 1) * params.count();
                var limit = params.count();

                if (_.isEmpty(paramsSorting)) {
                    return items.slice(offset, offset + limit);
                }
                if (_.size(paramsSorting) > 1) {
                    throw new Error('Toetatud on vaid ühe veeru järgi sorteerimine!');
                }

                var columnExpression = _(paramsSorting).chain().keys().first().value();
                if (columnExpression.startsWith('#')) {
                    var column = columnExpression.slice(1);
                    _.each(outitems, function(item) {
                        item.$$pmaSort = domeenService.getTekstInCurrentLanguage(_.getProperty(item, column));
                    });
                    var sign = paramsSorting[columnExpression] === 'asc' ? '+' : '-';
                    $defer.resolve(($filter('orderBy')(items, [sign + '$$pmaSort'])).slice(offset, offset + limit));
                } else {
                    $defer.resolve(($filter('orderBy')(items, params.orderBy())).slice(offset, offset + limit));
                }

            }
        });
    };

    return {
        /** Tabel, mis pärib, pagineerib ja sorteerib backendis. Kogu vastav funktsionaalsus peab backendis realiseeritud olema. */
        newNgTableParams: function(urlOrConf, filter, sort) {
            if (typeof urlOrConf === 'string') {
                return initParams({
                    'url': urlOrConf,
                    'filter': filter,
                    'sort': sort
                });
            }
            return initParams(urlOrConf);
        },
        /** Tabel, mis pärib, pagineerib ja sorteerib backendis. Kogu vastav funktsionaalsus peab backendis realiseeritud olema. */
        newNgTableParamsAutoLoad: function(urlOrConf, filter, sort, count) {
            return initParams({
                'url': urlOrConf,
                'filter': filter,
                'sort': sort,
                'count': count,
                fetchOnLoad: true
            });
        },
        /**
         * Tabel, mis töötab olemasoleva 'list'i instantsi pealt. Pärib, pagineerib ja sorteerib frontendis etteantud listist.
         * Pagineerimine ja sorteerimine etteantud listi instantsi ei muuda.
         * Listi haldamine (elementide lisamine/kustutamine) jääb väljakutsuja ülesanneks.
         */
        newLocalNgTableParams: newLocalNgTableParams_,
        newLocalNgTableParamsWithPaging: newLocalNgTableParamsWithPaging_
    };

});

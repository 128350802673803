'use strict';

angular.module('teenus.redirect', []).factory('TeenusMuudaRedirect', function($resource, config) {
    return $resource(config.api + '/v1/public/teenused/:teenusId/redirect', {}, {
        get: {
            method: 'GET',
            isArray: false
        }
    });
});

angular.module('teenus.muutmine', []).service('TeenusMuutmine', function($q, $resource, $http, domeenEnum, TeenusMuudaRedirect, teenusNavigationService) {

    this.doRedirect = function(teenusId, hasRegisterVaade) {
        TeenusMuudaRedirect.get({
            teenusId: teenusId
        }, function(dto) {
            doNavigation(dto, hasRegisterVaade);
        });
    };

    function doNavigation(dto, hasRegisterVaade) {
        if (dto.sammNr == 4) {
            teenusNavigationService.navigateToSamm4(dto.teenusId, dto.teenusSeadistusMenetlusTyypKood, dto.teenusSeadistusKood);
        }

        if (dto.teenusSeadistusMenetlusTyypKood === domeenEnum.MENETLUS_TYYP.elemendid.LIHT) {
            teenusNavigationService.navigateToDetailvaade(dto.teenusId, 'liht');
        } else {
            teenusNavigationService.navigateToDetailvaade(dto.teenusId, dto.teenusSeadistusKood);
        }
    }

});

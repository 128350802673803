'use strict';

angular.module('commons.regio', []).factory('regioService', function($resource, $q) {

    var RegioService = {};

    var regioUrl = '/jgc_rest_lyh/geocode?gl=ee&output=json2&war=true&srs=EPSG:4326&st=1&ahn=true&ac=false&maxcount=10&rs=reverse&key=pmin';

    RegioService.getAddresses = function(searchValue) {

        var delay = $q.defer();
        $resource(regioUrl, {
            'q': searchValue
        }).query(function(data) {
            var addressList = [];
            if (Array.isArray(data)) {
                var i, j;
                for (i = 0; i < data.length; i++) {
                    if (Array.isArray(data[i].placemark)) {
                        for (j = 0; j < data[i].placemark.length; j++) {
                            addressList.push(data[i].placemark[j]);
                        }
                    }
                }
            }
            delay.resolve(addressList);
        }, function() {
            delay.reject('Error in regio service for query ' + searchValue);
        });
        return delay.promise;

    };

    RegioService.convertToAadress = function(regioAadress, aadress) {
        if (regioAadress && regioAadress.AddressDetails && regioAadress.AddressDetails.AddressLines) {
            aadress.aadress = regioAadress.address;
            aadress.detailid = {};
            for (var i = 0; i < regioAadress.AddressDetails.AddressLines.length; i++) {
                var fieldType = regioAadress.AddressDetails.AddressLines[i].Type;
                var fieldValue = regioAadress.AddressDetails.AddressLines[i].Value;
                if (fieldType === 'A1') {
                    aadress.detailid.tase1 = fieldValue;
                } else if (fieldType === 'A2') {
                    aadress.detailid.tase2 = fieldValue;
                } else if (fieldType === 'A3') {
                    aadress.detailid.tase3 = fieldValue;
                } else if (fieldType === 'A4') {
                    aadress.detailid.tase4 = fieldValue;
                } else if (fieldType === 'A5') {
                    aadress.detailid.tase5 = fieldValue;
                } else if (fieldType === 'A6') {
                    aadress.detailid.tase6 = fieldValue;
                } else if (fieldType === 'A7') {
                    aadress.detailid.tase7 = fieldValue;
                } else if (fieldType === 'A8') {
                    aadress.detailid.tase8 = fieldValue;
                } else if (fieldType === 'A1_EHAK') {
                    aadress.detailid.tase1Ehak = fieldValue;
                } else if (fieldType === 'A2_EHAK') {
                    aadress.detailid.tase2Ehak = fieldValue;
                } else if (fieldType === 'A3_EHAK') {
                    aadress.detailid.tase3Ehak = fieldValue;
                } else if (fieldType === 'POSTCODE') {
                    aadress.postiindeks = fieldValue;
                }
            }

            if (regioAadress.point && regioAadress.point.length === 2) {
                aadress.detailid.koordinaatX = regioAadress.point[0];
                aadress.detailid.koordinaatY = regioAadress.point[1];
            }

            // aadressi tekstiväljalt tuleb eemaldada riik
            if (aadress.aadress) {
                aadress.aadress = aadress.aadress.replace(', Eesti', '');
            }
        }
    };

    return RegioService;

});
'use strict';

angular.module('register.vaetis', []).factory('RegisterVaetisService', ['$resource', 'config', '$q', function ($resource, config, $q) {


    var vaetisService = {};

    vaetisService.kbRiikKlassifikaatorid = $resource(config.api + '/v1/public/register/vaetised/riigid');

    vaetisService.getRiikKlfid = function () {
        return vaetisService.kbRiikKlassifikaatorid.get({
        }, function (result) {

        });
    };
       
    vaetisService.customerList = $resource(config.api + '/v1/public/register/vaetised/klient/all');

    vaetisService.getCustomerList = function (vaetisTyyp) {
        return vaetisService.customerList.get({
            vaetisTyyp: vaetisTyyp
        }, function (result) {

        });
    };
    
    vaetisService.kaubandusLiigid = $resource(config.api + '/v1/public/register/vaetised/kaubandusliik/all');
    
    vaetisService.getKaubandusLiigid = function () {
        return vaetisService.kaubandusLiigid.get({
        }, function (result) {

        });
    };
    
    return vaetisService;
}]);
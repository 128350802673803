'use strict';

angular.module('register.mater').controller('MaterSpetsialistCtrl', function ($scope, $routeParams, MaterService) {

        $scope.ettevoteId = $routeParams.ettevoteId;
        $scope.spetsialistId = $routeParams.spetsialistId;

    MaterService.getSpetsialistByEttevoteIdAndSpetsialistId($scope.ettevoteId, $scope.spetsialistId).$promise.then(function (spetsialist) {
            $scope.spetsialist = spetsialist;
        });

    }
);

'use strict';

angular.module('commons.hide',[])
.directive('pmaShowHiddenRowContainer', function () {
    return {
        restrict: 'A',
        controller: function ($scope) {
            this.toggleRow = function() {
                this.manager.showInverse();
                this.row.toggle();
            };
            this.openRow = function() {
                this.manager.showAsOpened();
                this.row.open();
            };
            this.closeRow = function() {
                this.manager.showAsClosed();
                this.row.close();
            };
        }
    };
}).directive('pmaShowHiddenRowToggler', function () {
    return {
        restrict: 'A',
        require: '^pmaShowHiddenRowContainer',
        link: function (scope, iElement, iAttrs, pmaShowHiddenRowContainer, transcludeFn) {
            var icon = iElement.children().first();
            var manager = {
                showAsOpened: function() {
                    icon.removeClass(iAttrs.expandClass).addClass(iAttrs.collapseClass);
                },
                showAsClosed: function() {
                    icon.removeClass(iAttrs.collapseClass).addClass(iAttrs.expandClass);
                },
                showInverse: function() {
                    if (icon.hasClass(iAttrs.expandClass)) {
                        icon.removeClass(iAttrs.expandClass).addClass(iAttrs.collapseClass);
                    } else {
                        icon.removeClass(iAttrs.collapseClass).addClass(iAttrs.expandClass);
                    }
                }
            };
            
            iElement.bind('click', function (event) {
                pmaShowHiddenRowContainer.toggleRow();
            });
            
            pmaShowHiddenRowContainer.manager = manager;
        }
    };
}).directive('pmaShowHiddenRowOpener', function () {
    return {
        restrict: 'A',
        require: '^pmaShowHiddenRowContainer',
        link: function (scope, iElement, iAttrs, pmaShowHiddenRowContainer) {
            iElement.bind('click', function (event) {
                pmaShowHiddenRowContainer.openRow();
            });
        }
    };
}).directive('pmaShowHiddenRowCloser', function () {
    return {
        restrict: 'A',
        require: '^pmaShowHiddenRowContainer',
        link: function (scope, iElement, iAttrs, pmaShowHiddenRowContainer) {
            iElement.bind('click', function (event) {
                pmaShowHiddenRowContainer.closeRow();
            });
        }
    };
}).directive('pmaShowHiddenRow', function () {
    return {
        restrict: 'A',
        require: '^pmaShowHiddenRowContainer',
        scope: {
            invisibilityClass: '@invisibilityClass',
            opened: '=opened'
        },
        link: function (scope, iElement, iAttrs, pmaShowHiddenRowContainer) {
            var row = {
                toggle: function () {
                    iElement.toggleClass(scope.invisibilityClass);
                },
                open: function () {
                    if (iElement.hasClass(scope.invisibilityClass)) {
                        iElement.removeClass(scope.invisibilityClass);
                    }
                },
                close: function () {
                    if (!iElement.hasClass(scope.invisibilityClass)) {
                        iElement.addClass(scope.invisibilityClass);
                    }
                }
            };
            pmaShowHiddenRowContainer.row = row;
            
            if (iAttrs.opened && scope.opened) {
                pmaShowHiddenRowContainer.openRow();
            }
        }
    };
});


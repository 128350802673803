'use strict';

angular.module('teenus.seadistus', []).service('TeenusSeadistusOtsing', function($resource, $q, config) {

  this.leiaTeenusSeadistused = function(valdkondKood) {
    var delay = $q.defer();
    $resource(config.api + '/v1/public/system/teenus/seadistused', {
      'filter': 'valdkondKood::' + valdkondKood,
      'offset' : 0,
      'sort': 'nimi'
    }, {
      'get': {
        method: 'GET',
        isArray: false
      }
    }).get(function (result) {
      delay.resolve(result);
    });
    return delay.promise;
  };

});

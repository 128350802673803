'use strict';

angular.module('register.maaparandus').controller('MaaparandusEhitisHeaderCtrl',
    function ($scope, $location, $routeParams) {

        var systeemiKood = $routeParams.systeemiKood;
        var kood = $routeParams.kood;

        $scope.tabs = [
            {title: 'register.maaparandus.ehitis.pais', path: '/maaparandus/systeem/' + systeemiKood + '/ehitis/' + kood, tabId: 'ehitiseAndmed', visible: true},
            {title: 'register.maaparandus.ehitis.kaart.pais', path: '/maaparandus/systeem/' + systeemiKood + '/ehitis/' + kood + '/kaart', tabId: 'kaart', visible: true}
        ];

        if (!$scope.activeTab) {
            $location.path($scope.tabs[0].path);
        }

    }
);

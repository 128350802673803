'use strict';

angular.module('commons.rest', []).service('restParamUtil', function() {

    function isIncludableFilterValue(value) {
        return value !== undefined && value !== null && value !== '';
    }

    /* https://confluence.nortal.com/pages/viewpage.action?title=Code+conventions+for+REST&spaceKey=PLATFORM */
    this.encodeFilter = function(filter) {
        if (!filter) {
            return undefined;
        }

        var filterStr = '';

        for ( var field in filter) {
            var value = filter[field];
            if (isIncludableFilterValue(value)) {
                var parameterChunk = this.encodeFilterParam(field, value);
                if (isIncludableFilterValue(parameterChunk)) {
                    filterStr += parameterChunk + '|';
                }
            }
        }
        return filterStr.slice(0, -1);
    };

    this.encodeFilterParam = function(paramName, value) {
        if (angular.isObject(value)) {
            var paramSrt = '';
            if (angular.isArray(value)) {
                for ( var idx in value) {
                    paramSrt += value[idx] + '++';
                }
                return paramName + '::' + paramSrt.slice(0, -2);
            } else {
                for ( var valueFieldName in value) {
                    if (isIncludableFilterValue(value[valueFieldName])) {
                        paramSrt += paramName + this.capitalize(valueFieldName) + '::' + (angular.isDate(value[valueFieldName]) ? value[valueFieldName].getTime()
                            : value[valueFieldName]) + '|';
                    }
                }
                return paramSrt.slice(0, -1);
            }
        } else {
            return paramName + '::' + value;
        }
    };

    this.encodeSortParams = function(sortDef) {
        if (!sortDef) {
            return undefined;
        }
        var sortExpression = '';
        for ( var columnName in sortDef) {
            if (sortDef.hasOwnProperty(columnName)) {
                sortExpression += this.encodeSortParam(columnName, sortDef[columnName] === 'asc' ? true : false) + '|';
            }
        }
        return sortExpression.slice(0, -1);
    };

    this.encodeSortParam = function(sortColumnName, sortAsc) {
        if (!sortColumnName) {
            return undefined;
        }
        return (sortAsc ? '' : '-') + sortColumnName;
    };

    this.capitalize = function(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    };

});

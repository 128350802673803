'use strict';

angular.module('pma.translate', []).config(function (config, $translateProvider) {
    $translateProvider.useUrlLoader(config.api + '/v1/system/i18n');

    var languageCode = 'et';
    if (typeof config !== 'undefined' && typeof config.languageCode !== 'undefined') {
        languageCode = config.languageCode;
    }
    $translateProvider.preferredLanguage(languageCode);

    $translateProvider.useSanitizeValueStrategy('escape');
})
    .run(function($translate, $rootScope) {
        $translate.instant('', null, null, 'en', null); // Et initsialiseerida tõlked inglise keelest
        $rootScope.$on('$translateChangeSuccess', function () {
            $rootScope.showTkvPage = true;
        });
    })
;
'use strict';

angular.module('dokument.download', []).factory('FileDownloadService', ['config',
    function (config) {

        function getFileDownloadUrl(dokumentFailId) {
            return config.api + '/v1/public/teenus/dokumentfail/' + dokumentFailId + '/content';
        }

        function getPublicFileDownloadUrl(kood, dokumentFailId, objectId) {
            return config.api + '/v1/public/' + kood + '/dokumentfail/' + objectId + '/content/' + dokumentFailId;
        }

        return {
            getFileDownloadUrl: function (dokumentFailId) {
                return getFileDownloadUrl(dokumentFailId);
            },
            getPublicFileDownloadUrl: function (kood, objectId, dokumentFailId) {
                return getPublicFileDownloadUrl(kood, objectId, dokumentFailId);
            }
        };
    }
]);

'use strict';

angular.module('commons.truncate', [])
    .directive('pmaTruncate', function(config) {
        return {
            restrict: 'E',
            scope: {
                value: '=', // = string | array
                max: '=?', // = 'average-form' | 'average-table' | sümbolite arv
                placement: '=?'
            },
            templateUrl: 'modules/commons/pma-truncate.html',
            link: function($scope) {
                var text = '';
                if (angular.isArray($scope.value)) {
                    for (var i in $scope.value) {
                        text = text + $scope.value[i] + ', ';
                    }
                    while (text.endsWith(' ') || text.endsWith(',')) {
                        text = text.slice(-1);
                    }
                } else {
                    text = $scope.value;
                }

                if (!$scope.placement) {
                    $scope.placement = 'top';
                }

                var max;
                if (!$scope.max) {
                    max = config.truncateTableText;
                } else if ($scope.max === 'average-form') {
                    max = config.truncateFormText;
                } else if ($scope.max === 'average-table') {
                    max = config.truncateTableText;
                } else {
                    max = $scope.max;
                }

                if (text && text.length && text.length > max) {
                    $scope.visibleText = text.substring(0, max) + '...';
                    $scope.tooltipText = text;
                } else {
                    $scope.visibleText = text;
                }
            }
        };
    });

'use strict';

angular.module('register.mahe').controller('MaheRegisterDetailvaadeCtrl', function ($scope, MaheService, $routeParams, DokumentService) {
        var registerId = parseInt($routeParams.registerId);
        MaheService.getDetailidByRegisterId(registerId).$promise.then(function (result) {
            $scope.register = result;
            $scope.registerId = registerId;
        });

        $scope.currentYear = moment().year();
        $scope.lastYear = moment().year() - 1;
        $scope.getFileDownloadUrl = DokumentService.getToendavDokumentDownloadUrl;
        $scope.getToendavDokumentPDFDownloadByRegisterIdUrl = DokumentService.getToendavDokumentPDFDownloadByRegisterIdUrl;
    }
);


'use strict';

angular.module('register.taimekaitse.turustuskohad').controller('TkvTurustuskohadOtsingCtrl', function ($scope, $routeParams, $filter, $q, ngTableHelperFactory, StateReminderService, notifyService, domeenService, domeenEnum, TkvTurustuskohadService) {
        $scope.domeenEnum = domeenEnum;

        $scope.selection = 'tkv-turustuskohad';
        $scope.activeTab = 'tkvTurustuskohad';

        $scope.datePickerOptions = {};
        $scope.filter = {};
        $scope.search = function () {
            $scope.tableParams.search();
        };

        $scope.preserveState = function () {
            StateReminderService.preserveState($scope);
        };

        if (StateReminderService.hasPreviousState()) {
            var state = StateReminderService.getPreviousState();
            $scope.stateGiven = true;
            $scope.tableParams = state.scope.tableParams;
            $scope.filter = state.scope.filter;
            $scope.tableParams.reload();
        }
        else {
            $scope.tableParams = ngTableHelperFactory.newNgTableParamsAutoLoad('/v1/public/registrid/turustuskoht', $scope.filter, {
                'registerId': 'desc'
            });
        }


    }
);


'use strict';

angular.module('register.taimekaitse.taimekaitsevahend.detailvaade').directive('pmaTeTkvToimeainedDirective',
    function () {
        return {
            restrict: 'E',
            scope: {
                taimekaitsevahend: '=',
                language: '='
            },
            templateUrl: 'modules/taimekaitse/taimekaitsevahend/detailvaade/komponendid/3-tkv-toimeained/te-tk-tkv-toimeained-directive.html',
            link: function ($scope) {


                $scope.$watch('taotlus', function (newTaotlus, oldTaotlus) {
                });
            }
        };
    });

'use strict';

angular.module('register.maaparandus').controller('MaaparandusEhitisCtrl',
    function ($scope, $routeParams, $modal, $location, MaaparandusService, TeenusMuutmine, ngTableHelperFactory) {

        $scope.activeTab = 'ehitiseAndmed';
        $scope.systeemiKood = $routeParams.systeemiKood;
        $scope.kood = $routeParams.kood;

        $scope.katastriyksused = [];

        MaaparandusService.getMaaparandusEhitis($scope.systeemiKood, $scope.kood, false, true).$promise.then(function (ehitis) {
            $scope.ehitis = ehitis;

            $scope.katastriyksused = ehitis.katastriyksused;
            $scope.katastriyksusedTableParams = ngTableHelperFactory.newLocalNgTableParamsWithPaging($scope.katastriyksused, {'katastritunnus': 'desc'});
        });

        $scope.tehnilisedAndmed = function () {
            $modal.open({
                templateUrl: 'modules/maaparandus/ehitis/ehitis-tehnilised-andmed.html',
                controller: 'MaaparandusEhitisTehnilisedAndmedCtrl',
                windowClass: 'wide',
                backdrop: 'static',
                resolve: {
                    'ehitis': function () {
                        return angular.copy($scope.ehitis);
                    }
                }
            }).result.then(function () {
            });
        };

        MaaparandusService.getMaaparandusEhitisTeenus($scope.systeemiKood, $scope.kood).$promise.then(function (teenused) {
            $scope.teenused = teenused;

            $scope.teenusedTableParams = ngTableHelperFactory.newLocalNgTableParamsWithPaging($scope.teenused, {'loomiskp': 'desc'});
        });

        $scope.avaTeenus = function (teenusId, teenusTyyp) {
            if (teenusTyyp == 'TEENUS') {
                TeenusMuutmine.doRedirect(teenusId, false);
            } else if (teenusTyyp == 'ARHIIV_TAOTLUS') {
                $location.url('/maaparandus/arhiiv/taotlus/' + teenusId);
            } else if (teenusTyyp == 'ARHIIV_TEATIS') {
                $location.url('/maaparandus/arhiiv/teatis/' + teenusId);
            }
        };

        $scope.laeKinnistuAndmed = function (){
            MaaparandusService.getMaaparandusEhitis($scope.systeemiKood, $scope.kood, true, true).$promise.then(function (ehitis) {
                $scope.katastriyksused.splice(0, $scope.katastriyksused.length);
                angular.forEach(ehitis.katastriyksused, function(k){
                    $scope.katastriyksused.push(k);});
                $scope.katastriyksusedTableParams.reload();
            });
        };

    }
);
'use strict';

angular.module('domeen.enum', []
).constant('domeenEnum', {
    ADS_TASE_1: {
        nimi: 'ADS_TASE_1',
        elemendid: {
            HARJU: '0037'
        }
    },
    ADS_TASE_2: {
        nimi: 'ADS_TASE_2'
    },
    ADS_TASE_3: {
        nimi: 'ADS_TASE_3'
    },
    ADS_TASE_1_OTSING: {
        nimi: 'ADS_TASE_1_OTSING'
    },
    DOKUMENT_TYYP: {
        nimi: 'DOKUMENT_TYYP',
        elemendid: {
            TAOTLUS: 'DOKUMENT_TYYP_TAOTLUS',
            TEATIS: 'DOKUMENT_TYYP_TEATIS',
            VOLITUS: 'DOKUMENT_TYYP_VOLITUS',
            MULLAPROOV_PROTOKOLL: 'DOKUMENT_TYYP_MULLAPROOV_PROTOKOLL',
            VALISRIIK_SERTIFIKAAT: 'DOKUMENT_TYYP_VALISRIIK_SERTIFIKAAT',
            KATSE_PROTOKOLL: 'DOKUMENT_TYYP_KATSE_PROTOKOLL',
            TEHASE_SERTIFIKAAT: 'DOKUMENT_TYYP_TEHASE_SERTIFIKAAT',
            MUU: 'DOKUMENT_TYYP_MUU'
        }
    },
    PMA_SE_PARTII_TEGEVUS_TYYP: {
        nimi: 'PMA_SE_PARTII_TEGEVUS_TYYP',
        elemendid: {
            MUU: 'PMA_SE_PARTII_TEGEVUS_TYYP_MUU',
            SERTIFITSEERIMINE: 'PMA_SE_PARTII_TEGEVUS_TYYP_SERTIFITSEERIMINE'
        }
    },
    PMA_ALLKIRJASTAMINE_VIIS: {
        nimi: 'PMA_ALLKIRJASTAMINE_VIIS',
        elemendid: {
            ID_KAART: 'PMA_ALLKIRJASTAMINE_VIIS_ID_KAART'
        }
    },
    PMA_PROTOKOLL_NIMI: {
        nimi: 'PMA_PROTOKOLL_NIMI',
        elemendid: {
            SE_POLDTUNNUSTAMINE: 'PMA_PROTOKOLL_NIMI_SE_POLDTUNNUSTAMINE',
            TT_POLDTUNNUSTAMINE: 'PMA_PROTOKOLL_NIMI_TT_POLDTUNNUSTAMINE',
            SE_SEEMNEPROOV_VOTMINE: 'PMA_PROTOKOLL_NIMI_SE_SEEMNEPROOV_VOTMINE',
            TT_1: 'PMA_PROTOKOLL_NIMI_TT_TT1',
            TT_10: 'PMA_PROTOKOLL_NIMI_TT_TT10'
        }
    },
    PMA_PROTOKOLL_NOUE: {
        nimi: 'PMA_PROTOKOLL_NOUE'
    },
    PMA_NOUE_GRUPP: {
        nimi: 'PMA_NOUE_GRUPP'
    },
    PMA_PUUDUS_STAATUS: {
        nimi: 'PMA_PUUDUS_STAATUS',
        elemendid: {
            UUS: 'PMA_PUUDUS_STAATUS_UUS',
            LAHENDAMISEL: 'PMA_PUUDUS_STAATUS_LAHENDAMISEL',
            KORRAS: 'PMA_PUUDUS_STAATUS_KORRAS',
            MITTEAKTIIVNE: 'PMA_PUUDUS_STAATUS_MITTEAKTIIVNE'
        }
    },
    PMA_PUUDUS_TYYP: {
        nimi: 'PMA_PUUDUS_TYYP',
        elemendid: {
            VALI_TAITMATA: 'PMA_PUUDUS_TYYP_VALI_TAITMATA',
            DOKUMENT_LISAMATA: 'PMA_PUUDUS_TYYP_DOKUMENT_LISAMATA',
            RIIGILOIV_TASUMATA: 'PMA_PUUDUS_TYYP_RIIGILOIV_TASUMATA',
            MUU: 'PMA_PUUDUS_TYYP_MUU'
        }
    },
    PMA_SORT_OMANIK_PALJUNDUS_KINNITUS: {
        nimi: 'PMA_SORT_OMANIK_PALJUNDUS_KINNITUS',
        elemendid: {
            LUBATUD: 'PMA_SORT_OMANIK_PALJUNDUS_KINNITUS_LUBATUD'
        }
    },
    PMA_TT_FYTO_TYYP: {
        nimi: 'PMA_TT_FYTO_TYYP',
        elemendid: {
            REEXPORT: 'PMA_TT_FYTO_TYYP_REEXPORT'
        }
    },
    PMA_TT_FYTO_TRANSPORT_VAHEND: {
        nimi: 'PMA_TT_FYTO_TRANSPORT_VAHEND'
    },
    PMA_TT_FYTO_SISSEVEDU_PUNKT: {
        nimi: 'PMA_TT_FYTO_SISSEVEDU_PUNKT',
        elemendid: {
            MUU: 'PMA_TT_FYTO_SISSEVEDU_PUNKT_MUU'
        }
    },
    PMA_TT_FYTO_KN_KOOD: {
        nimi: 'PMA_TT_FYTO_KN_KOOD'
    },
    PMA_TT_FYTO_LISA_DEKLARATSIOON: {
        nimi: 'PMA_TT_FYTO_LISA_DEKLARATSIOON'
    },
    PMA_TT_FYTO_TAIM_LIIK: {
        nimi: 'PMA_TT_FYTO_TAIM_LIIK'
    },
    PMA_TT_FYTO_SAADETIS_YHIK: {
        nimi: 'PMA_TT_FYTO_SAADETIS_YHIK'
    },
    PMA_TT_FYTO_TOOTLEMIS_MEETOD: {
        nimi: 'PMA_TT_FYTO_TOOTLEMIS_MEETOD'
    },
    PMA_TT_FYTO_VALJASTAMISKOHT: {
        nimi: 'PMA_TT_FYTO_VALJASTAMISKOHT'
    },
    PMA_TT_KAUP_TAIMNE_SAADUS: {
        nimi: 'PMA_TT_KAUP_TAIMNE_SAADUS'
    },
    PMA_TT_PROTOKOLL_LABIVIIDUD_TOOTLUS: {
        nimi: 'PMA_TT_PROTOKOLL_LABIVIIDUD_TOOTLUS'
    },
    PMA_TT_KARTULIPOLD_PRAAKIMISPOHJUS: {
        nimi: 'PMA_TT_KARTULIPOLD_PRAAKIMISPOHJUS',
        elemendid: {
            MUU: 'PMA_TT_KARTULIPOLD_PRAAKIMISPOHJUS_MUU'
        }
    },
    PMA_TT_TT7_OTSUS: {
        nimi: 'PMA_TT_TT7_OTSUS'
    },
    PMA_TAIM_KULTUUR: {
        nimi: 'PMA_TAIM_KULTUUR'
    },
    PMA_TT_ANALYYS_LABOR_TAHIS: {
        nimi: 'PMA_TT_ANALYYS_LABOR_TAHIS'
    },
    PMA_TT_ANALYYS_MARKUS: {
        nimi: 'PMA_TT_ANALYYS_MARKUS'
    },
    PMA_TT_ANALYYS_MATERJAL: {
        nimi: 'PMA_TT_ANALYYS_MATERJAL'
    },
    PMA_TT_ANALYYS_MEETOD_TAHIS: {
        nimi: 'PMA_TT_ANALYYS_MEETOD_TAHIS',
        elemendid: {
            PMA_TT_ANALYYS_MEETOD_TAHIS_PCR: 'PMA_TT_ANALYYS_MEETOD_TAHIS_PCR',
            PMA_TT_ANALYYS_MEETOD_TAHIS_MIKROSKOPEERIMISE_MEETOD: 'PMA_TT_ANALYYS_MEETOD_TAHIS_MIKROSKOPEERIMISE_MEETOD'
        }
    },
    PMA_TT_ANALYYS_OHTLIK_KAHJUSTAJA: {
        nimi: 'PMA_TT_ANALYYS_OHTLIK_KAHJUSTAJA',
        elemendid: {
            GLOBODERA_SPP_KARTULI_KIDUUSS: 'PMA_TT_ANALYYS_OHTLIK_KAHJUSTAJA_GLOBODERA_SPP_KARTULI_KIDUUSS',
            GLOBODERA_PALLIDA_VALKJAS_KARTULI_KIDUUSS: 'PMA_TT_ANALYYS_OHTLIK_KAHJUSTAJA_GLOBODERA_PALLIDA_VALKJAS_KARTULI_KIDUUSS',
            GLOBODERA_ROSTOCHIENSIS_KOLLANE_KARTULI_KIDUUSS: 'PMA_TT_ANALYYS_OHTLIK_KAHJUSTAJA_GLOBODERA_ROSTOCHIENSIS_KOLLANE_KARTULI_KIDUUSS'
        }
    },
    PMA_TT_ANALYYS_UURIMINE_EESMARK: {
        nimi: 'PMA_TT_ANALYYS_UURIMINE_EESMARK'
    },
    PMA_TT_ANALYYS_MONITOORING: {
        nimi: 'PMA_TT_ANALYYS_MONITOORING'
    },
    PMA_TT_POLDTUN_PRAAKIMISPOHJUS: {
        nimi: 'PMA_TT_POLDTUN_PRAAKIMISPOHJUS',
        elemendid: {
            MUU: 'PMA_TT_POLDTUN_PRAAKIMISPOHJUS_MUU'
        }
    },
    PMA_TT_POLDTUN_UMBROHTUMINE: {
        nimi: 'PMA_TT_POLDTUN_UMBROHTUMINE'
    },
    PMA_TT_PROTOKOLL_KOHT: {
        nimi: 'PMA_TT_PROTOKOLL_KOHT'
    },
    PMA_TT_PROTOKOLL_KOGUS_YHIK: {
        nimi: 'PMA_TT_PROTOKOLL_KOGUS_YHIK'
    },
    PMA_TT_PROTOKOLL_TT11_KOHT: {
        nimi: 'PMA_TT_PROTOKOLL_TT11_KOHT'
    },
    PMA_TT_ANALYYS_TULEMUS_VORM_TYYP: {
        nimi: 'PMA_TT_ANALYYS_TULEMUS_VORM_TYYP',
        elemendid: {
            PMA_TT_ANALYYS_TULEMUS_VORM_TYYP_N1: 'PMA_TT_ANALYYS_TULEMUS_VORM_TYYP_N1',
            PMA_TT_ANALYYS_TULEMUS_VORM_TYYP_N2: 'PMA_TT_ANALYYS_TULEMUS_VORM_TYYP_N2',
            PMA_TT_ANALYYS_TULEMUS_VORM_TYYP_V: 'PMA_TT_ANALYYS_TULEMUS_VORM_TYYP_V'
        }
    },
    PMA_TT_PROTOKOLL_SISSEVEDU_STAATUS: {
        nimi: 'PMA_TT_PROTOKOLL_SISSEVEDU_STAATUS',
        elemendid: {
            KEELATUD: 'PMA_TT_PROTOKOLL_SISSEVEDU_STAATUS_KEELATUD',
            PEATATUD: 'PMA_TT_PROTOKOLL_SISSEVEDU_STAATUS_PEATATUD'
        }
    },
    PMA_TT_PROTOKOLL_KAUBASAADETIS_DOKUMENDI_LIIK: {
        nimi: 'PMA_TT_PROTOKOLL_KAUBASAADETIS_DOKUMENDI_LIIK'
    },
    PMA_TT_PROTOKOLL_MITTEVASTAVUS_OIGUSLIK_ALUS: {
        nimi: 'PMA_TT_PROTOKOLL_MITTEVASTAVUS_OIGUSLIK_ALUS'
    },
    PMA_TT_PROTOKOLL_MITTEVASTAVUS_KIRJELDUS: {
        nimi: 'PMA_TT_PROTOKOLL_MITTEVASTAVUS_KIRJELDUS'
    },
    PMA_TT_PROTOKOLL_KOHUSTUSLIK_MEEDE: {
        nimi: 'PMA_TT_PROTOKOLL_KOHUSTUSLIK_MEEDE'
    },
    PMA_TT_PROTOKOLL_MITTEVASTAVUS_ULATUS: {
        nimi: 'PMA_TT_PROTOKOLL_MITTEVASTAVUS_ULATUS'
    },
    PMA_ANALYYS_TELLIMUS_STAATUS: {
        nimi: 'PMA_ANALYYS_TELLIMUS_STAATUS',
        elemendid: {
            MUSTAND: 'PMA_ANALYYS_TELLIMUS_STAATUS_MUSTAND',
            PROOV_VOETUD: 'PMA_ANALYYS_TELLIMUS_STAATUS_PROOV_VOETUD',
            SAADETUD_JARELKONTROLLI: 'PMA_ANALYYS_TELLIMUS_STAATUS_SAADETUD_JARELKONTROLLI',
            ANALUUSITUD: 'PMA_ANALYYS_TELLIMUS_STAATUS_ANALUUSITUD',
            LABORISSE_JOUDNUD: 'PMA_ANALYYS_TELLIMUS_STAATUS_LABORISSE_JOUDNUD',
            ANALUUSIMISEL: 'PMA_ANALYYS_TELLIMUS_STAATUS_ANALUUSIMISEL',
            ANALUUS_TEOSTATUD: 'PMA_ANALYYS_TELLIMUS_STAATUS_ANALUUS_TEOSTATUD'
        }
    },
    PMA_TT_PROTOKOLL_MATERJAL: {
        nimi: 'PMA_TT_PROTOKOLL_MATERJAL'
    },
    PMA_TT_PROTOKOLL_KAUP_MATERJAL: {
        nimi: 'PMA_TT_PROTOKOLL_KAUP_MATERJAL'
    },
    PMA_PROOV_YHIK: {
        nimi: 'PMA_PROOV_YHIK'
    },
    PMA_PROOV_NIMI: {
        nimi: 'PMA_PROOV_NIMI'
    },
    MENETLUS_TYYP: {
        nimi: 'MENETLUS_TYYP',
        elemendid: {
            LIHT: 'MENETLUS_TYYP_LIHT'
        }
    },
    PARAMEETER_TYYP: {
        nimi: 'PARAMEETER_TYYP',
        elemendid: {
            NUMBER: 'PARAMEETER_TYYP_NUMBER',
            TEKST: 'PARAMEETER_TYYP_TEKST'
        }
    },
    REGISTER_STAATUS: {
        nimi: 'REGISTER_STAATUS',
        elemendid: {
            KEHTIV: 'REGISTER_STAATUS_KEHTIV',
            PEATATUD: 'REGISTER_STAATUS_AJUTISELT_PEATATUD',
            KEHTETU: 'REGISTER_STAATUS_KEHTETU'

        }
    },

    PMA_ANALYYS_TULEMUS_DOKUMENT_TYYP: {
        nimi: 'PMA_ANALYYS_TULEMUS_DOKUMENT_TYYP'
    },

    PMA_MAAKONNAKESKUS: {
        nimi: 'PMA_MAAKONNAKESKUS',
        elemendid: {
            HARJU: 'PMA_HARJU'
        }
    },
    PMA_MP_PROTOKOLL_FOTOAPARAAT: {
        nimi: 'PMA_MP_PROTOKOLL_FOTOAPARAAT'
    },

    PMA_MP_PROTOKOLL_GPS: {
        nimi: 'PMA_MP_PROTOKOLL_GPS'
    },

    PMA_MP_PROTOKOLL_KALDEMOODIK: {
        nimi: 'PMA_MP_PROTOKOLL_KALDEMOODIK'
    },

    PMA_MP_PROTOKOLL_KAUGUSMOOTJA: {
        nimi: 'PMA_MP_PROTOKOLL_KAUGUSMOOTJA'
    },

    PMA_MP_PROTOKOLL_MOODULINT: {
        nimi: 'PMA_MP_PROTOKOLL_MOODULINT'
    },

    PMA_MP_PROTOKOLL_MOOTERATAS: {
        nimi: 'PMA_MP_PROTOKOLL_MOOTERATAS'
    },

    PMA_MP_PROTOKOLL_NIVELLIIR: {
        nimi: 'PMA_MP_PROTOKOLL_NIVELLIIR'
    },

    PMA_MP_PROTOKOLL_YLEVAATUSE_EESMARK: {
        nimi: 'PMA_MP_PROTOKOLL_YLEVAATUSE_EESMARK'
    },

    PMA_SE_ANALYYS_MEETOD_TYYP: {
        nimi: 'PMA_SE_ANALYYS_MEETOD_TYYP',
        elemendid: {
            ISTA_3: 'PMA_SE_ANALYYS_MEETOD_TYYP_ISTA_3',
            ISTA_4: 'PMA_SE_ANALYYS_MEETOD_TYYP_ISTA_4',
            ISTA_5: 'PMA_SE_ANALYYS_MEETOD_TYYP_ISTA_5',
            ISTA_6: 'PMA_SE_ANALYYS_MEETOD_TYYP_ISTA_6',
            ISTA_7: 'PMA_SE_ANALYYS_MEETOD_TYYP_ISTA_7',
            ISTA_7_013: 'PMA_SE_ANALYYS_MEETOD_TYYP_ISTA_7_013*',
            ISTA_8: 'PMA_SE_ANALYYS_MEETOD_TYYP_ISTA_8',
            ISTA_9: 'PMA_SE_ANALYYS_MEETOD_TYYP_ISTA_9',
            ISTA_10: 'PMA_SE_ANALYYS_MEETOD_TYYP_ISTA_10',
            GOST_12045_81: 'PMA_SE_ANALYYS_MEETOD_TYYP_GOST_12045_81*',
            PMK_TML: 'PMA_SE_ANALYYS_MEETOD_TYYP_PMK_TML_12005*'
        }
    },
    PMA_SE_IDANEVUS_REZIIM: {
        nimi: 'PMA_SE_IDANEVUS_REZIIM'
    },
    PMA_SE_IDANEVUS_REZIIM_AEG: {
        nimi: 'PMA_SE_IDANEVUS_REZIIM_AEG'
    },
    PMA_SE_EELJAHUTUS_AEG: {
        nimi: 'PMA_SE_EELJAHUTUS_AEG'
    },
    PMA_SE_EELVILI: {
        nimi: 'PMA_SE_EELVILI'
    },
    PMA_SE_KATEGOORIA: {
        nimi: 'PMA_SE_KATEGOORIA'
    },
    PMA_TAIM_KULTUUR_GRUPP: {
        nimi: 'PMA_TAIM_KULTUUR_GRUPP'
    },
    PMA_TAIM_GRUPP:{
        nimi: 'PMA_TAIM_GRUPP'
    },
    PMA_TAIM_LIIK: {
        nimi: 'PMA_TAIM_LIIK',
        elemendid: {
            KARTUL: 'PMA_TAIM_LIIK_HARILIK_KARTUL'
        }
    },
    PMA_TAIM_SORT: {
        nimi: 'PMA_TAIM_SORT'
    },
    PMA_TT_KAUP: {
        nimi: 'PMA_TT_KLASS'
    },
    PMA_TT_KLASS: {
        nimi: 'PMA_TT_KLASS'
    },
    PMA_TT_PROTOKOLL_MUGUL_ANALYYS_MADANIK: {
        nimi: 'PMA_TT_PROTOKOLL_MUGUL_ANALYYS_MADANIK'
    },
    PMA_TT_PROTOKOLL_MUGUL_ANALYYS_KAHJUSTAJA: {
        nimi: 'PMA_TT_PROTOKOLL_MUGUL_ANALYYS_KAHJUSTAJA'
    },
    PMA_TT_PROTOKOLL_MUGUL_ANALYYS_MUU: {
        nimi: 'PMA_TT_PROTOKOLL_MUGUL_ANALYYS_MUU'
    },
    PMA_TT_PROTOKOLL_TRANSPORDIVAHEND: {
        nimi: 'PMA_TT_PROTOKOLL_TRANSPORDIVAHEND'
    },
    PMA_VAETIS_EMTAK_TEGEVUSALA: {
        nimi: 'PMA_VAETIS_EMTAK_TEGEVUSALA'
    },
    PMA_VAETIS_KN_KOOD: {
        nimi: 'PMA_VAETIS_KN_KOOD'
    },
    PMA_VAETIS_KOOSTIS: {
        nimi: 'PMA_VAETIS_KOOSTIS'
    },
    PMA_VAETIS_MAJANDUSTEGEVUSTEADE: {
        nimi: 'PMA_VAETIS_MT',
        elemendid: {
            EL_VAETIS: 'PMA_VAETIS_MT_EL_VAETISTOODE',
            VAETISEREGISTRISSE_KANDMINE: 'PMA_VAETIS_MT_VAETISEREGISTER_KANDMINE'
        }
    },
    PMA_VAETIS_SEADUS_TEGEVUSALA: {
        nimi: 'PMA_VAETIS_SEADUS_TEGEVUSALA',
        elemendid: {
            TOOTMINE: 'PMA_VAETIS_SEADUS_TEGEVUSALA_TOOTMINE',
            MARGISTAMINE: 'PMA_VAETIS_SEADUS_TEGEVUSALA_MARGISTAMINE',
            PAKENDAMINE: 'PMA_VAETIS_SEADUS_TEGEVUSALA_PAKENDAMINE',
            TURUSTAMINE: 'PMA_VAETIS_SEADUS_TEGEVUSALA_TURUSTAMINE'
        }
    },
    PMA_VAETIS_TOIMEKATEGOORIA: {
        nimi: 'PMA_VAETIS_TOIMEKATEGOORIA'
    },
    PMA_VAETIS_VALMISTAMINE_VIIS: {
        nimi: 'PMA_VAETIS_VALMISTAMINE_VIIS'
    },
    SYS_PARAM_TYYP: {
        nimi: 'SYS_PARAM_TYYP',
        elemendid: {
            MAX_FAILI_SUURUS_MB: 'MAX_FAILI_SUURUS_MB',
            FAILI_TYYBID: 'FAILI_TYYBID',
            TEENUS_ALAMTYYP: {
                nimi: 'TEENUS_ALAMTYYP'
            }
        }
    },
    TEENUS_SEADISTUS_STAATUS: {
        nimi: 'TEENUS_SEADISTUS_STAATUS',
        elemendid: {
            AKTIIVNE: 'TEENUS_SEADISTUS_STAATUS_AKTIIVNE'
        }
    },
    TEENUS_STAATUS: {
        nimi: 'TEENUS_STAATUS',
        elemendid: {
            UUS: 'TEENUS_STAATUS_UUS',
            SISESTAMISEL: 'TEENUS_STAATUS_SISESTAMISEL',
            SISESTAMISEL_PUUDUS: 'TEENUS_STAATUS_SISESTAMISEL_PUUDUS',
            TAGASI_LYKATUD: 'TEENUS_STAATUS_TAGASI_LYKATUD',
            OTSUS_ALLKIRJASTATUD: 'TEENUS_STAATUS_OTSUS_ALLKIRJASTATUD',
            SISESTAMINE_LOPETATUD: 'TEENUS_STAATUS_SISESTAMINE_LOPETATUD',
            KONTROLLIMISEL: 'TEENUS_STAATUS_KONTROLLIMISEL',
            KONTROLLITUD: 'TEENUS_STAATUS_KONTROLLITUD',
            OTSUS_KOOSKOLASTAMISEL: 'TEENUS_STAATUS_OTSUS_KOOSKOLASTAMISEL',
            OTSUS_KOOSTATUD: 'TEENUS_STAATUS_OTSUS_KOOSTATUD',
            LOPETATUD: 'TEENUS_STAATUS_LOPETATUD',
            OOTEL: 'TEENUS_STAATUS_OOTEL',
            PORTAALIS_SISESTAMISEL: 'TEENUS_STAATUS_PORTAALIS_SISESTAMISEL',
            PORTAALIS_REGISTREERITUD: 'TEENUS_STAATUS_PORTAALIS_REGISTREERITUD',
            KUSTUTATUD: 'TEENUS_STAATUS_KUSTUTATUD'
        }
    },
    TEENUS_TYYP: {
        nimi: 'TEENUS_TYYP',
        elemendid: {
            TEATIS: 'TEENUS_TYYP_TEATIS',
            TAOTLUS: 'TEENUS_TYYP_TAOTLUS'
        }
    },

    // Kliendibaasi klassifikaatorid
    AADRESSI_LIIK: {
        nimi: 'AADRESSI_LIIK',
        elemendid: {
            GARAZEERIMISKOHA_AADRESS: 'GARAZEERIMISKOHA_AADRESS',
            POSTIAADRESS: 'POSTIAADRESS',
            TEGEVUSKOHA_AADRESS: 'TEGEVUSKOHA_AADRESS',
            ARIREGISTRI_AADRESS: 'ARIREGISTRI_AADRESS'
        }
    },
    ESINDAMINE_LIIK: {
        nimi: 'ESINDAMINE_LIIK',
        elemendid: {
            KASUTAJA_VOLITUS: 'KASUTAJA_VOLITUS'
        }
    },
    JAH_EI: {
        nimi: 'JAH_EI',
        elemendid: {
            EI: 'EI',
            JAH: 'JAH'
        }
    },
    JUR_ISIK_LIIK: {
        nimi: 'JUR_ISIK_LIIK'
    },
    KLIENDIKOODI_REGISTER: {
        nimi: 'KLIENDIKOODI_REGISTER',
        elemendid: {
            KLIENDIBAAS: 'KLIENDIKOODI_REGISTER_KLIENDIBAAS'
        }
    },
    KLIENT_STAATUS: {
        nimi: 'KLIENT_STAATUS',
        elemendid: {
            AKTIIVNE: 'AKTIIVNE'
        }
    },
    KONTAKTI_LIIK: {
        nimi: 'KONTAKTI_LIIK',
        elemendid: {
            TELEFON: 'TELEFON',
            EMAIL: 'EMAIL',
            KONTAKTISIK: 'KONTAKTISIK',
            KONTAKTISIKU_TELEFON: 'KONTAKTISIKU_TELEFON',
            KONTAKTISIKU_EMAIL: 'KONTAKTISIKU_EMAIL'
        }
    },
    RIIK: {
        nimi: 'RIIK',
        elemendid: {
            EESTI: 'EE'
        }
    },
    TEGEVUSKOHA_LIIK: {
        nimi: 'TEGEVUSKOHA_LIIK',
        elemendid: {
            TEGEVUSKOHT: 'TEGEVUSKOHT',
            GARAZEERIMISKOHT: 'GARAZEERIMISKOHT'
        }
    },
    VALDKOND: {
        nimi: 'VALDKOND',
        elemendid: {
            SEEME: 'SEEME',
            TAIMETERVIS: 'TAIMETERVIS',
            MAAPARANDUS: 'MAAPARANDUS'
        }
    },
    PMA_TT_HT_KASUTAMINE: {
        nimi: 'PMA_TT_HT_KASUTAMINE'
    },
    PMA_TT_HT_MARGISTAMINE_VIIS: {
        nimi: 'PMA_TT_HT_MARGISTAMINE_VIIS',
        elemendid: {
            PMA_TT_HT_MARGISTAMINE_VIIS_1: 'PMA_TT_HT_MARGISTAMINE_VIIS_1',
            PMA_TT_HT_MARGISTAMINE_VIIS_2: 'PMA_TT_HT_MARGISTAMINE_VIIS_2',
            PMA_TT_HT_MARGISTAMINE_VIIS_3: 'PMA_TT_HT_MARGISTAMINE_VIIS_3'
        }
    },
    PMA_TT_ETTEVOTE_TYYP: {
        nimi: 'PMA_TT_ETTEVOTE_TYYP'
    },
    PMA_TT_ETTEVOTE_TYYP_GRUPP: {
        nimi: 'PMA_TT_ETTEVOTE_TYYP_GRUPP',
        elemendid: {
            PAKKEMATERJALI_PARANDAMINE: 'PMA_TT_ETTEVOTE_TYYP_GRUPP_PAKKEMATERJALI_PARANDAMINE',
            PAKKEMATERJALI_VALMISTAMINE: 'PMA_TT_ETTEVOTE_TYYP_GRUPP_PAKKEMATERJALI_VALMISTAMINE',
            PUIDU_TOOTLEMINE: 'PMA_TT_ETTEVOTE_TYYP_GRUPP_PUIDU_TOOTLEMINE'
        }
    },
    PMA_TT_KAMBER_TYYP: {
        nimi: 'PMA_TT_KAMBER_TYYP'
    },
    PMA_TT_TEGEVUSALA: {
        nimi: 'PMA_TT_TEGEVUSALA',
        elemendid: {
            PMA_TT_TEGEVUSALA_TOOTMINE: 'PMA_TT_TEGEVUSALA_TOOTMINE',
            PMA_TT_TEGEVUSALA_PAKENDAMINE: 'PMA_TT_TEGEVUSALA_PAKENDAMINE',
            PMA_TT_TEGEVUSALA_TURUSTAMINE: 'PMA_TT_TEGEVUSALA_TURUSTAMINE',
            PMA_TT_TEGEVUSALA_VASTAVUSMARGI_KASUTAMISE_OIGUS: 'PMA_TT_TEGEVUSALA_VASTAVUSMARGI_KASUTAMISE_OIGUS',
            PMA_TT_TEGEVUSALA_TAIMEPASS_OIGUS: 'PMA_TT_TEGEVUSALA_TAIMEPASS_OIGUS'
        }
    },
    PMA_TT_TEGEVUSALA_TEGEVUSE_TYYP: {
        nimi: 'PMA_TT_TEGEVUSALA_TEGEVUSE_TYYP'
    },
    PMA_TT_TEGEVUSALA_TOODANG: {
        nimi: 'PMA_TT_TEGEVUSALA_TOODANG'
    },
    PMA_TT_TEGEVUSALA_ALGMATERJAL: {
        nimi: 'PMA_TT_TEGEVUSALA_ALGMATERJAL'
    },
    PMA_TT_TEGEVUSALA_TOOTLUSVIIS: {
        nimi: 'PMA_TT_TEGEVUSALA_TOOTLUSVIIS'
    },
    PMA_TT_TEGEVUSALA_KASUTUSOTSTARVE: {
        nimi: 'PMA_TT_TEGEVUSALA_KASUTUSOTSTARVE'
    },
    PMA_TT_TEGEVUSALA_PUIT_TYYP: {
        nimi: 'PMA_TT_TEGEVUSALA_PUIT_TYYP'
    },
    PMA_TT_TAIMSED_SAADUSED: {
        nimi: 'PMA_TT_TAIMSED_SAADUSED'
    },
    PMA_TT_TEMP_JALGIMINE: {
        nimi: 'PMA_TT_TEMP_JALGIMINE'
    },
    PMA_TT_VALMISTOODANG_MAHT_TOODE_LIIK: {
        nimi: 'PMA_TT_VALMISTOODANG_MAHT_TOODE_LIIK'
    },
    PMA_SEEME_TEGEVUSALA: {
        nimi: 'PMA_SEEME_TEGEVUSALA'
    },
    PMA_SEEME_TYYP: {
        nimi: 'PMA_SEEME_TYYP'
    },
    PMA_SEEME_SEGUNEMATUS_MEEDE: {
        nimi: 'PMA_SEEME_SEGUNEMATUS_MEEDE',
        elemendid: {
            MUU: 'PMA_SEEME_SEGUNEMATUS_MEEDE_MUU'
        }
    },
    PMA_SEEME_TEHNOLOOGIA: {
        nimi: 'PMA_SEEME_TEHNOLOOGIA',
        elemendid: {
            MUU: 'PMA_SEEME_TEHNOLOOGIA_MUU'
        }
    },
    PMA_SEEME_PUHASTUSMEEDE: {
        nimi: 'PMA_SEEME_PUHASTUSMEEDE',
        elemendid: {
            MUU: 'PMA_SEEME_PUHASTUSMEEDE_MUU',
            SEADMED_MUU: 'PMA_SEEME_PUHASTUSMEEDE_SEADMED_MUU',
            DESINFITSEERIMINE_VAHENDID: 'PMA_SEEME_PUHASTUSMEEDE_DESINFITSEERIMINE_VAHENDID'
        }
    },
    PMA_SEEME_KAHJUR_TORJE_MEEDE: {
        nimi: 'PMA_SEEME_KAHJUR_TORJE_MEEDE',
        elemendid: {
            MUU: 'PMA_SEEME_KAHJUR_TORJE_MEEDE_MUU'
        }
    },
    PMA_SEEME_JAATME_MEEDE: {
        nimi: 'PMA_SEEME_JAATME_MEEDE',
        elemendid: {
            MUU: 'PMA_SEEME_JAATME_MEEDE_MUU'
        }
    },
    PMA_SEEME_MITTENOUETEKOHANE_SEEME_MEEDE: {
        nimi: 'PMA_SEEME_MITTENOUETEKOHANE_SEEME_MEEDE',
        elemendid: {
            MUU: 'PMA_SEEME_MITTENOUETEKOHANE_SEEME_MEEDE_MUU'
        }
    },
    PMA_SEEME_MEEDE_TYYP: {
        nimi: 'PMA_SEEME_MEEDE_TYYP',
        elemendid: {
            SEEME_SEGUNEMATUS_MEEDE: 'PMA_SEEME_MEEDE_TYYP_SEEME_SEGUNEMATUS_MEEDE',
            SEEME_TEHNOLOOGIA: 'PMA_SEEME_MEEDE_TYYP_SEEME_TEHNOLOOGIA',
            SEEME_PUHASTUSMEEDE: 'PMA_SEEME_MEEDE_TYYP_SEEME_PUHASTUSMEEDE',
            SEEME_KAHJUR_TORJE_MEEDE: 'PMA_SEEME_MEEDE_TYYP_SEEME_KAHJUR_TORJE_MEEDE',
            TYYP_JAATME_MEEDE: 'PMA_SEEME_MEEDE_TYYP_JAATME_MEEDE',
            MITTENOUETEKOHANE_SEEME_MEEDE: 'PMA_SEEME_MEEDE_TYYP_MITTENOUETEKOHANE_SEEME_MEEDE'
        }
    },
    PMA_TEENUS_LIIK: {
        nimi: 'TEENUSED',
        elemendid: {
            VAETIS_REGISTRIKANNE: 'VD_PMA_VAETISEREGISTRI_MAJANDUSTEGEVUSTEADE',
            TAIMETERVIS_REGISTRIKANNE: 'TE_TT_MAJANDUSTEADE',
            SEEMNEPROOV: 'PMA_SE_SEEMNEPROOV_TAOTLUS',
            PMA_SE_POLDTT: 'PMA_SE_POLDTT',
            PMA_TT_POLDTT: 'PMA_TT_POLDTT',
            TE_TT_FYTO_TAOTLUS: 'TE_TT_FYTO_TAOTLUS',
            PMA_TE_SE_YMBERPAKENDAMINE: 'PMA_TE_SE_YMBERPAKENDAMINE'
        }
    },
    PROTOKOLL_TYYP: {
        nimi: 'PROTOKOLL_NIMI'
    },
    PROTSESS: {
        nimi: 'PROTSESS'
    },
    PMA_PARTII_STAATUS: {
        nimi: 'PMA_PARTII_STAATUS',
        elemendid: {
            PMA_PARTII_STAATUS_KEHTIV: 'PMA_PARTII_STAATUS_KEHTIV',
            PMA_PARTII_STAATUS_PRAKEERITUD: 'PMA_PARTII_STAATUS_PRAKEERITUD',
            PMA_PARTII_STAATUS_KEHTETU: 'PMA_PARTII_STAATUS_KEHTETU',
            PMA_PARTII_STAATUS_KONTROLLIMISEL: 'PMA_PARTII_STAATUS_KONTROLLIMISEL',
            PMA_PARTII_STAATUS_KATEGOORIA_ALANDATUD: 'PMA_PARTII_STAATUS_KATEGOORIA_ALANDATUD',
            PMA_PARTII_STAATUS_KUSTUTATUD: 'PMA_PARTII_STAATUS_KUSTUTATUD'
        }
    },
    PMA_SEEMNESEGU_PARTII_STAATUS: {
        nimi: 'PMA_SEEMNESEGU_PARTII_STAATUS',
        elemendid: {
            PMA_SEEMNESEGU_PARTII_STAATUS_KEHTIV: 'PMA_SEEMNESEGU_PARTII_STAATUS_KEHTIV',
            PMA_SEEMNESEGU_PARTII_STAATUS_KEHTETU: 'PMA_SEEMNESEGU_PARTII_STAATUS_KEHTETU',
            PMA_SEEMNESEGU_PARTII_STAATUS_KONTROLLIMISEL: 'PMA_SEEMNESEGU_PARTII_STAATUS_KONTROLLIMISEL',
            PMA_SEEMNESEGU_PARTII_STAATUS_KONTROLLITUD: 'PMA_SEEMNESEGU_PARTII_STAATUS_KONTROLLITUD'
        }
    },
    PMA_PROTOKOLL_STAATUS: {
        nimi: 'PMA_PROTOKOLL_STAATUS',
        elemendid: {
            PMA_PROTOKOLL_STAATUS_MUSTAND: 'PMA_PROTOKOLL_STAATUS_MUSTAND',
            PMA_PROTOKOLL_STAATUS_TAITMISEL: 'PMA_PROTOKOLL_STAATUS_TAITMISEL',
            PMA_PROTOKOLL_STAATUS_LOPETATUD: 'PMA_PROTOKOLL_STAATUS_LOPETATUD',
            PMA_PROTOKOLL_STAATUS_LOPETATUD_RIKKUMISEGA: 'PMA_PROTOKOLL_STAATUS_LOPETATUD_RIKKUMISEGA',
            PMA_PROTOKOLL_STAATUS_KATKESTATUD: 'PMA_PROTOKOLL_STAATUS_KATKESTATUD',
            PMA_PROTOKOLL_STAATUS_TYHISTATUD: 'PMA_PROTOKOLL_STAATUS_TYHISTATUD'
        }
    },
    PMA_ETTEKIRJUTUS_STAATUS: {
        nimi: 'PMA_ETTEKIRJUTUS_STAATUS',
        elemendid: {
            PMA_ETTEKIRJUTUS_STAATUS_MUSTAND: 'PMA_ETTEKIRJUTUS_STAATUS_MUSTAND',
            PMA_ETTEKIRJUTUS_STAATUS_TAITMISEL: 'PMA_ETTEKIRJUTUS_STAATUS_TAITMISEL',
            PMA_ETTEKIRJUTUS_STAATUS_LOPETATUD: 'PMA_ETTEKIRJUTUS_STAATUS_LOPETATUD',
            PMA_ETTEKIRJUTUS_STAATUS_LOPETATUD_RIKKUMISEGA: 'PMA_ETTEKIRJUTUS_STAATUS_LOPETATUD_RIKKUMISEGA',
            PMA_ETTEKIRJUTUS_STAATUS_TYHISTATUD: 'PMA_ETTEKIRJUTUS_STAATUS_TYHISTATUD'
        }
    },
    PMA_REGISTER: {
        nimi: 'PMA_REGISTER',
        elemendid: {
            PMA_REGISTER_LIHT: 'PMA_REGISTER_LIHT',
            PMA_REGISTER_TAIMETERVIS_YLD: 'PMA_REGISTER_TAIMETERVIS_YLD',
            PMA_REGISTER_VAETIS: 'PMA_REGISTER_VAETIS',
            PMA_REGISTER_EL_VAETISTOODE: 'PMA_REGISTER_EL_VAETISTOODE',
            PMA_REGISTER_TT_KARTULIPOLLU_TUNNUSTAMINE: 'PMA_REGISTER_TT_KARTULIPOLLU_TUNNUSTAMINE',
            PMA_REGISTER_SE_POLLU_TUNNUSTAMINE: 'PMA_REGISTER_SE_POLLU_TUNNUSTAMINE'
        }
    },
    PMA_SE_PARTII_TEGEVUS: {
        nimi: 'PMA_SE_PARTII_TEGEVUS',
        elemendid: {
            SERTIFITSEERIMINE: 'PMA_SE_PARTII_TEGEVUS_SERTIFITSEERIMINE',
            ESMANE_SERTIFITSEERIMINE: 'PMA_SE_PARTII_TEGEVUS_ESMANE_SERTIFITSEERIMINE',
            KORDUSIDANEVUS: 'PMA_SE_PARTII_TEGEVUS_KORDUSIDANEVUS',
            KONTROLLPROOV: 'PMA_SE_PARTII_TEGEVUS_KONTROLLPROOV',
            MUU_PROOV: 'PMA_SE_PARTII_TEGEVUS_MUU_PROOV',
            JARELKONTROLLPROOV: 'PMA_SE_PARTII_TEGEVUS_JARELKONTROLLPROOV',
            KATEGOORIA_ALANDAMINE: 'PMA_SE_PARTII_TEGEVUS_KATEGOORIA_ALANDAMINE',
            KORDUSANALUUS_PARTII_SERTIFITSEERIMISEKS: 'PMA_SE_PARTII_TEGEVUS_KORDUSANALUUS_PARTII_SERTIFITSEERIMISEKS',
        }
    },
    PMA_SE_PARTII_ETIKETT_TYYP: {
        nimi: 'PMA_SE_PARTII_ETIKETT_TYYP'
    },
    PMA_SE_ANALYYS_NIMI: {
        nimi: 'PMA_SE_ANALYYS_NIMI',
        elemendid: {
            PUHTUS: 'PMA_SE_ANALYYS_NIMI_PUHTUS',
            PIIRATUD_ANALUUS: 'PMA_SE_ANALYYS_NIMI_PIIRATUD_ANALUUS ',
            TEISTE_TAIMEDE_SEEMNED: 'PMA_SE_ANALYYS_NIMI_TEISTE_TAIMEDE_SEEMNED',
            IDANEVUS: 'PMA_SE_ANALYYS_NIMI_IDANEVUS',
            TUHANDE_SEEMNE_KAAL: 'PMA_SE_ANALYYS_NIMI_1000_SEEMNE_KAAL',
            NIISKUS: 'PMA_SE_ANALYYS_NIMI_NIISKUS',
            ELUVOIME: 'PMA_SE_ANALYYS_NIMI_ELUVOIME',
            LAOKAHJURID: 'PMA_SE_ANALYYS_NIMI_LAOKAHJURID',
            ODRA_LENDNOGI: 'PMA_SE_ANALYYS_NIMI_ODRA_LENDNOGI',
            ODRA_TRIIPTOBI: 'PMA_SE_ANALYYS_NIMI_ODRA_TRIIPTOBI',
            KAERA_LENDNOGI: 'PMA_SE_ANALYYS_NIMI_KAERA_LENDNOGI',
            TALINISU_KOVANOGI: 'PMA_SE_ANALYYS_NIMI_TALINISU_KOVANOGI',
            TUULEKAER: 'PMA_SE_ANALYYS_NIMI_TUULEKAER'
        }
    },
    PMA_SE_PROOV_TYYP: {
        nimi: 'PMA_SE_PROOV_TYYP'
    },

    PMA_TT_KONTROLL_TYYP: {
        nimi: 'PMA_TT_KONTROLL_TYYP',
        elemendid: {
            TYYP_1: 'PMA_TT_KONTROLL_TYYP_1',
            TYYP_2: 'PMA_TT_KONTROLL_TYYP_2',
            TYYP_3: 'PMA_TT_KONTROLL_TYYP_3'
        }
    },

    PMA_TT_TAIMSED_SAADUSED_TEGEVUSALA: {
        nimi: 'PMA_TT_TAIMSED_SAADUSED_TEGEVUSALA',
        elemendid: {
            PAKENDAMINE_SERTIFITSEERITUD_SEEMNED_JA_SEEMNESEGUD: 'PMA_TT_TAIMSED_SAADUSED_TEGEVUSALA_PAKENDAMINE_SERTIFITSEERITUD_SEEMNED_JA_SEEMNESEGUD'
        }
    },
    PMA_ANALYYS_TELLIMUS_LABOR: {
        nimi: 'PMA_ANALYYS_TELLIMUS_LABOR',
        elemendid: {
            SEEME: 'PMA_ANALYYS_TELLIMUS_LABOR_SEEME',
            TAIMETERVIS: 'PMA_ANALYYS_TELLIMUS_LABOR_TAIMETERVIS',
            VILJANDI_KK: 'PMA_ANALYYS_TELLIMUS_LABOR_VILJANDI_KK'
        }
    },
    PMA_PMK_AMET: {
        nimi: 'PMA_PMK_AMET'
    },
    PMA_TT_FYTO_ORIGINAAL_SERTIFIKAAT_MANUSTAMINE_VIIS: {
        nimi: 'PMA_TT_FYTO_ORIGINAAL_SERTIFIKAAT_MANUSTAMINE_VIIS'
    },
    PMA_TT_FYTO_REEKSPORT_PAKEND_VIIS: {
        nimi: 'PMA_TT_FYTO_REEKSPORT_PAKEND_VIIS'
    },
    PMA_TT_FYTO_REEKSPORT_VALJASTAMINE_ALUS: {
        nimi: 'PMA_TT_FYTO_REEKSPORT_VALJASTAMINE_ALUS'
    },
    PMA_SYS_DOMEEN_ATRIBUUT_TYYP: {
        nimi: 'PMA_SYS_DOMEEN_ATRIBUUT_TYYP',
        elemendid: {
            ABIINFO_EN: 'PMA_SYS_DOMEEN_ATRIBUUT_TYYP_ABIINFO_EN',
            ABIINFO_ET: 'PMA_SYS_DOMEEN_ATRIBUUT_TYYP_ABIINFO_ET'
        }
    },
    PMA_MSR_EHITIS_STAATUS: {
        nimi: 'PMA_MSR_EHITIS_STAATUS'
    },
    PMA_MSR_RAPORT_GRUPP: {
        nimi: 'PMA_MSR_RAPORT_GRUPP'
    },
    PMA_MP_MAAKASUTUSE_VIIS: {
        nimi: 'PMA_MP_MAAKASUTUSE_VIIS'
    },
    PMA_MP_KUIVENDUS_NIISUTUS_VIIS: {
        nimi: 'PMA_MP_KUIVENDUS_NIISUTUS_VIIS'
    },
    PMA_MP_TEE_KLASS: {
        nimi: 'PMA_MP_TEE_KLASS'
    },
    PMA_MP_RAJATIS_LIIK: {
        nimi: 'PMA_MP_RAJATIS_LIIK'
    },
    PMA_MATER_VALDKOND: {
        nimi: 'PMA_MATER_VALDKOND'
    },
    PMA_MATER_TEGEVUS: {
        nimi: 'PMA_MATER_TEGEVUS'
    },
    PMA_KOOSKOLASTUS_LIIK: {
        nimi: 'PMA_KOOSKOLASTUS_LIIK'
    },
    KAARDIKIHT_ALLIKAS_TYYP: {
        nimi: 'KAARDIKIHT_ALLIKAS_TYYP',
        elemendid: {
            WMS: 'KAARDIKIHT_ALLIKAS_TYYP_WMS',
            WMS_1_1_0: 'KAARDIKIHT_ALLIKAS_TYYP_WMS_1_1_0',
            WMS_1_1_1: 'KAARDIKIHT_ALLIKAS_TYYP_WMS_1_1_1',
            WMS_1_3_0: 'KAARDIKIHT_ALLIKAS_TYYP_WMS_1_3_0'
        }
    },
    KAARDIKIHT_STAATUS: {
        nimi: 'KAARDIKIHT_STAATUS',
        elemendid: {
            AKTIIVNE: 'KAARDIKIHT_STAATUS_AKTIIVNE',
            MITTE_AKTIIVNE: 'KAARDIKIHT_STAATUS_MITTE_AKTIIVNE'
        }
    },
    KAARDIOSA_TYYP: {
        nimi: 'KAARDIOSA_TYYP',
        elemendid: {
            KIHT: 'KAARDIOSA_TYYP_KIHT',
            KIHT_GRUPP: 'KAARDIOSA_TYYP_KIHT_GRUPP'
        }
    },
    PMA_MAHE_TEGEVUSVALDKOND: {
        nimi: 'PMA_MAHE_TEGEVUSVALDKOND'
    },
    PMA_MAHE_LOOM_GRUPP: {
        nimi: 'PMA_MAHE_LOOM_GRUPP'
    },
    PMA_MAHE_LOOM_GRUPP_STAATUS: {
        nimi: 'PMA_MAHE_LOOM_GRUPP_STAATUS'
    },
    POLLUMAJANDUSMAA_KASUTUS: {
        nimi: 'POLLUMAJANDUSMAA_KASUTUS'
    },
    PMA_MAHE_KORJATAV_LIIK: {
        nimi: 'PMA_MAHE_KORJATAV_LIIK'
    },
    PMA_MAHE_MAAKASUTUS_MAARATLUS: {
        nimi: 'PMA_MAHE_MAAKASUTUS_MAARATLUS'
    },
    PMA_TKV_OTSING_MUUGIPIIRANG: {
        nimi: 'PMA_TKV_OTSING_MUUGIPIIRANG',
        elemendid: {
            MUUGIPIIRANG_NAITA_AINULT_VAGA_MURGISEID_TAIMEKAITSEVAHENDEID: 'PMA_TKV_OTSING_MUUGIPIIRANG_MUUGIPIIRANG_NAITA_AINULT_VAGA_MURGISEID_TAIMEKAITSEVAHENDEID',
            MUUGIPIIRANG_NAITA_TAIMEKAITSEVAHENDEID_MIS_ON_VABAMUUGIS: 'PMA_TKV_OTSING_MUUGIPIIRANG_MUUGIPIIRANG_NAITA_TAIMEKAITSEVAHENDEID_MIS_ON_VABAMUUGIS',
            MUUGIPIIRANG_NAITA_AINULT_TAIMEKAITSETUNNISTUSE_ALUSEL_MUUDAVAID_TAIMEKAITSEVAHENDEID: 'PMA_TKV_OTSING_MUUGIPIIRANG_MUUGIPIIRANG_NAITA_AINULT_TAIMEKAITSETUNNISTUSE_ALUSEL_MUUDAVAID_TAIMEKAITSEVAHENDEID'
        }
    },
    PMA_TKV_OTSING_KASUTUSPIIRANG: {
        nimi: 'PMA_TKV_OTSING_KASUTUSPIIRANG',
        elemendid: {
            KASUTUSPIIRANG_NAITA_TAIMEKAITSEVAHENDEID_MILLEL_ON_KASUTUSPIIRANG: 'PMA_TKV_OTSING_KASUTUSPIIRANG_KASUTUSPIIRANG_NAITA_TAIMEKAITSEVAHENDEID_MILLEL_ON_KASUTUSPIIRANG'
        }
    },
    PMA_TKV_OTSING_KASUTUSALA: {
        nimi: 'PMA_TKV_OTSING_KASUTUSALA',
        elemendid: {
            KASUTUSALA_LAIENDAMINE_NAITA_TAIMEKAITSEVAHENDEID_MILLE_KASUTUSALA_ON_LAIENDATUD: 'PMA_TKV_OTSING_KASUTUSALA_KASUTUSALA_LAIENDAMINE_NAITA_TAIMEKAITSEVAHENDEID_MILLE_KASUTUSALA_ON_LAIENDATUD'
        }
    },
    PMA_TKV_PROOV_TOIMEAINE: {
        nimi: 'PMA_TKV_PROOV_TOIMEAINE'
    },
    PMA_TKV_STAATUS: {
        nimi: 'PMA_TKV_STAATUS',
        elemendid: {
            TURULELUBAMISE_MENETLUS_ON_POOLELI: 'PMA_TKV_STAATUS_TURULELUBAMISE_MENETLUS_ON_POOLELI',
            TEHTUD_ON_KEELDUV_OTSUS:'PMA_TKV_STAATUS_TEHTUD_ON_KEELDUV_OTSUS'
        }
    },
    PMA_TKV_KULTUUR: {
        nimi: 'PMA_TKV_KULTUUR'
    },
    PMA_TKV_KAHJUSTAJA: {
        nimi: 'PMA_TKV_KAHJUSTAJA'
    },
    PMA_TKV_TKV_LIIK: {
        nimi: 'PMA_TKV_TKV_LIIK'
    },
    PMA_TKV_KASUTUSVIIS: {
        nimi: 'PMA_TKV_KASUTUSVIIS'
    },
    PMA_TKV_KASUTAMISE_KOHT: {
        nimi: 'PMA_TKV_KASUTAMISE_KOHT'
    },
    PMA_TKV_TOIME_LIIK: {
        nimi: 'PMA_TKV_TOIME_LIIK'
    }
    ,
    PMA_TKV_OTSING_LOA_VALDAJA_NIMI: {
        nimi: 'PMA_TKV_OTSING_LOA_VALDAJA_NIMI',
        elemendid: {
            LOA_VALDAJA_NIMI: 'PMA_TKV_OTSING_LOA_VALDAJA_NIMI_LOA_VALDAJA_NIMI'
        }
    },
    PMA_TKV_OTSING_AJAPIKENDUS_MUUGIKS: {
        nimi: 'PMA_TKV_OTSING_AJAPIKENDUS_MUUGIKS',
        elemendid: {
            AJAPIKENDUS_MUUGIKS: 'PMA_TKV_OTSING_AJAPIKENDUS_MUUGIKS_AJAPIKENDUS_MUUGIKS'
        }
    },
    PMA_TKV_OTSING_AJAPIKENDUS_KASUTAMISEKS: {
        nimi: 'PMA_TKV_OTSING_AJAPIKENDUS_KASUTAMISEKS',
        elemendid: {
            AJAPIKENDUS_KASUTAMISEKS: 'PMA_TKV_OTSING_AJAPIKENDUS_KASUTAMISEKS_AJAPIKENDUS_KASUTAMISEKS'
        }
    },
    PMA_TKV_OTSING_TOIMEAINE: {
        nimi: 'PMA_TKV_OTSING_TOIMEAINE',
        elemendid: {
            TOIMEAINE: 'PMA_TKV_OTSING_TOIMEAINE_TOIMEAINE'
        }
    },
    PMA_TKV_OTSING_KULTUUR_KASUTUSALA: {
        nimi: 'PMA_TKV_OTSING_KULTUUR_KASUTUSALA',
        elemendid: {
            KULTUUR_KASUTUSALA: 'PMA_TKV_OTSING_KULTUUR_KASUTUSALA_KULTUUR_KASUTUSALA'
        }
    },
    PMA_TKV_OTSING_KAHJUSTAJA: {
        nimi: 'PMA_TKV_OTSING_KAHJUSTAJA',
        elemendid: {
            KAHJUSTAJA: 'PMA_TKV_OTSING_KAHJUSTAJA_KAHJUSTAJA'
        }
    },

    PMA_TKV_KASUTUSALA: {
        nimi: 'PMA_TKV_KASUTUSALA'
    },
    PMA_VAETIS_OTSING_VAETISE_KAITLEJA : {
        nimi: 'PMA_VAETIS_OTSING_VAETISE_KAITLEJA',
        elemendid: {
            VAETISE_KAITLEJA:'PMA_VAETIS_OTSING_VAETISE_KAITLEJA_VAETISE_KAITLEJA'
        }
    },
    PMA_VAETIS_OTSING_VAETISE_LIIGINIMETUS: {
        nimi: 'PMA_VAETIS_OTSING_VAETISE_LIIGINIMETUS',
        elemendid: {
            VAETISE_LIIGINIMETUS: 'PMA_VAETIS_OTSING_VAETISE_LIIGINIMETUS_VAETISE_LIIGINIMETUS'
        }
    },
    PMA_VAETIS_OTSING_VAETISE_KOOSTIS : {
        nimi: 'PMA_VAETIS_OTSING_VAETISE_KOOSTIS',
        elemendid: {
            VAETISE_KOOSTIS: 'PMA_VAETIS_OTSING_VAETISE_KOOSTIS_VAETISE_KOOSTIS'
        }
    },
    PMA_VAETIS_OTSING_PARITOLU_RIIK : {
        nimi: 'PMA_VAETIS_OTSING_PARITOLU_RIIK',
        elemendid: {
            PARITOLU_RIIK: 'PMA_VAETIS_OTSING_PARITOLU_RIIK_PARITOLU_RIIK'
        }
    },
    PMA_VAETIS_OTSING_KOOSTISOSA_SISALDUS: {
        nimi: 'PMA_VAETIS_OTSING_KOOSTISOSA_SISALDUS',
        elemendid: {
            KOOSTISOSA_SISALDUS: 'PMA_VAETIS_OTSING_KOOSTISOSA_SISALDUS_KOOSTISOSA_SISALDUS'
        }
    },
    JALUS_TEKST: {
        nimi: 'JALUS_TEKST',
        elemendid: {
            JALUS_ULD: 'JALUS_ULD',
            JALUS_MAAPARANDUS: 'JALUS_MAAPARANDUS',
            JALUS_MAHEPOLLUMAJANDUS: 'JALUS_MAHEPOLLUMAJANDUS',
            JALUS_TAIMEKAITSE: 'JALUS_TAIMEKAITSE',
            JALUS_VAETIS: 'JALUS_VAETIS',
            JALUS_TAIMETERVIS: 'JALUS_TAIMETERVIS',
            JALUS_ETTEKIRJUTUS: 'JALUS_ETTEKIRJUTUS'
        }
    },
    PMA_TAIM_LIIK_KULTUUR: {
        nimi: 'PMA_TAIM_LIIK_KULTUUR'
    },
    PMA_SORT_REGISTREERIMINE_STAATUS:{
        nimi: 'PMA_SORT_REGISTREERIMINE_STAATUS'
    },
    PMA_SORT_SK_STAATUS: {
        nimi: 'PMA_SORT_SK_STAATUS'
    },
    PMA_SORT_SL_STAATUS: {
        nimi: 'PMA_SORT_SL_STAATUS'
    },
    PMA_SORT_GR_STAATUS: {
        nimi: 'PMA_SORT_GR_STAATUS'
    },
    PMA_SORT_HETM_STAATUS: {
        nimi: 'PMA_SORT_HETM_STAATUS'
    }
});

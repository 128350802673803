'use strict';

angular.module('commons.selectmultiple', []).directive('sdeSelectMultiple', function($timeout, pmaTranslateSdeFilter, domeenService) {

    return {
        restrict: 'E',
        scope: {
            label: '=',
            model: '=',
            isDisabled: '=?',
            id: '=?',
            klList: '=?',
            klKood: '=?',
            required: '=?',
            onChange: '=?',
            inputClass: '=?',
            labelClass: '=?',
            searchText: '=?'
        },
        templateUrl: 'modules/commons/elements/sde-select-multiple.html',
        link: function(scope, elem, attrs, ctrl) {

          if (!scope.searchText) {
            scope.searchText = 'yld.vali';
          }

            scope.$watch('klKood', function(newValue, oldValue) {
                if (!scope.klList && newValue) {
                    domeenService.klassifikaatorQuery(newValue, function(result) {
                        scope.klList = result;
                    });
                }
            });

            scope.getValueList = function() {
                var returnValue = [];
                if (angular.isArray(scope.model)) {
                    for ( var i in scope.model) {
                        returnValue[i] = pmaTranslateSdeFilter(scope.model[i]);
                    }
                }
                return returnValue;
            };

            scope.onSelectChange = function() {
                scope.model = scope.holder.content;
                if (scope.onChange) {
                    $timeout(function() {
                        scope.onChange();
                    });
                }
            };

            $timeout(function() {
                scope.holder = {
                    content: scope.model
                };
            });

        }
    };
});

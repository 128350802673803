'use strict';

angular.module('register.taimekaitse.vagamurgised', []).factory('TkvVagaMurgisedService', ['$resource', 'config', '$q', function ($resource, config, $q) {


    var taimekaitsevahendService = {};

    taimekaitsevahendService.vagaMurgisedTkvDto = $resource(config.api + '/v1/public/taimekaitsevahend/');

    taimekaitsevahendService.getVagaMurgisedTkvdQuery = function () {
        return taimekaitsevahendService.vagaMurgisedTkvDto.get({
        }, function (result) {

        });
    };


    return taimekaitsevahendService;
}]);
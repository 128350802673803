'use strict';
var pmaReg = angular.module('register.maaparandus');

pmaReg.controller('MaaparandusRegisterToimikOtsingCtrl', function ($scope, notifyService, regioService, ngTableHelperFactory,
                                                             StateReminderService, TeenusMuutmine, domeenService, domeenEnum,
                                                             $q, TeenusSeadistusOtsing, $location) {

        $scope.activeTab = 'toimik';
        $scope.toimikOtsing = {
            kirjeldus: '',
            protsessLoomisKp: ''
        };
        $scope.otsingutunnus = 'dokument';

        $scope.tableParams = ngTableHelperFactory.newNgTableParams('/v1/public/register/maaparandus/otsing/toimik', $scope.toimikOtsing, {
            'kirjeldus': 'asc'
        });


        TeenusSeadistusOtsing.leiaTeenusSeadistused(domeenEnum.VALDKOND.elemendid.MAAPARANDUS).then(function (result) {
            $scope.teenused = result.entities;
        });

        if (StateReminderService.hasPreviousState()) {
            var state = StateReminderService.getPreviousState();
            $scope.tableParams = state.scope.tableParams;
            $scope.toimikOtsing = state.scope.toimikOtsing;
            $scope.tableParams.reload();
        }
        $scope.preserveState = function() {
            StateReminderService.preserveState($scope);
        };

        $scope.otsi = function() {
            notifyService.clearNotifications();
            $scope.tableParams.search();
        };

        $scope.resetSearch = function() {
            $scope.toimikOtsing = {};
            $scope.aadress = {};
            $scope.tableParams.reset($scope.toimikOtsing);
        };

        $scope.otsingutunnusChanged = function(){
            $scope.toimikOtsing.aadress = null;

            $scope.toimikOtsing.asukohtTase1 = null;
            $scope.toimikOtsing.asukohtTase2 = null;
            $scope.toimikOtsing.asukohtTase3 = null;

            $scope.toimikOtsing.systeemikood = null;
            $scope.toimikOtsing.katastritunnus = null;
        };

        $scope.getAddress = function(searchValue) {
            $scope.toimikOtsing.aadress = searchValue;
            $scope.aadress = {};
            return regioService.getAddresses(searchValue);
        };

        $scope.aadressSelect = function(regioAadress) {
            regioService.convertToAadress(regioAadress, $scope.aadress);
            $scope.toimikOtsing.aadress = $scope.aadress.aadress;
            $scope.toimikOtsing.asukohtTase1 = $scope.aadress.detailid.tase1Ehak;
            $scope.toimikOtsing.asukohtTase2 = $scope.aadress.detailid.tase2Ehak;
            $scope.toimikOtsing.asukohtTase3 = $scope.aadress.detailid.tase3Ehak;
        };

    }
);

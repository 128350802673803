'use strict';


angular.module('register.maaparandus').controller('MaaparandusEhitisTehnilisedAndmedCtrl',
    function ($scope, $modalInstance, ehitis) {

        $scope.ehitis = ehitis;

        $scope.cancel = $modalInstance.dismiss;

});

'use strict';

var sysParamModule = angular.module('pma.sysparam');

/**
 * Lubatud süsteemsete parameetrite koodide loetelu
 */
sysParamModule.constant('sysParamEnum', {
  MP_KAART_TYPE_NAME_EESVOOLUD: 'MP_KAART_TYPE_NAME_EESVOOLUD',
  MP_KAART_TYPE_NAME_RIIGIEESVOOLUD: 'MP_KAART_TYPE_NAME_RIIGIEESVOOLUD',
  MP_KAART_TYPE_NAME_TEENINDAV_TEE: 'MP_KAART_TYPE_NAME_TEENINDAV_TEE',
  MP_KAART_TYPE_NAME_VORK: 'MP_KAART_TYPE_NAME_VORK',
  MP_KAART_TYPE_NAME_SCHEMA_PREFIX: 'MP_KAART_TYPE_NAME_SCHEMA_PREFIX'
});

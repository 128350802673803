'use strict';

angular.module('register.maaparandus').controller('MaaparandusArhiivTeatisCtrl', function ($scope, $routeParams, MaaparandusArhiivService) {

    $scope.teatisId = $routeParams.teatisId;

    MaaparandusArhiivService.getArhiivTeatis($scope.teatisId).$promise.then(function (teatis) {
        $scope.teatis = teatis;
    });

});

'use strict';

angular.module('register.maaparandus').controller('MaaparandusSysteemCtrl', function ($scope, $routeParams, $location, ngTableHelperFactory, MaaparandusService, TeenusMuutmine) {

    $scope.kood = $routeParams.kood;

    MaaparandusService.getMaaparandusSysteem($scope.kood).$promise.then(function (systeem) {
        $scope.systeem = systeem;
    });

    MaaparandusService.getMaaparandusSysteemTeenus($scope.kood).$promise.then(function (teenused) {
        $scope.teenused = teenused;

        $scope.teenusedTableParams = ngTableHelperFactory.newLocalNgTableParamsWithPaging($scope.teenused, {'loomiskp': 'desc'});
    });

    $scope.avaTeenus = function (teenusId, teenusTyyp) {
        if (teenusTyyp == 'TEENUS') {
            TeenusMuutmine.doRedirect(teenusId, false);
        } else if (teenusTyyp == 'ARHIIV_TAOTLUS') {
            $location.url('/maaparandus/arhiiv/taotlus/' + teenusId);
        } else if (teenusTyyp == 'ARHIIV_TEATIS') {
            $location.url('/maaparandus/arhiiv/teatis/' + teenusId);
        }
    };

});
'use strict';

angular.module('register.taimekaitse.koolitus', []).factory('KoolitusTunnistusService', ['$resource', 'config', '$q', function ($resource, config, $q) {


    var taimekaitsevahendService = {};

    taimekaitsevahendService.taimekaitseKoolitusTunnistusDto = $resource(config.api + '/v1/public/registrid/tk-koolitus-tunnistus/:isikukood/');

    taimekaitsevahendService.getKoolitusTunnistusQuery = function (kood) {
        return taimekaitsevahendService.taimekaitseKoolitusTunnistusDto.get({
            'isikukood': kood
        }, function (result) {

        });
    };

    return taimekaitsevahendService;
}]);
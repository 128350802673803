'use strict';

angular.module('commons.general')
    .directive('pmaSdeKoodSelectSimple', function (domeenService, $timeout) {

        return {
            restrict: 'E',
            scope: {
                model: '=',
                isDisabled: '=?',
                id: '=?',
                klList: '=?',
                klKood: '=?',
                onChange: '=?'
            },
            templateUrl: 'modules/commons/elements/pma-sde-kood-select-simple.html',
            link: function (scope, elem, attrs, ctrl) {
            
                if (!scope.klList && scope.klKood) {
                    scope.klList = domeenService.klassifikaatorQuery(scope.klKood);
                }
            
                scope.onSelectChange = function () {
                    if (scope.onChange) {
                        $timeout(function () {
                            scope.onChange();
                        });
                    }
                };
            
            }
        };
    });
'use strict';

angular.module('pma', [
    'ngResource', 'ngRoute', 'ngBootstrap', 'ngTable', 'nDaterangepicker',
    'monospaced.elastic',
    'localytics.directives',
    'pascalprecht.translate',
    /*    'angular-data.DSCacheFactory',*/
    'naif.base64',
    'ngSanitize',
    'ui.bootstrap', 'ui.select',
    /*    'ui.sortable',*/
    'angular-loading-bar',
    'openlayers-directive',
    'ui.bootstrap.datepicker',

    'pma.layout',
    'pma.config',
    'pma.dashboard',
    'pma.translate.util',
    'pma.translate',

    'teenus.seadistus',
    'teenus.muutmine',
    'teenus.redirect',
    'teenus.navigation',
    'teenus.common',
    'teenus.detailvaade',
    'teenus.detailvaade.teated',
    'teenus.teenusdetailvaadedto',
    'teenus.detailvaade.service',

    'domeen.service',
    'domeen.enum',

    'commons.date',
    'commons.notify.const',
    'commons.notify.service',
    'commons.klient',
    'commons.textareasimple',
    'commons.tables',
    'commons.tables.constants',
    'commons.selectmultiple',
    'commons.customfields',
    'commons.checkbox',
    'commons.textarea',
    'commons.notify',
    'commons.general',
    'commons.state',
    'commons.rest',
    'commons.tableutils',
    'commons.regio',
    'commons.kaart',
    'commons.slash.translate',
    'commons.hide',
    'commons.static.input',
    'commons.text-input',
    'commons.number',
    'commons.truncate',
    'commons.counties',

    'dokument.service',
    'dokument.directive',
    'dokument.minified.directive',
    'dokument.query',
    'dokument.download',

    'register.maaparandus',
    'register.maaparandus.teenus',
    'register.mater',

    'register.mahe',
    'register.taimekaitse',
    'register.taimekaitse.koolitus',
    'register.taimekaitse.taimekaitsevahend',
    'register.taimekaitse.taimekaitsevahend.detailvaade',
    'register.taimekaitse.turustuskohad',
    'register.taimekaitse.vagamurgised',

    'register.vaetis',

    'register.taimetervis',

    'register.ettekirjutus',

    'register.sort',

    'tabel.registreering',
    'tabel.kinnisasi',
    'tabel.asukoht',

    'pma.sysparam'
    /* 'pma.domeen'*/
]).config(function ($routeProvider) {
    $routeProvider

    // töölaud
        .when('/', {
            redirectTo: function (routeParams, currentPath, currentSearch) {
                return '/maaparandus/msr/systeemi-otsing';
            }
        }).when('/#', {
        redirectTo: function (routeParams, currentPath, currentSearch) {
            return '/maaparandus/msr/systeemi-otsing';
        }
    }).when('/dashboard', {
        redirectTo: function (routeParams, currentPath, currentSearch) {
            return '/maaparandus/msr/systeemi-otsing';
        }
    })
    // --- MSR ---
        .when('/maaparandus', {
            templateUrl: 'modules/maaparandus/register-maaparandus-msrOrMater-header.html',
            controller: 'RegisterMaaparandusMsrOrMaterHeader'
        })
        .when('/maaparandus/msr', {
            templateUrl: 'modules/maaparandus/maaparandus-register-header.html',
            controller: 'MaaparandusRegisterHeaderCtrl'
        })
        .when('/maaparandus/msr/systeemi-otsing', {
            templateUrl: 'modules/maaparandus/maaparandus-systeem-otsing.html',
            controller: 'MaaparandusRegisterSysteemOtsingCtrl'
        })
        .when('/maaparandus/msr/ehitise-otsing', {
            templateUrl: 'modules/maaparandus/maaparandus-ehitis-otsing.html',
            controller: 'MaaparandusRegisterEhitisOtsingCtrl'
        })
        .when('/maaparandus/msr/toimingud', {
            templateUrl: 'modules/maaparandus/maaparandus-arhiiv-otsing.html',
            controller: 'MaaparandusRegisterArhiivOtsingCtrl'
        })
        .when('/maaparandus/msr/raportid', {
            templateUrl: 'modules/maaparandus/maaparandus-raportid.html',
            controller: 'MaaparandusRegisterRaportidCtrl'
        })
        .when('/maaparandus/msr/toimik', {
            templateUrl: 'modules/maaparandus/maaparandus-toimik-otsing.html',
            controller: 'MaaparandusRegisterToimikOtsingCtrl'
        })
        .when('/maaparandus/toimik/:protsessId', {
            templateUrl: 'modules/maaparandus/toimik/maaparandus-toimik.html',
            controller: 'MaaparandusToimikCtrl'
        })
        .when('/maaparandus/systeem/:kood', {
            templateUrl: 'modules/maaparandus/systeem/systeem-vaade.html',
            controller: 'MaaparandusSysteemCtrl'
        })
        .when('/maaparandus/systeem/:systeemiKood/ehitis/:kood', {
            templateUrl: 'modules/maaparandus/ehitis/ehitis-vaade.html',
            controller: 'MaaparandusEhitisCtrl'
        })
        .when('/maaparandus/systeem/:systeemiKood/ehitis/:kood/kaart', {
            templateUrl: 'modules/maaparandus/ehitis/ehitis-kaart.html',
            controller: 'MaaparandusEhitisKaartCtrl'
        })
        // --- MATER ---
        .when('/maaparandus/mater', {
            templateUrl: 'modules/mater/mater-otsing.html',
            controller: 'MaterRegisterOtsingCtrl'
        })
        .when('/mater/ettevote/:ettevoteId', {
            templateUrl: 'modules/mater/mater-ettevote.html',
            controller: 'MaterEttevoteCtrl'
        })
        .when('/mater/ettevote/:ettevoteId/:spetsialistId', {
            templateUrl: 'modules/mater/mater-spetsialist.html',
            controller: 'MaterSpetsialistCtrl'
        })

        .when('/maaparandus/arhiiv/taotlus/:taotlusId', {
            templateUrl: 'modules/maaparandus/arhiiv/maaparandus-arhiiv-taotlus.html',
            controller: 'MaaparandusArhiivTaotlusCtrl'
        })
        .when('/maaparandus/arhiiv/teatis/:teatisId', {
            templateUrl: 'modules/maaparandus/arhiiv/maaparandus-arhiiv-teatis.html',
            controller: 'MaaparandusArhiivTeatisCtrl'
        })

        .when('/teenus/:teenusId/pma-te-mp-projekteerimistingimusted-taotlus/detailvaade', {
            templateUrl: 'modules/maaparandus/teenus/proj-tingimus-taotlus/proj-tingimused-taotlus.html',
            controller: 'ProjTingimustedTaotlusDetailvaadeCtrl'
        })
        .when('/teenus/:teenusId/pma-te-mp-projekteerimistingimusted/detailvaade', {
            templateUrl: 'modules/maaparandus/teenus/proj-tingimus/proj-tingimus.html',
            controller: 'ProjTingimusedDetailvaadeCtrl'
        })
        .when('/teenus/:teenusId/pma-te-mp-projekteerimistingimusted-keeld-teatis/detailvaade', {
            templateUrl: 'modules/maaparandus/teenus/proj-tingimus-keeld/proj-tingimus-keeldumine.html',
            controller: 'ProjTingimustedKeeldumineDetailvaadeCtrl'
        })
        .when('/teenus/:teenusId/pma-te-mp-ehitusloa-taotlus/detailvaade', {
            templateUrl: 'modules/maaparandus/teenus/ehitusluba-taotlus/ehitusluba-taotlus.html',
            controller: 'EhituslubaTaotlusDetailvaadeCtrl'
        })
        .when('/teenus/:teenusId/pma-te-mp-ehitusluba/detailvaade', {
            templateUrl: 'modules/maaparandus/teenus/ehitusluba/ehitusluba.html',
            controller: 'EhituslubaDetailvaadeCtrl'
        })
        .when('/teenus/:teenusId/pma-te-mp-ehitusluba-keeld/detailvaade', {
            templateUrl: 'modules/maaparandus/teenus/ehitusluba-keeld/ehitusluba-keeld.html',
            controller: 'EhituslubaKeeldDetailvaadeCtrl'
        })
        .when('/teenus/:teenusId/pma-te-mp-ehitusluba-kehtetus-taotlus/detailvaade', {
            templateUrl: 'modules/maaparandus/teenus/ehitusluba-kehtetus-taotlus/ehitusluba-kehtetus-taotlus.html',
            controller: 'EhituslubaKehtetusTaotlusDetailvaadeCtrl'
        })
        .when('/teenus/:teenusId/pma-te-mp-ehitusluba-kehtetus-otsus/detailvaade', {
            templateUrl: 'modules/maaparandus/teenus/ehitusluba-kehtetus-otsus/ehitusluba-kehtetus-otsus.html',
            controller: 'EhituslubaKehtetusOtsusDetailvaadeCtrl'
        })
        .when('/teenus/:teenusId/pma-te-mp-ehitamise-algus-teatis/detailvaade', {
            templateUrl: 'modules/maaparandus/teenus/ehitamise-algus-teatis/ehitamise-algus-teatis.html',
            controller: 'EhitamiseAlgusTeatisDetailvaadeCtrl'
        })
        .when('/teenus/:teenusId/pma-te-mp-kasutusluba-taotlus/detailvaade', {
            templateUrl: 'modules/maaparandus/teenus/kasutusluba-taotlus/kasutusluba-taotlus.html',
            controller: 'KasutuslubaTaotlusDetailvaadeCtrl'
        })
        .when('/teenus/:teenusId/pma-te-mp-kasutusluba/detailvaade', {
            templateUrl: 'modules/maaparandus/teenus/kasutusluba/kasutusluba.html',
            controller: 'KasutuslubaDetailvaadeCtrl'
        })
        .when('/teenus/:teenusId/pma-te-mp-kasutusluba-keeld/detailvaade', {
            templateUrl: 'modules/maaparandus/teenus/kasutusluba-keeld/kasutusluba-keeld.html',
            controller: 'KasutuslubaKeeldDetailvaadeCtrl'
        })
        .when('/teenus/:teenusId/pma-te-mp-kooskolastus-taotlus/detailvaade', {
            templateUrl: 'modules/maaparandus/teenus/kooskolastus-taotlus/kooskolastus-taotlus.html',
            controller: 'KooskolastusTaotlusDetailvaadeCtrl'
        })
        .when('/teenus/:teenusId/pma-te-mp-kooskolastus/detailvaade', {
            templateUrl: 'modules/maaparandus/teenus/kooskolastus/kooskolastus.html',
            controller: 'KooskolastusDetailvaadeCtrl'
        })
        .when('/teenus/:teenusId/pma-te-mp-kooskolastus-keeld/detailvaade', {
            templateUrl: 'modules/maaparandus/teenus/kooskolastus-keeld/kooskolastus-keeld.html',
            controller: 'KooskolastusKeeldDetailvaadeCtrl'
        })
        .when('/teenus/:teenusId/pma-te-mp-kasutusotstarve-lopetamine-taotlus/detailvaade', {
            templateUrl: 'modules/maaparandus/teenus/kasutusotstarve-lopetamine-taotlus/kasutusotstarve-taotlus.html',
            controller: 'KasutusotstarveLopetamineTaotlusDetailvaadeCtrl'
        })
        .when('/teenus/:teenusId/pma-te-mp-ehitusprojekti-kooskolastus-taotlus/detailvaade', {
            templateUrl: 'modules/maaparandus/teenus/ehitusprojekt-kooskolastus/ehitusprojekti-kooskolastus.html',
            controller: 'EhitusprojektiKooskolastusDetailvaadeCtrl'
        })
        // MAHE
        .when('/mahe', {
            templateUrl: 'modules/mahe/mahe-otsing.html',
            controller: 'MaheRegisterOtsingCtrl'
        })
        .when('/mahe/:registerId/detailvaade', {
            templateUrl: 'modules/mahe/detailvaade/mahe-register-detailvaade.html',
            controller: 'MaheRegisterDetailvaadeCtrl'
        })
        // Taimekaitse
        .when('/taimekaitse', {
            templateUrl: 'modules/taimekaitse/taimekaitse-register-header.html',
            controller: 'TaimekaitseRegisterHeaderCtrl'
        })
        .when('/taimekaitse/taimekaitsevahendid-otsing/:lang', {
            templateUrl: 'modules/taimekaitse/taimekaitsevahend/taimekaitsevahendid-nimekiri.html',
            controller: 'TaimekaitsevahendidOtsingCtrl'
        })
        .when('/taimekaitse/taimekaitsevahendid/:registerId/detailvaade/:lang', {
            templateUrl: 'modules/taimekaitse/taimekaitsevahend/detailvaade/taimekaitsevahendid-detailvaade.html',
            controller: 'TaimekaitsevahendidDetailvaadeController'
        })
        .when('/taimekaitse/koolitus-tunnistus-otsing', {
            templateUrl: 'modules/taimekaitse/koolitus/koolitus-tunnistus.html',
            controller: 'KoolitusTunnistusOtsingCtrl'
        })
        .when('/taimekaitse/tkv-turustuskohad', {
            templateUrl: 'modules/taimekaitse/turustuskohad/tk-turustuskohad.html',
            controller: 'TkvTurustuskohadOtsingCtrl'
        })
        .when('/taimekaitse/tkv-vagamurgised', {
            templateUrl: 'modules/taimekaitse/vagamurgised/tk-vagamurgised.html',
            controller: 'TkvVagaMurgisedOtsingCtrl'
        })
        //Vaetis
        .when('/vaetis', {
            templateUrl: 'modules/vaetis/vaetis-register-header.html',
            controller: 'VaetisRegisterHeaderCtrl'
        })
        .when('/vaetis/vaetised', {
            templateUrl: 'modules/vaetis/vaetised/register-vaetis.html',
            controller: 'RegisterVaetisCtrl'
        })
        .when('/vaetis/elvaetised', {
            templateUrl: 'modules/vaetis/elvaetised/register-elvaetis.html',
            controller: 'RegisterElVaetisCtrl'
        })
        //Taimetervis
        .when('/taimetervis', {
            templateUrl: 'modules/taimetervis/register-taimetervis-yld.html',
            controller: 'RegisterTaimetervisCtrl'
        })
        .when('/ettekirjutus', {
            templateUrl: 'modules/ettekirjutus/ettekirjutus-otsing.html',
            controller: 'EttekirjutusCtrl'
        })
        .when('/sort', {
            templateUrl: 'modules/sort/sort-otsing/sort-otsing-nimekiri.html',
            controller: 'SortOtsingCtrl'
        })
        .when('/sort/:kood/detailvaade', {
            templateUrl: 'modules/sort/sort-otsing/detailvaade/sort-detailvaade.html',
            controller: 'SortDetailvaadeCtrl'
        })
        .when('/access-denied', {
            templateUrl: '403.html'
        })

        .otherwise({
            templateUrl: '404.html'
        });

}).config(['$locationProvider', function($locationProvider) {
    $locationProvider.hashPrefix('');
}]);
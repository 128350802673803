'use strict';

angular.module('register.taimekaitse.taimekaitsevahend').controller('TaimekaitsevahendidOtsingCtrl', function ($rootScope, $scope, $routeParams, $filter, $q, ngTableHelperFactory, StateReminderService, notifyService, domeenService, domeenEnum, TaimekaitsevahendService) {
        $scope.language = $routeParams.lang;
        $scope.domeenEnum = domeenEnum;

        TaimekaitsevahendService.getKaubakood().$promise.then(function (result) {
            $scope.kaubakood = result.entity;
        });


        $scope.naitaVagaMyrgisedTkvKlf = domeenService.klassifikaatorElementAtribuutidegaQuery(domeenEnum.PMA_TKV_OTSING_MUUGIPIIRANG.elemendid.MUUGIPIIRANG_NAITA_AINULT_VAGA_MURGISEID_TAIMEKAITSEVAHENDEID);
        $scope.naitaVabamuugisTkvKlf = domeenService.klassifikaatorElementAtribuutidegaQuery(domeenEnum.PMA_TKV_OTSING_MUUGIPIIRANG.elemendid.MUUGIPIIRANG_NAITA_TAIMEKAITSEVAHENDEID_MIS_ON_VABAMUUGIS);
        $scope.naitaTkvTunnistuseAluselKlf = domeenService.klassifikaatorElementAtribuutidegaQuery(domeenEnum.PMA_TKV_OTSING_MUUGIPIIRANG.elemendid.MUUGIPIIRANG_NAITA_AINULT_TAIMEKAITSETUNNISTUSE_ALUSEL_MUUDAVAID_TAIMEKAITSEVAHENDEID);
        $scope.naitaLaiendatudKasutusalaTkvKlf = domeenService.klassifikaatorElementAtribuutidegaQuery(domeenEnum.PMA_TKV_OTSING_KASUTUSALA.elemendid.KASUTUSALA_LAIENDAMINE_NAITA_TAIMEKAITSEVAHENDEID_MILLE_KASUTUSALA_ON_LAIENDATUD);
        $scope.naitaKasutuspiirangTkvKlf = domeenService.klassifikaatorElementAtribuutidegaQuery(domeenEnum.PMA_TKV_OTSING_KASUTUSPIIRANG.elemendid.KASUTUSPIIRANG_NAITA_TAIMEKAITSEVAHENDEID_MILLEL_ON_KASUTUSPIIRANG);

        $scope.naitaLoaValdajaNimiKlf = domeenService.klassifikaatorElementAtribuutidegaQuery(domeenEnum.PMA_TKV_OTSING_LOA_VALDAJA_NIMI.elemendid.LOA_VALDAJA_NIMI);
        $scope.naitaAjapikendusMuugiksKlf = domeenService.klassifikaatorElementAtribuutidegaQuery(domeenEnum.PMA_TKV_OTSING_AJAPIKENDUS_MUUGIKS.elemendid.AJAPIKENDUS_MUUGIKS);
        $scope.naitaAjapikendusKasutamiseksKlf = domeenService.klassifikaatorElementAtribuutidegaQuery(domeenEnum.PMA_TKV_OTSING_AJAPIKENDUS_KASUTAMISEKS.elemendid.AJAPIKENDUS_KASUTAMISEKS);
        $scope.naitaToimeaineTkvKlf = domeenService.klassifikaatorElementAtribuutidegaQuery(domeenEnum.PMA_TKV_OTSING_TOIMEAINE.elemendid.TOIMEAINE);
        $scope.naitaKultuurKasutusalaKlf = domeenService.klassifikaatorElementAtribuutidegaQuery(domeenEnum.PMA_TKV_OTSING_KULTUUR_KASUTUSALA.elemendid.KULTUUR_KASUTUSALA);
        $scope.naitaKahjustajaKlf = domeenService.klassifikaatorElementAtribuutidegaQuery(domeenEnum.PMA_TKV_OTSING_KAHJUSTAJA.elemendid.KAHJUSTAJA);


        domeenService.klassifikaatorQuery(domeenEnum.PMA_TKV_STAATUS.nimi).$promise.then(function (result) {
            result.entities = _.filter(result.entities, function (elem) {
                return elem.kood !== domeenEnum.PMA_TKV_STAATUS.elemendid.TURULELUBAMISE_MENETLUS_ON_POOLELI && elem.kood !== domeenEnum.PMA_TKV_STAATUS.elemendid.TEHTUD_ON_KEELDUV_OTSUS;
            });
            $scope.tkvStaatusKlfid = result;
        });
        $scope.kultuurKlfid = domeenService.klassifikaatorQuery(domeenEnum.PMA_TKV_KASUTUSALA.nimi);
        $scope.kasutusviisKlfid = domeenService.klassifikaatorQuery(domeenEnum.PMA_TKV_KASUTUSVIIS.nimi);
        $scope.kasutamiskohtKlfid = domeenService.klassifikaatorQuery(domeenEnum.PMA_TKV_KASUTAMISE_KOHT.nimi);
        $scope.toimeKlfid = domeenService.klassifikaatorQuery(domeenEnum.PMA_TKV_TOIME_LIIK.nimi);
        $scope.tkvLiigidKlfid = domeenService.klassifikaatorQuery(domeenEnum.PMA_TKV_TKV_LIIK.nimi);
        $scope.tkvKahjustajadKlfid = domeenService.klassifikaatorQuery(domeenEnum.PMA_TKV_KAHJUSTAJA.nimi);
        $scope.toimeained = domeenService.klassifikaatorQuery(domeenEnum.PMA_TKV_PROOV_TOIMEAINE.nimi);


        if ($scope.language === 'en') {
            $scope.activeTab = 'plantProtectionProductsOtsing';
        } else {
            $scope.activeTab = 'taimekaitsevahendidOtsing';
        }

        $scope.selection = 'tkv';
        $scope.showExtended = false;
        $scope.filter = {
            keelKood: $scope.language,
            loaKehtivuseAlgus: {startDate: null, endDate: null},
            loaKehtivuseLopp: {startDate: null, endDate: null},
            ajapikendusMuugiks: {startDate: null, endDate: null},
            ajapikendusKasutamiseks: {startDate: null, endDate: null},
            viimatiMuudetud: {startDate: null, endDate: null},
            kaubakood: $scope.kaubakood
        };

        $scope.preserveState = function () {
            StateReminderService.preserveState($scope);
        };
        if (StateReminderService.hasPreviousState()) {
            var state = StateReminderService.getPreviousState();
            $scope.stateGiven = true;
            $scope.tableParams = state.scope.tableParams;
            $scope.filter = state.scope.filter;
            $scope.tableParams.reload();
        }
        else {
            $scope.tableParams = ngTableHelperFactory.newNgTableParamsAutoLoad('/v1/public/registrid/taimekaitsevahend/otsing', $scope.filter, {
                'tkvNimetus': 'asc'
            });
        }
        $scope.showExtendedSearch = function () {
            if ($scope.showExtended) {
                $scope.filter = {
                    keelKood: 'et',
                    loaKehtivuseAlgus: {startDate: null, endDate: null},
                    loaKehtivuseLopp: {startDate: null, endDate: null},
                    ajapikendusMuugiks: {startDate: null, endDate: null},
                    ajapikendusKasutamiseks: {startDate: null, endDate: null},
                    viimatiMuudetud: {startDate: null, endDate: null},
                    taimekaitsevahendNimi: $scope.filter.taimekaitsevahendNimi,
                    naitaVabamuugisTkv: $scope.filter.naitaVabamuugisTkv,
                    toimeaineList: $scope.filter.toimeaineList,
                    kultuurKasutusalaList: $scope.filter.kultuurKasutusalaList,
                    kahjustajaList: $scope.filter.kahjustajaList
                };
                $scope.tableParams.reset($scope.filter);
            }
            else {
                $scope.filter.kaubakood = $scope.kaubakood;
            }
            $scope.showExtended = !$scope.showExtended;
        };
        $scope.search = function () {
            $scope.tableParams.search();
        };
        $scope.navigateTeenus = function (teenusId) {
            $scope.preserveState();
        };

        $scope.reset = function () {
            $scope.filter = {
                keelKood: $scope.language,
                loaKehtivuseAlgus: {startDate: null, endDate: null},
                loaKehtivuseLopp: {startDate: null, endDate: null},
                ajapikendusMuugiks: {startDate: null, endDate: null},
                ajapikendusKasutamiseks: {startDate: null, endDate: null},
                viimatiMuudetud: {startDate: null, endDate: null}
            };
            if ($scope.showExtended) {
                $scope.filter.kaubakood = $scope.kaubakood;
            }
            $scope.tableParams.reset($scope.filter);
        };

        $scope.checkForPopover = function (atribuudid) {
            if (getPopOverAtribuut(atribuudid)) {
                return true;
            }
        };

        $scope.getPopover = function (atribuudid) {
            return getPopOverAtribuut(atribuudid);
        };

        $scope.getKlf = function (atribuudid) {
            if (atribuudid.entities) {
                return atribuudid.entities[0].element;
            }
        };

        var getPopOverAtribuut = function (atribuudid) {
            var vaartus = _.find(atribuudid.entities, function (atribuut) {
                return ($scope.language === 'en' && $scope.domeenEnum.PMA_SYS_DOMEEN_ATRIBUUT_TYYP.elemendid.ABIINFO_EN === atribuut.atribuutTyyp.kood) ||
                    ($scope.language === 'et' && $scope.domeenEnum.PMA_SYS_DOMEEN_ATRIBUUT_TYYP.elemendid.ABIINFO_ET === atribuut.atribuutTyyp.kood);
            });
            if (vaartus) {
                return vaartus.atribuutVaartus;
            }
        };

        $scope.findTkvByPartialKood = function (partialKood) {
            return TaimekaitsevahendService.getTaimekaitsevahendTypeaheadQuery(partialKood).$promise.then(function (result) {
                return result.entities;
            });
        };

        $scope.findLoaValdajaByPartialNimi = function (partialKood) {
            return TaimekaitsevahendService.getLoaValdajanimiTypeaheadQuery(partialKood).$promise.then(function (result) {
                return result.entities;
            });
        };

        $scope.findKaubakoodByPartialKood = function (partialKood) {
            return TaimekaitsevahendService.getKaubakoodTypeaheadQuery({typeahead: partialKood}).$promise.then(function (result) {
                return result.entities;
            });
        };

        $scope.findUfiByPartialKood = function (partialKood) {
            return TaimekaitsevahendService.getUfiTypeaheadQuery(partialKood).$promise.then(function (result) {
                return result.entities;
            });
        };

    }
);

'use strict';

angular.module('pma.translate.util', [])
    .filter('pmaTranslateBoolean', ['$translate',
        function ($translate) {
            return function (boolean, lang) {
                if (!lang) {
                    lang = 'et';
                }
                if (boolean === null) {
                    return $translate.instant('yld.maaramata', null, null, lang, null);
                }
                return $translate.instant(boolean ? 'yld.jah' : 'yld.ei', null, null, lang, null);
            };
        }
    ])
    .filter('pmaTranslateSde', function (domeenService) {
        return function (sde, lang) {
            if (!lang) {
                return domeenService.getTekstInCurrentLanguage(sde);
            } else {
                return domeenService.getTekstForLanguage(sde, lang);
            }
        };
    })
    .filter('pmaTranslateSdeSlashed', function (domeenService) {
        return function (sde, lang) {
           return domeenService.getTekstInCurrentLanguage(sde) + ' / ' + domeenService.getTekstForLanguage(sde, lang);        
        };
    })
    .filter('pmaTranslateSdeAtribuutWithTekst', function (domeenService) {
        return function (sde) {
            if (!sde) {
                return;
            }
            var text = domeenService.getTekstInCurrentLanguage(sde);
            return sde.atribuudid[0].atribuutVaartus + ' ' + text;
        };
    })
    .filter('pmaTranslateSdeAtribuut', function () {
        return function (sde) {
            if (!sde) {
                return;
            }
            return sde.atribuudid[0].atribuutVaartus;
        };
    })
    .filter('pmaTranslateSdeCode', ['$q', 'domeenService', 'domeenEnum', 'pmaTranslateSdeFilter',
        function ($q, domeenService, domeenEnum, pmaTranslateSdeFilter) {
            return function (kood, sdeList, lang) {
                var returnable = '';
                _.find(sdeList, function (sde) {
                    if (sde.kood === kood) {
                        returnable = pmaTranslateSdeFilter(sde, lang);
                    }
                });
                return returnable;
            };
        }])
    .filter('pmaTranslateSdeCodeAsync',
        function (domeenService, $filter) {
            return function (kood) {
                return (domeenService.klassifikaatorElementQuery(kood));
            };
        })
    /**
     * Funktsioon võtab paremeetrina 'params' hash/objekt/map ette ja tagastab uue objekti, kus sama võtmete all asuvad formateeritud väärtused.
     *
     * String: väärtus tõlgitakse, parameetri lisamine ei ole toetatud. Kui tekkib vajadus parameetri mitte tõlkida, siis on vaja
     * panna esimese sümboolina '#'. Sel juhul ülejäänud väärtuse osa läheb väljundisse 'as is'.
     *
     * Number: formateeritakse kasutades Angular 'number' filtrit. NB! Komakoha parameetri pääsemine ei ole hetkel toetatud!
     *
     * Date: formateeritakse kasutades Angular 'pmaDate' filtrit. NB! Kuupäeva formaadi parameetri pääsemine ei ole hetkel toetatud!
     * NB! FIXME (Kostja) Backend-ist tulnud kuupäevad on Number tüüpi ja neid formateeritakse numbrina!
     */
    .factory('pmaTranslateParamsFormat', ['$filter', '$translate',
        function ($filter, $translate) {
            return function (params) {
                if (_.isEmpty(params)) return undefined;

                var result = {};
                for (var key in params) {
                    var value = params[key];
                    if (_.isString(value)) {
                        if (value.startsWith('#')) {
                            result[key] = value.slice(1);
                        } else {
                            result[key] = $translate.instant(value);
                        }
                    } else if (_.isNumber(value)) {
                        result[key] = $filter('number')(value);
                    } else if (_.isDate(value)) {
                        result[key] = $filter('pmaDate')(value);
                    }
                }
                return result;
            };
        }
    ])
    .filter('pmaTranslate', ['$filter', 'pmaTranslateParamsFormat',
        function ($filter, pmaTranslateParamsFormat) {
            return function (labelCode, params) {
                return $filter('translate')(labelCode, pmaTranslateParamsFormat(params));
            };
        }
    ]);

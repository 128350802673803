'use strict';

angular.module('register.taimekaitse').controller ('TaimekaitseRegisterHeaderCtrl', function ($scope, $location) {

        $scope.tabs = [
            {title: 'register.taimekaitse.tab.avalik.taimekaitsevahendid_en', path: '/taimekaitse/taimekaitsevahendid-otsing/en', tabId: 'plantProtectionProductsOtsing', visible: true},
            {title: 'register.taimekaitse.tab.avalik.taimekaitsevahendid_et', path: '/taimekaitse/taimekaitsevahendid-otsing/et', tabId: 'taimekaitsevahendidOtsing', visible: true},
            {title: 'register.taimekaitse.tab.avalik.pealkiri_tk', path: '/taimekaitse/tkv-turustuskohad', tabId: 'tkvTurustuskohad', visible: true},
            {title: 'register.taimekaitse.tab.avalik.myrgine_tkv_kasutus.pealkiri', path: '/taimekaitse/tkv-vagamurgised', tabId: 'tkvVagamurgised', visible: true},
            {title: 'register.taimekaitse.tab.avalik.koolitus_tunnistus', path: '/taimekaitse/koolitus-tunnistus-otsing', tabId: 'tkKoolitusTunnistus', visible: true}
        ];

        if (!$scope.activeTab) {
            $location.path($scope.tabs[1].path);
        }

    }
);

'use strict';

angular.module('commons.general')
    .directive('pmaSelectPrint', function($timeout) {
        return {
            restrict: 'A',
            link: function(scope, iElement, iAttrs) {
                var jElement = $(iElement);
                jElement.addClass('print-display-none');
                jElement.after('<div class="textarea-copy"></div>');

                var copyValue = function() {
                    jElement.next('.textarea-copy').text(jElement.children("option:selected").text());
                };
                $timeout(copyValue, 100);
                scope.$watch(function() {
                    return scope.$eval(iAttrs.ngModel);
                }, copyValue);
                jElement.bind('change', copyValue);
            }
        }
    });

'use strict';

angular.module('register.mahe', ['chart.js']).controller('MaheRegisterOtsingCtrl', function ($scope, $filter, ngTableHelperFactory, StateReminderService, notifyService, domeenService, domeenEnum, $window, MaheService) {
        $scope.domeenEnum = domeenEnum;
        $scope.tegevusvaldkonnad = domeenService.klassifikaatorQuery(domeenEnum.PMA_MAHE_TEGEVUSVALDKOND.nimi);
        $scope.loomad = domeenService.klassifikaatorQuery(domeenEnum.PMA_MAHE_LOOM_GRUPP.nimi);
        $scope.loomadStaatus = domeenService.klassifikaatorQuery(domeenEnum.PMA_MAHE_LOOM_GRUPP_STAATUS.nimi);
        $scope.pollud = domeenService.klassifikaatorQuery(domeenEnum.POLLUMAJANDUSMAA_KASUTUS.nimi);
        $scope.polludStaatus = domeenService.klassifikaatorQuery(domeenEnum.PMA_MAHE_MAAKASUTUS_MAARATLUS.nimi);
        $scope.korje = domeenService.klassifikaatorQuery(domeenEnum.PMA_MAHE_KORJATAV_LIIK.nimi);

        $scope.height_chart = $window.innerHeight * 0.4;

        $scope.filter = {
            tegevusalaLoomisKp: {startDate: null, endDate: null}
        };
        $scope.tableParams = ngTableHelperFactory.newNgTableParams('/v1/public/register/mahe/otsing', $scope.filter, {});

        MaheService.getStatisticsForQuery().$promise.then(function (result) {
            $scope.tegevusLabels = [];
            $scope.tegevusData = [];
            $scope.colors = ["#46BBE8", "#46BBE8", "#46BBE8", "#46BBE8", "#46BBE8", "#46BBE8",
                "#46BBE8", "#46BBE8", "#46BBE8", "#46BBE8", "#46BBE8",
                "#46BBE8", "#46BBE8", "#46BBE8", "#46BBE8", "#46BBE8", "#46BBE8", "#46BBE8"];
            _.each(result.tegevusvaldkondToCount, function (value, key, object) {
                var result = key.replace(/.{35}\S*\s+/g, "$&@").split(/\s+@/);
                $scope.tegevusLabels.push(result);
                $scope.tegevusData.push(value);
            });
            $scope.tegevusLabels.reverse();
            $scope.tegevusData.reverse();

            $scope.tegevusOptions = {
                scales: {
                    yAxes: [{
                        gridLines: {
                            display: false
                        }
                    }]
                },
                title: {
                    display: true,
                    fontSize: 20,
                    fontFamily: 'Arial',
                    text: $filter('translate')('mahe.otsing.tegevusala')
                }
            };

            $scope.maakondLabels = [];
            $scope.maakondData = [];
            _.each(result.maakondToCount, function (value, key, object) {
                $scope.maakondLabels.push(key);
                $scope.maakondData.push(value);
            });

            $scope.maakondLabels.reverse();
            $scope.maakondData.reverse();

            $scope.maakondOptions = {
                scales: {
                    yAxes: [{
                        gridLines: {
                            display: false
                        }
                    }]
                },
                title: {
                    display: true,
                    fontSize: 20,
                    fontFamily: 'Arial',
                    text: $filter('translate')('mahe.otsing.maakond')
                }

            };
        });


        $scope.preserveState = function () {
            StateReminderService.preserveState($scope);
        };

        $scope.search = function () {
            notifyService.clearNotifications();
            $scope.tableParams.search();
        };
        $scope.$watch("tableParams.data", function (newVal, oldVal) {
            if ($scope.tableParams.data && $scope.tableParams.data.length > 0) {
                $scope.showTable = true;
            }
            else {
                $scope.showTable = false;
            }
        });

        $scope.containsSubString = function (actual, expected) {
            var lowerStr = (actual + "").toLowerCase();
            return lowerStr.indexOf(expected.toLowerCase()) !== -1;
        };


        $scope.resetSearch = function () {
            $scope.filter = {
                tegevusalaLoomisKp: {startDate: null, endDate: null}
            };
            $scope.tableParams.reset($scope.filter);
        };
    }
);


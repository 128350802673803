'use strict';


angular.module('domeen.service', []
).service('domeenService', function(config, $resource, $translate, restParamUtil/*, DSCacheFactory*/) {


    this.klassifikaatorQuery = function(loendKood, onSuccess) {
        return $resource(config.api + '/v1/public/system/domeenid/:kood/elemendid', {
            kood: loendKood
        }, {
            query: {
                method: 'GET'
            }
        }).get(onSuccess);
    };

    this.kbKlassifikaatorValdkonnadQuery = function(onSuccess) {
        return $resource(config.api + '/v1/public/register/ettekirjutused/valdkonnad', {
        }, {
          query: {
              isArray: true,
              method: 'GET'
          }
        }).query(onSuccess);
    };

    this.klassifikaatorElementQuery = function(kood, onSuccess) {
        return $resource(config.api + '/v1/public/system/domeenid/element/:kood', {
            kood: kood
        }).get(onSuccess);
    };

    this.getTekstInCurrentLanguage = function(sysDomeenElement) {
        return this.getTekstForLanguage(sysDomeenElement, $translate.use());
    };

    this.getTekstForLanguage = function(sysDomeenElement, language) {
        if (!sysDomeenElement) {
            return null;
        }

        for ( var i in sysDomeenElement.tekstid) {
            var t = sysDomeenElement.tekstid[i];
            if (t.keelKood === language) {
                return t.tekst;
            }
        }
        return sysDomeenElement.kood;
    };

    this.klassifikaatorAtribuutidegaQuery = function(loendKood, onSuccess) {
        return $resource(config.api + '/v1/public/system/domeenid/:kood/elemendid?attributes=true', { kood: loendKood }).get(onSuccess);
    };

    this.klassifikaatorElementAtribuutidegaQuery = function(elementKood, onSuccess) {
        return $resource(config.api + '/v1/public/system/domeenid/elemendid/:kood/atribuudid?attributes=true', { kood: elementKood }).get(onSuccess);
    };

});

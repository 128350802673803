'use strict';

angular.module('register.maaparandus').controller('MaaparandusRegisterSysteemOtsingCtrl',
    function ($scope, notifyService, regioService, ngTableHelperFactory, StateReminderService) {


        $scope.activeTab = 'systeemiOtsing';
        $scope.systeemid = {};
        $scope.systeemiOtsing = {
            sisestatud: {
                startDate: null, //moment().startOf('month'),
                endDate: null // moment()
            },
            kood: '',
            rvmPindalaAlates: '',
            rvmPindalaKuni: '',
            teePikkusAlates: '',
            teePikkusKuni: '',
            eesvooluPikkusAlates: '',
            eesvooluPikkusKuni: '',
            maakond: ''
        };
        $scope.otsingutunnus = 'asukoht';

        $scope.searched = false;

        $scope.tableParams = ngTableHelperFactory.newNgTableParams('/v1/public/register/maaparandus/otsing/systeem', $scope.systeemiOtsing, {
            'kood': 'asc'
        });

        if (StateReminderService.hasPreviousState()) {
            var state = StateReminderService.getPreviousState();
            $scope.tableParams = state.scope.tableParams;
            $scope.systeemiOtsing = state.scope.systeemiOtsing;
            $scope.tableParams.reload();
        }
        $scope.preserveState = function () {
            StateReminderService.preserveState($scope);
        };

        $scope.otsiSysteeme = function () {
            notifyService.clearNotifications();
            $scope.searched = true;
            $scope.tableParams.search();
        };

        $scope.resetSysteemiSearch = function () {
            $scope.systeemiOtsing = {};
            $scope.searched = false;
            $scope.tableParams.reset($scope.systeemiOtsing);
        };

        $scope.otsingutunnusChanged = function () {

            $scope.systeemiOtsing.aadress = null;

            $scope.systeemiOtsing.asukohtTase1 = null;
            $scope.systeemiOtsing.asukohtTase2 = null;
            $scope.systeemiOtsing.asukohtTase3 = null;

            $scope.systeemiOtsing.systeemikood = null;
            $scope.systeemiOtsing.katastritunnus = null;
        };

        $scope.getAddress = function (searchValue) {
            $scope.systeemiOtsing.aadress = searchValue;
            $scope.aadress = {};

            return regioService.getAddresses(searchValue);
        };

        $scope.aadressSelect = function (regioAadress) {
            regioService.convertToAadress(regioAadress, $scope.aadress);

            $scope.systeemiOtsing.aadress = $scope.aadress.aadress;
            $scope.systeemiOtsing.asukohtTase1 = $scope.aadress.detailid.tase1Ehak;
            $scope.systeemiOtsing.asukohtTase2 = $scope.aadress.detailid.tase2Ehak;
            $scope.systeemiOtsing.asukohtTase3 = $scope.aadress.detailid.tase3Ehak;
        };

        $scope.showFilter = function () {
            return $scope.searched;
        }

    }
);

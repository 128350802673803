'use strict';

angular.module('register.maaparandus').factory('MaaparandusArhiivService', ['$resource','$q','config', function($resource, $q, config) {

        var maaparandusArhiivService = {};

        maaparandusArhiivService.maaparandusArhiivTaotlusDto = $resource(config.api + '/v1/public/register/maaparandus/arhiiv/taotlus/:id', {});

        maaparandusArhiivService.getArhiivTaotlus = function(id) {
            return maaparandusArhiivService.maaparandusArhiivTaotlusDto.get({
                'id': id
            }, function(result) {

            });
        };

        maaparandusArhiivService.maaparandusArhiivTeatisDto = $resource(config.api + '/v1/public/register/maaparandus/arhiiv/teatis/:id', {});

        maaparandusArhiivService.getArhiivTeatis = function(id) {
            return maaparandusArhiivService.maaparandusArhiivTeatisDto.get({
                'id': id
            }, function(result) {

            });
        };

        return maaparandusArhiivService;
    }

]);
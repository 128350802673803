'use strict';

angular.module('teenus.navigation', []).service('teenusNavigationService', function($location,
                                                         $log,
                                                         domeenEnum,
                                                         notifyService) {

    function goTo(path) {
        if (path) {
            $location.path(path);
        }
    }

/*    this.navigateToSamm1 = function(teenusSeadistusId) {
        goTo(getSamm1Path(teenusSeadistusId));
    };

    this.navigateToSamm2 = function(teenusSeadistusId, kliendiNr, teenusId) {
        goTo(getSamm2Path(teenusSeadistusId, kliendiNr, teenusId));
    };

    this.navigateToSamm3 = function(teenusId, seadistusMenetlusTyypKood, seadistusKood) {
        goTo(getSamm3Path(teenusId, seadistusMenetlusTyypKood, seadistusKood));
    };*/

    this.navigateToSamm4 = function(teenusId, seadistusMenetlusTyypKood, seadistusKood) {
        goTo(getSamm4Path(teenusId, seadistusMenetlusTyypKood, seadistusKood));
    };

    this.navigateToDetailvaade = function(teenusId, teenusSeadistusKood) {
        goTo(getPathForDetail(teenusId, teenusSeadistusKood));
    };

 /*   this.navigateToRegisterDetail = function(registerId) {
        goTo(getPathForRegisterDetail(registerId));
    };

    function getSamm1Path(teenusSeadistusId) {
        if (!teenusSeadistusId) {
            return;
        }
        return 'teenus/teenus-seade/' + teenusSeadistusId + '/samm/1/';
    }*/

/*    function getSamm2Path(teenusSeadistusId, kliendiNr, teenusId) {
        if (!teenusSeadistusId || !kliendiNr) {
            $log.error('teenusNavigationService: getSamm2Path: teenusSeadistusId ja kliendiNr parameetrid on mõlemad kohustuslikud');
            return;
        }
        if (teenusId) {
            return 'teenus/' + teenusId + '/klient/' + kliendiNr + '/teenus-seade/' + teenusSeadistusId + '/samm/2';
        }
        return 'teenus/klient/' + kliendiNr + '/teenus-seade/' + teenusSeadistusId + '/samm/2';
    }*/

    var normalizeKoodForUrl = function(kood) {
        return kood.toLowerCase().replace(/_/g, '-');
    };
    
    this.normalizeKoodForUrl = normalizeKoodForUrl;

    function getPathForSamm3Or4(teenusId, teenusSeadistusMenetlusTyypKood, teenusSeadistusKood, sammNr) {
        if (!teenusId || !teenusSeadistusMenetlusTyypKood) {
            return;
        }
        if (teenusSeadistusMenetlusTyypKood === domeenEnum.MENETLUS_TYYP.elemendid.LIHT) {
            return 'teenus/' + teenusId + '/samm/' + sammNr;
        }
        if (!teenusSeadistusKood) {
            return;
        }
        return 'teenus/' + teenusId + '/' + normalizeKoodForUrl(teenusSeadistusKood) + '/samm/' + sammNr;

    }

    function getPathForDetail(teenusId, teenusSeadistusKood) {
        if (!teenusSeadistusKood) {
            return 'teenus/' + teenusId + '/detailvaade';
        }
        return 'teenus/' + teenusId + '/' + normalizeKoodForUrl(teenusSeadistusKood) + '/detailvaade';
    }

 /*   function getPathForRegisterDetail(registerId) {
        return 'registrid/' + registerId + '/detailvaade';
    }

    function getSamm3Path(teenusId, seadistusMenetlusTyypKood, seadistusKood) {
        return getPathForSamm3Or4(teenusId, seadistusMenetlusTyypKood, seadistusKood, 3);
    }*/

    function getSamm4Path(teenusId, seadistusMenetlusTyypKood, seadistusKood) {
        return getPathForSamm3Or4(teenusId, seadistusMenetlusTyypKood, seadistusKood, 4);
    }

/*
    var prevLocation = '';
    this.isSameAsPreviousLocation = function(newUrl) {
        if (newUrl === prevLocation) {
            return true;
        }
        return false;
    };
    this.storePreviousLocation = function(oldUrl) {
        prevLocation = oldUrl;
    };
    this.clearPreviousLocation = function() {
        prevLocation = '';
    };*/


}

);

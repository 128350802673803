'use strict';
angular.module('register.taimetervis').controller('RegisterTaimetervisCtrl',
    function ($scope, ngTableHelperFactory, domeenEnum, domeenService, StateReminderService) {

        $scope.filter = {
            registreerimineKp: {startDate: null, endDate: null},
            viimatiMuudetudKp: {startDate: null, endDate: null}
        };

        domeenService.klassifikaatorQuery(domeenEnum.PMA_TT_TEGEVUSALA.nimi).$promise.then(function (data) {
            $scope.tegevusalad = data.entities;
        });

        domeenService.klassifikaatorQuery(domeenEnum.PMA_SEEME_TEGEVUSALA.nimi).$promise.then(function (data) {
            $scope.seemeTegevusalad = data.entities;
        });


        $scope.search = function () {
            $scope.tableParams.search();
        };
        $scope.preserveState = function () {
            StateReminderService.preserveState($scope);
        };

        $scope.reset = function () {
            $scope.filter = {
                registreerimineKp: {startDate: null, endDate: null},
                viimatiMuudetudKp: {startDate: null, endDate: null}
            };

            $scope.tableParams.reset($scope.filter);
        };

        $scope.getKlientNimi = function (dto) {
            if (dto.klientKood) {
                return dto.teenusKlientNimi + " (" + dto.klientKood + ")";
            }
            return dto.klientTeenusNimi;
        };

        if (StateReminderService.hasPreviousState()) {
            var state = StateReminderService.getPreviousState();
            $scope.stateGiven = true;
            $scope.tableParams = state.scope.tableParams;
            $scope.filter = state.scope.filter;
            $scope.tableParams.settings().$scope = $scope;
            $scope.tableParams.reload();
        }
        else {
            $scope.tableParams = ngTableHelperFactory.newNgTableParamsAutoLoad('/v1/public/register/taimetervis',
                $scope.filter, {
                    registreerimineKp: {
                        startDate: moment(),
                        endDate: moment()
                    }
                });
        }
    }
);

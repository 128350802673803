'use strict';

angular.module('register.taimetervis')
    .directive('taimetervisVastutavadIsikudVaatamineDirective', function () {
        return {
            restrict: 'E',
            scope: {
                teade: "="
            },
            templateUrl: 'modules/taimetervis/taimetervis-vastutavad-isikud.html',
            link: function (scope) {
                 scope.items = [];

                var createKlientIdWatcher = function (jrkNr, descriptionLabel, volitusValdkondLabel, klient) {
                    scope.items.push({
                        jrkNr: jrkNr,
                        descriptionLabel: descriptionLabel,
                        volitusValdkondLabel: volitusValdkondLabel,
                        klient: klient
                    });
                };
                if (scope.teade.onTaimetervis && scope.teade.taimetervisVastutaja) {
                    createKlientIdWatcher(
                        1,
                        'register.taimetervis_yld.tabel.vastutaja_tyyp.taimetervis',
                        'teenus.taimetervis.samm4.vastutavad_isikud.tabel.volituse_valdkond.taimetervis',
                        scope.teade.taimetervisVastutaja);
                }
                if (scope.teade.onSeemePakendamine && scope.teade.seemePakendamineVastutaja) {
                    createKlientIdWatcher(
                        2,
                        'register.taimetervis_yld.tabel.vastutaja_tyyp.seemnete_pakendamine',
                        'teenus.taimetervis.samm4.vastutavad_isikud.tabel.volituse_valdkond.seemned',
                        scope.teade.seemePakendamineVastutaja);
                }
                if (scope.teade.onSeemePakendamine && scope.teade.soodaSeemePakendamineVastutaja) {
                    createKlientIdWatcher(
                        3,
                        'register.taimetervis_yld.tabel.vastutaja_tyyp.soodakultuuride_seemnete_pakendamine',
                        'teenus.taimetervis.samm4.vastutavad_isikud.tabel.volituse_valdkond.seemned',
                        scope.teade.soodaSeemePakendamineVastutaja);
                }
            }
        };
    });

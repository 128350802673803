'use strict';

angular.module('register.ettekirjutus')
  .controller('EttekirjutusCtrl',
    function($scope, ngTableHelperFactory, notifyService, domeenService, domeenEnum) {
      $scope.datePickerOptions = {};
      $scope.ettekirjutusFilter = {};

      domeenService.kbKlassifikaatorValdkonnadQuery().$promise.then(function(result) {
        $scope.valdkonnad = result;
      });
      $scope.ettekirjutusTable = ngTableHelperFactory.newNgTableParams('/v1/public/register/ettekirjutused', $scope.ettekirjutusFilter,
        {'ettekirjutuseNr': 'asc'});

      $scope.search = function() {
        notifyService.clearNotifications();
        $scope.ettekirjutusTable.search();
      };

      $scope.resetSearch = function() {
        $scope.ettekirjutusFilter = {};
        $scope.ettekirjutusTable.reset($scope.ettekirjutusFilter);
      };
    }
  );


'use strict';

angular.module('register.maaparandus').controller('MaaparandusEhitisKaartCtrl', function ($q, $http, $scope, $routeParams,
    MaaparandusService, sysParamService, sysParamEnum) {

    $scope.activeTab = 'kaart';
    $scope.systeemiKood = $routeParams.systeemiKood;
    $scope.kood = $routeParams.kood;
    $scope.eesvooludType = '';
    $scope.riigiEesvooludType = '';
    $scope.teenindavteeType = '';
    $scope.vorkType = '';
    $scope.schemaPrefixType = '';

    function getSysParameters() {
        var parameterPromiseArray = [];
        parameterPromiseArray.push(sysParamService.getValueByCode(sysParamEnum.MP_KAART_TYPE_NAME_EESVOOLUD).then(function (value) {
            $scope.eesvooludType = value.vaartus;
            return $scope.eesvooludType;
        }));
        parameterPromiseArray.push(sysParamService.getValueByCode(sysParamEnum.MP_KAART_TYPE_NAME_RIIGIEESVOOLUD).then(function (value) {
            $scope.riigiEesvooludType = value.vaartus;
            return $scope.riigiEesvooludType;
        }));
        parameterPromiseArray.push(sysParamService.getValueByCode(sysParamEnum.MP_KAART_TYPE_NAME_TEENINDAV_TEE).then(function (value) {
            $scope.teenindavteeType = value.vaartus;
            return $scope.teenindavteeType;
        }));
        parameterPromiseArray.push(sysParamService.getValueByCode(sysParamEnum.MP_KAART_TYPE_NAME_VORK).then(function (value) {
            $scope.vorkType = value.vaartus;
            return $scope.vorkType;
        }));
        parameterPromiseArray.push(sysParamService.getValueByCode(sysParamEnum.MP_KAART_TYPE_NAME_SCHEMA_PREFIX).then(function (value) {
            $scope.schemaPrefixType = value.vaartus;
            return $scope.schemaPrefixType;
        }));
        return parameterPromiseArray;
    }

    function transformCoordinatesToGeoJsonFeature(coordinatesAsString) {
        var lineStringCoordinates = _.map(coordinatesAsString.split(' '), function (point) {
            var points = point.split(',');
            return _.map(points, function (coordinate) {
                return parseFloat(coordinate);
            });
        });
        var geoJson = {
            type: 'Feature',
            properties: {},
            geometry: {
                type: 'LineString',
                coordinates: lineStringCoordinates
            }
        };
        return geoJson;
    }

    function parseLineString(geometry) {
        var geoJsonFeatures = [];
        if (geometry.LineString) {
            var coordinatesAsString = geometry.LineString.coordinates.__text;
            geoJsonFeatures.push(transformCoordinatesToGeoJsonFeature(coordinatesAsString));
        }

        if (geometry.MultiLineString) {
            geoJsonFeatures = _.map(geometry.MultiLineString.lineStringMember, function (lineStringMember) {
                var coordinatesAsString = lineStringMember.LineString.coordinates.__text;
                return transformCoordinatesToGeoJsonFeature(coordinatesAsString);
            });
        }
        return geoJsonFeatures;
    }

    function parsePolygon(geometry) {
        var polygonMembers = [];
        if (geometry.MultiPolygon) {
            polygonMembers = geometry.MultiPolygon.polygonMember;
        } else if (geometry.Polygon) {
            polygonMembers.push(geometry);
        }
        return _.map(polygonMembers, function (polygonMember) {
            var coordinatesAsString = polygonMember.Polygon.outerBoundaryIs.LinearRing.coordinates.__text;
            return transformCoordinatesToGeoJsonFeature(coordinatesAsString);
        });
    }

    function getGsonJsonFeatures(gmlXmlAsJson, typeName) {
        var geoJsonFeatures = [];
        if (gmlXmlAsJson.FeatureCollection
          && gmlXmlAsJson.FeatureCollection.featureMember
          && gmlXmlAsJson.FeatureCollection.featureMember[typeName]
          && gmlXmlAsJson.FeatureCollection.featureMember[typeName].SP_GEOMETRY) {
            var geometry = gmlXmlAsJson.FeatureCollection.featureMember[typeName].SP_GEOMETRY;
            if (geometry.LineString || geometry.MultiLineString) {
                geoJsonFeatures = parseLineString(geometry);
            } else if (geometry.MultiPolygon || geometry.Polygon) {
                geoJsonFeatures = parsePolygon(geometry);
            }
        }
        return geoJsonFeatures;
    }

    function queryObjectCoordinatesFromAgriAtlas(typeName) {
        return $http({
            method: 'GET',
            url: '/rest/Spatial/WFS',
            params: {
                service: 'WFS',
                version: '1.0.0',
                request: 'GetFeature',
                typeName: $scope.schemaPrefixType + ':' + typeName,
                maxFeatures: '10',
                outputFormat: 'GML2',
                srsName: 'EPSG:3301',
                filter: '<Filter xmlns="http://www.opengis.net/ogc">' +
                  '<And><PropertyIsEqualTo><PropertyName>MS_KOOD</PropertyName><Literal>' + $scope.systeemiKood + '</Literal></PropertyIsEqualTo>' +
                  '<PropertyIsEqualTo><PropertyName>EHITISE_KOOD</PropertyName><Literal>' + $scope.kood + '</Literal></PropertyIsEqualTo></And>' +
                  '</Filter>'
            }
        }).then(function (response) {
            var x2js = new X2JS();
            return x2js.xml_str2json(response.data);
        });
    }

    function highlight(typeName, gmlXmlAsJson) {
        var promise1 = Promise.resolve(gmlXmlAsJson);
        var promise2 = Promise.resolve(undefined);
        if (typeName === $scope.vorkType) {
            promise2 = queryObjectCoordinatesFromAgriAtlas($scope.eesvooludType);
        }
        $q.all([promise1, promise2]).then(function (data) {
            $scope.highlightGeoJsons = [];
            $scope.highlightGeoJsons.push({
                type: 'FeatureCollection',
                features: getGsonJsonFeatures(data[0], typeName),
                properties: {
                    color: 'red',
                    typeName: typeName
                }
            });
            if (data[1]) {
                $scope.highlightGeoJsons.push({
                    type: 'FeatureCollection',
                    features: getGsonJsonFeatures(data[1], $scope.eesvooludType),
                    properties: {
                        color: 'blue',
                        typeName: $scope.eesvooludType
                    }
                });
            }
        });
    }

    function tsentreeri(typeName) {
        queryObjectCoordinatesFromAgriAtlas(typeName)
          .then(function (gmlXmlAsJson) {
              if (gmlXmlAsJson.FeatureCollection && gmlXmlAsJson.FeatureCollection.featureMember && gmlXmlAsJson.FeatureCollection.boundedBy
                && gmlXmlAsJson.FeatureCollection.boundedBy.Box) {
                  var box = gmlXmlAsJson.FeatureCollection.boundedBy.Box.coordinates.__text;
                  var points = box.split(' ');
                  var c = points[0].split(',');
                  $scope.center.lat = parseFloat(c[1]);
                  $scope.center.lon = parseFloat(c[0]);
                  $scope.center.zoom = 14;
                  highlight(typeName, gmlXmlAsJson);
              }
          });
    }

    MaaparandusService.getMaaparandusEhitis($scope.systeemiKood, $scope.kood).$promise.then(function (ehitis) {
        $scope.ehitis = ehitis;
        $q.all(getSysParameters()).then(function () {
            var typeName = $scope.vorkType;
            if ($scope.kood && $scope.kood.startsWith('1')) {
                typeName = $scope.teenindavteeType;
            } else if ($scope.systeemiKood && $scope.systeemiKood.endsWith('0000')) {
                typeName = $scope.riigiEesvooludType;
            }
            tsentreeri(typeName);
        });
    });
});

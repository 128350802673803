'use strict';

angular.module('register.sort').controller('SortDetailvaadeCtrl', function ($rootScope, $scope, $routeParams, $filter, $q, ngTableHelperFactory, StateReminderService, notifyService, domeenService, domeenEnum, SortAvalikDetailvaadeService) {
        $scope.language = $routeParams.lang;
        $scope.domeenEnum = domeenEnum;
        $scope.routeRegisterId = parseInt($routeParams.kood);
        $scope.liikideKlassifikaatorid = [];

        SortAvalikDetailvaadeService.getSortAvalikDetailvaadeByRegisterId().get({registerId: $scope.routeRegisterId}).$promise.then(function (taotlus) {
            $scope.taotlus = taotlus;
            $scope.taotlus.synonyymidConcat = taotlus.synonyymid.map(
                function(synonyym) {
                    return synonyym.synonyym;
                }
            ).join(', ');
        });
    }
);
'use strict';

angular.module('register.mahe').factory('MaheService', ['$resource', 'config', '$q', function ($resource, config, $q) {

    var detailvaadeService = {};

    detailvaadeService.detailDto = $resource(config.api + '/v1/public/register/mahe/:registerId/detailvaade', {});

    detailvaadeService.getDetailidByRegisterId = function (registerId) {
        return detailvaadeService.detailDto.get({
            'registerId': registerId
        }, function (result) {

        });
    };

    detailvaadeService.statisticsDto = $resource(config.api + '/v1/public/register/mahe/statistics', {});

    detailvaadeService.getStatisticsForQuery = function (filter) {
        return detailvaadeService.statisticsDto.get(function (result) {
        });
    };
    return detailvaadeService;
}]);
'use strict';

angular.module('teenus.detailvaade', []).factory( 'TeenusDetailvaadeTeatedService', [ '$q', 'domeenEnum', function ( $q, domeenEnum ) {

    function showNewTeenusLink( teenusData ){
      return teenusData.viimaneKehtivTeenus !== null
        && teenusData.teenusId !== teenusData.viimaneKehtivTeenus
        && ( teenusData.teenusStaatus.kood === domeenEnum.TEENUS_STAATUS.elemendid.SISESTAMINE_LOPETATUD
        || teenusData.teenusStaatus.kood === domeenEnum.TEENUS_STAATUS.elemendid.OTSUS_ALLKIRJASTATUD);
    }

    function showChangeInProgressLink( teenusData ){
      return teenusData.viimaneKehtivTeenus !== null
        && teenusData.teenusId === teenusData.viimaneKehtivTeenus
        && teenusData.hasChildTeenusInStatusSisestamisel == true
        && ( teenusData.teenusStaatus.kood === domeenEnum.TEENUS_STAATUS.elemendid.SISESTAMINE_LOPETATUD
        || teenusData.teenusStaatus.kood === domeenEnum.TEENUS_STAATUS.elemendid.OTSUS_ALLKIRJASTATUD);
    }

    return {
      showNewTeenusLink    : function ( teenusData ) {
        return showNewTeenusLink( teenusData );
      },
      showChangeInProgressLink    : function ( teenusData ) {
        return showChangeInProgressLink( teenusData );
      }
    };
  }
] );

angular.module('teenus.detailvaade.teated', []).directive('pmaTeenusDetailvaadeTeated', ['TeenusMuutmine', 'TeenusDetailvaadeTeatedService',
  function (TeenusMuutmine, TeenusDetailvaadeTeatedService) {

    var link = function (scope) {

      scope.$watch(
        function() {
          return scope.teenusDetail;
        }, function(newValue, oldValue) {
          if (typeof newValue !== 'undefined' && newValue !== null) {
            scope.showNewTeenusLink = TeenusDetailvaadeTeatedService.showNewTeenusLink(scope.teenusDetail);
            scope.showChangeInProgressLink = TeenusDetailvaadeTeatedService.showChangeInProgressLink(scope.teenusDetail);
          }
        });

      scope.redirect = function( teenusId ) {
        if (teenusId != null) {
          TeenusMuutmine.doRedirect(teenusId, true);
        }
      }

    };

    return {
      restrict: 'E',
      scope: {
        teenusDetail: '='
      },
      link: link,
      templateUrl: 'modules/commons/teenus/teenus-detailvaade-teated.html'
    };
  }
]);

'use strict';

angular.module('commons.notify.const', [])
.constant('notifyConstants', {
    UNNAMED_REGION_NAME: '$$unnamed',
    ALERT_CSS_CLASSES: {
        INFO: 'alert-success',
        WARNING: 'alert-warning',
        ERROR: 'alert-danger'
    },
    GLYPHICON_CSS_CLASSES: {
        INFO: 'glyphicon-ok',
        WARNING: 'glyphicon-warning-sign',
        ERROR: 'glyphicon-remove'
    }
});


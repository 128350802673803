'use strict';

angular.module('register.maaparandus').controller('MaaparandusRegisterEhitisOtsingCtrl',
    function ($scope, notifyService, regioService, ngTableHelperFactory, StateReminderService, domeenEnum, domeenService) {

        $scope.activeTab = 'ehitiseOtsing';
        $scope.ehitised = {};
        $scope.ehitiseOtsing = {
            sisestatud: {
                startDate: null, //moment().startOf('month'),
                endDate: null // moment()
            },
            staatusKood: '',
            tabelSysteemKood: '',
            ehitiseKood: '',
            nimi: '',
            maakond: '',
            vald: '',
            kyla: '',
            staatus: ''
        };

        $scope.otsingutunnus = 'asukoht';

        $scope.searched = false;

        $scope.tableParams = ngTableHelperFactory.newNgTableParams('/v1/public/register/maaparandus/otsing/ehitis', $scope.ehitiseOtsing, {
            'systeemiKood': 'asc'
        });

        domeenService.klassifikaatorQuery(domeenEnum.PMA_MSR_EHITIS_STAATUS.nimi, function (results) {
            $scope.ehitiseSaatused = [];

            angular.forEach(results.entities, function (item) {
                if (item.kood !== 'PMA_MSR_EHITIS_STAATUS_KASUTUSELT_MAHA_VOETUD') {
                    $scope.ehitiseSaatused.push(item);
                }
            });
        });


        if (StateReminderService.hasPreviousState()) {
            var state = StateReminderService.getPreviousState();
            $scope.tableParams = state.scope.tableParams;
            $scope.ehitiseOtsing = state.scope.ehitiseOtsing;
            $scope.tableParams.reload();
        }
        $scope.preserveState = function () {
            StateReminderService.preserveState($scope);
        };

        $scope.otsiEhitised = function () {
            notifyService.clearNotifications();
            $scope.searched = true;
            $scope.tableParams.search();
        };

        $scope.resetEhitisSearch = function () {
            $scope.ehitiseOtsing = {};
            $scope.aadress = {};
            $scope.searched = false;
            $scope.tableParams.reset($scope.ehitiseOtsing);
        };

        $scope.otsingutunnusChanged = function () {

            $scope.ehitiseOtsing.aadress = null;

            $scope.ehitiseOtsing.asukohtTase1 = null;
            $scope.ehitiseOtsing.asukohtTase2 = null;
            $scope.ehitiseOtsing.asukohtTase3 = null;

            $scope.ehitiseOtsing.systeemikood = null;
            $scope.ehitiseOtsing.katastritunnus = null;
        };

        $scope.getAddress = function (searchValue) {
            $scope.ehitiseOtsing.aadress = searchValue;

            $scope.aadress = {};

            return regioService.getAddresses(searchValue);
        };

        $scope.aadressSelect = function (regioAadress) {
            regioService.convertToAadress(regioAadress, $scope.aadress);

            $scope.ehitiseOtsing.aadress = $scope.aadress.aadress;
            $scope.ehitiseOtsing.asukohtTase1 = $scope.aadress.detailid.tase1Ehak;
            $scope.ehitiseOtsing.asukohtTase2 = $scope.aadress.detailid.tase2Ehak;
            $scope.ehitiseOtsing.asukohtTase3 = $scope.aadress.detailid.tase3Ehak;
        };

        $scope.showFilter = function () {
            return $scope.searched;
        }

    }
);


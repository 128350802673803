'use strict';

angular.module('dokument.service', []).factory('DokumentService', function ($q, $resource, $window, config,
                                                                            $http, Dokument, notifyService) {

        var readOnlyMode = false;

        function setReadOnlyMode(mode) {
            if (_.isBoolean(mode)) {
                readOnlyMode = mode;
            }
        }

        function getNewDoc(dokumentId) {
            return {
                id: 'doc_new',
                dokumentId: dokumentId,
                teenusSeadistusFailId: null,
                nimetus: null,
                abitekst: '',
                kommentaar: '',
                klientKommentaar: '',
                dokumentTyypKood: null,
                jrkNr: null,
                editable: true,
                onKohustuslik: false,

                file: {},
                uploadId: null,
                uploadComplete: false,
                validityStateHolder: {
                    file: false,
                    kommentaar: true
                }
            }
        }

        function addUploadIdToFiles(document) {
            setDokument(document);
            if (_.isEmpty(document.files)) {
                var delay = $q.defer();
                delay.resolve();
                return delay.promise;
            }
            var promiseArray = [];
            for (var i = 0, len = document.files.length; i < len; i++) {
                if (!document.files[i].hasOwnProperty('uploadId')) {
                    document.files[i]['uploadId'] = null;
                }
                if (!document.files[i].hasOwnProperty('file')) {
                    document.files[i]['file'] = {};
                }
                if (!document.files[i].hasOwnProperty('validityStateHolder')) {
                    document.files[i]['validityStateHolder'] = {file: false, kommentaar: true};
                }
                if (!document.files[i].hasOwnProperty('uploadComplete')) {
                    document.files[i]['uploadComplete'] = false;
                }
                if (!document.files[i].hasOwnProperty('onKohustuslik')) {
                    (function (file) {
                        var promise = getOnKohustuslik(file);
                        promiseArray.push(promise);
                        promise.then(function (data) {
                            file['onKohustuslik'] = data;
                        });
                    })(document.files[i]);
                }
            }
            return $q.all(promiseArray);
        }

        function getFileDownloadUrl(dokumentFailId) {
            return config.api + '/v1/public/teenus/dokumentfail/' + dokumentFailId + '/content';
        }

        function getToendavDokumentDownloadUrl(dokumentFailId) {
            return config.api + '/v1/public/dokumentfail/' + dokumentFailId + '/toendav-dokument';
        }

        function getPublicFileDownloadUrl(kood, dokumentFailId, objectId) {
            return config.api + '/v1/public/' + kood + '/dokumentfail/' + objectId + '/content/' + dokumentFailId;
        }

        function downloadFile(dokumentFailId) {
            return $q(function (resolve, reject) {
                $window.open(getFileDownloadUrl(dokumentFailId), '_self');
                resolve();
            });
        }
        
        function getToendavDokumentPDFDownloadByRegisterIdUrl(registerId) {
            return config.api + '/v1/public/register/mahe/' + registerId + '/toendav-dokument/pdf';
        }

        var dokument = {};

        function getDokument() {
            return dokument;
        }

        function setDokument(teenusDoc) {
            dokument = teenusDoc;
        }


        return {
            getNewDoc: function (dokumentId) {
                return getNewDoc(dokumentId);
            },
            getFileDownloadUrl: function (dokumentFailId) {
                return getFileDownloadUrl(dokumentFailId);
            },
            downloadFile: downloadFile,
            setReadOnlyMode: function (mode) {
                return setReadOnlyMode(mode);
            },
            addUploadIdToFiles: function (document) {
                return addUploadIdToFiles(document);
            },
            getToendavDokumentDownloadUrl: function (dokumentFailId) {
                return getToendavDokumentDownloadUrl(dokumentFailId);
            },
            getPublicFileDownloadUrl: function (kood, objectId, dokumentFailId) {
                return getPublicFileDownloadUrl(kood, objectId, dokumentFailId);
            },
            getToendavDokumentPDFDownloadByRegisterIdUrl: function (registerId) {
                return getToendavDokumentPDFDownloadByRegisterIdUrl(registerId);
            }
        };
    }
);


angular.module('dokument.query', []).factory('Dokument', ['$resource', 'config', function ($resource, config) {
    return $resource(config.api + '/v1/public/teenus/dokumendid/:dokumentId/failid', {}, {
        getFailid: {
            method: 'GET',
            isArray: true
        }
    });
}]);

'use strict';

var service = angular.module('register.sort');

service.factory('SortAvalikDetailvaadeService', ['$resource', '$q', 'config', function ($resource, $q, config) {
    
    var sortAvalikDetailvaadeService = {};

    sortAvalikDetailvaadeService.getSortAvalikDetailvaadeByRegisterId = function () {
        return $resource(config.api + '/v1/public/register/sort/:registerId', {}, {
            'get': {
                method: 'GET', isArray: false, params: {
                    registerId: '@registerId'
                }
            }
        });
    };
    return sortAvalikDetailvaadeService;
}]);
'use strict';

angular.module('register.taimekaitse.taimekaitsevahend.detailvaade', []).factory('TaimekaitsevahendDetailvaadeService',
    function (config, $resource) {
        var taimekaitsevahendDetailvaadeService = {};

        taimekaitsevahendDetailvaadeService.taimekaitsevahendDto = $resource(config.api + '/v1/public/registrid/taimekaitsevahend/:registerId/detailvaade', {});

        taimekaitsevahendDetailvaadeService.getTaimekaitsevahendDetailvaadeQuery = function (registerId) {
            return taimekaitsevahendDetailvaadeService.taimekaitsevahendDto.get({
                'registerId': registerId
            }, function (result) {

            });
        };
        return taimekaitsevahendDetailvaadeService;

    });

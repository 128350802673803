angular.module('commons.number',[])
    .filter("pmaFormatNumber", function () {
        return function (value, precision) {
            value = value || "";
            if (value == 0) {
                return 0;
            }
            precision = precision || 0;
            var rounded, trimmed;

            rounded = (!isNaN(precision) && parseInt(precision, 10) > 0)
                ? parseFloat(value).toFixed(parseInt(precision, 10))
                : value;
            trimmed = parseFloat(rounded).toString();

            return trimmed;
        }
    }).filter("pmaNumber", function () {
    return function (value) {
        if (value && angular.isNumber(value)) {
            return value;
        }
        return null;
    }
});
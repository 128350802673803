'use strict';

angular.module('commons.klient', []).service('klientService', function($resource, $q, config) {

    this.getKlientsByPartialNimi = function(klientNimi) {
        var delay = $q.defer();
        $resource(config.api + '/v1/public/register/maaparandus/otsing/klient', {
            'nimi': klientNimi
        }, {
            'get': {
                method: 'GET',
                isArray: true
            }
        }).get(function(result) {
            delay.resolve(result);
        });
        return delay.promise;
    };

});
'use strict';

angular.module('register.taimekaitse.taimekaitsevahend.detailvaade').controller('TaimekaitsevahendidDetailvaadeController', function ($scope,
                                                                                                                                      $q,
                                                                                                                                      notifyService,
                                                                                                                                      domeenEnum,
                                                                                                                                      domeenService,
                                                                                                                                      $routeParams,
                                                                                                                                      TaimekaitsevahendDetailvaadeService) {
    $scope.language = $routeParams.lang;
    $scope.registerId = $routeParams.registerId;
    $scope.domeenEnum = domeenEnum;
    TaimekaitsevahendDetailvaadeService.getTaimekaitsevahendDetailvaadeQuery($scope.registerId).$promise.then(function (result) {
        $scope.taimekaitsevahend = result;
    });
    if ($scope.language === 'en') {
        $scope.activeTab = 'plantProtectionProductsOtsing';
    } else {
        $scope.activeTab = 'taimekaitsevahendidOtsing';
    }

});

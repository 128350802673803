'use strict';

angular.module('commons.notify', []).factory('notifyService', [
    'notifyConstants',
    'notifyStorageService',
    function(notifyConstants, notifyStorageService) {

        var NotifyService = {};

        function addNotificationToSection(notifications, regionType, regionName, code, params, type) {
            if (!notifications) {
                notifications = {};
            }
            if (!notifications[regionType]) {
                notifications[regionType] = {};
            }
            if (!notifications[regionType][regionName]) {
                notifications[regionType][regionName] = [];
            }
            notifications[regionType][regionName].push(NotifyService.constructNotification(code, params, type));
            return notifications;
        }

        /**
         * Sisend parameetriks on objekt {
         *     regionNotifications? as {
         *         'regionName': Notification.java[]
         *     },
         *     fieldNotifications? as {
         *         'fieldId': Notification.java[]
         *     }
         * }
         */
        NotifyService.notify = notifyStorageService.notify;

        NotifyService.addRegionNotification = function(notifications, regionName, code, params, type) {
            return addNotificationToSection(notifications, 'regionNotifications', regionName, code, params, type);
        };

        NotifyService.addFieldNotification = function(notifications, regionName, code, params, type) {
            return addNotificationToSection(notifications, 'fieldNotifications', regionName, code, params, type);
        };

        /**
         * helper method for costructing input object for notify() function  
         */
        NotifyService.constructRegionNotification = function(notificationList, regionName) {
            if (!notificationList || !Array.isArray(notificationList)) {
                return;
            }
            regionName = regionName || notifyConstants.UNNAMED_REGION_NAME;
            var regionNotifications = {};
            regionNotifications[regionName] = notificationList;
            return {
                'regionNotifications': regionNotifications
            };
        };

        /**
         * helper method for constructing a Notification.java type object
         */
        NotifyService.constructNotification = function(code, params, type) {
            return {
                'type': type || 'ERROR',
                'code': code,
                'params': params
            };
        };

        /**
         * Sisendparameetriks on {
         *     'väli id': 'error label code'
         * }
         */
        NotifyService.displayFieldErrors = function(errors) {
            var fieldNotifications = {};
            for (var key in errors) {
                fieldNotifications[key] = [ { code: errors[key], type: 'ERROR' } ];
            }
            notifyStorageService.notify({ fieldNotifications: fieldNotifications });
        };

        NotifyService.clearNotifications = function() {
            notifyStorageService.notify({ fieldNotifications: {}, regionNotifications: {} });
        };

        /**
         * 'errors' as string or string[], kus iga string on tõlgitav label
         * 'regionName' optional as string; kui ei ole määratud, siis pannakse globaalse teade (nimetamata regiooni)
         */
        NotifyService.displayRegionErrors = function(errors, regionName) {
            regionName = regionName || notifyConstants.UNNAMED_REGION_NAME;
            var regionNotifications = {};
            if (Array.isArray(errors)) {
                regionNotifications[regionName] = _.map(errors, function(error) {
                    return { code: error, type: 'ERROR' };
                });
            } else {
                regionNotifications[regionName] = [ { code: errors, type: 'ERROR' } ];
            }
            notifyStorageService.notify({ regionNotifications: regionNotifications });
        };
        
        /**
         * Peale selle meetodi väljakutsumist ei kustuta järjestikuste teenuseväljakutsete teated eelmisi vaid lisanduvad üksteisele.
         * Võimaldab siduda teenuseid ühtseteks loogilisteks gruppideks.
         * Koodis alati kasutada koos cancelRetainNotifications() meetodiga, mis tühistab antud käitumise.
         */
        NotifyService.retainNotifications = function(doNotClearExisting) {
            if(!doNotClearExisting){
                notifyStorageService.notify({ fieldNotifications: {}, regionNotifications: {} });
            }
            notifyStorageService.setRetainNotifications(true);
        };

        /**
         * Tagastab booleani, kas teenuseväljakutsete teateid veel hoitakse või mitte
         */
        NotifyService.getRetainNotifications = function() {
            return notifyStorageService.getRetainNotifications();
        };

        /**
         * Loe retainNotifications(); meetodi juhist
         */
        NotifyService.cancelRetainNotifications = function() {
            notifyStorageService.setRetainNotifications(false);
        };
        
        return NotifyService;

    }
]);

'use strict';

angular.module (
    'pma.dashboard',
    [
        'ngResource'
    ]
).controller ('DashboardCtrl',
    function ($scope, $resource, config /*sysParamService, sysParamEnum, domeenService, kbDomeenService, domeenEnum, UtilsFactory*/) {

    }
);
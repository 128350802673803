'use strict';
var pmaReg = angular.module('register.maaparandus');

pmaReg.controller('MaaparandusRegisterRaportidCtrl', function ($scope, config, domeenService, domeenEnum) {

        $scope.activeTab = 'raportid';

        $scope.form = {gruppeering : 'PMA_MSR_RAPORT_GRUPP_MAAKOND'};

        $scope.raportiGrupeeringud = domeenService.klassifikaatorQuery(domeenEnum.PMA_MSR_RAPORT_GRUPP.nimi);

        $scope.reports = [{
            title: 'register.maaparandus.tab.raportid.raport1',
            url: config.api + '/v1/public/register/maaparandus/raport/1'
        },{
            title: 'register.maaparandus.tab.raportid.raport2',
            url: config.api + '/v1/public/register//maaparandus/raport/2'
        },{
            title: 'register.maaparandus.tab.raportid.raport3',
            url: config.api + '/v1/public/register//maaparandus/raport/3'
        },{
            title: 'register.maaparandus.tab.raportid.raport4',
            url: config.api + '/v1/public/register//maaparandus/raport/4'
        },{
            title: 'register.maaparandus.tab.raportid.raport5',
            url: config.api + '/v1/public/register/maaparandus/raport/5'
        }]

    }
);

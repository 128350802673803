'use strict';

angular.module('commons.static.input', []).directive('pmaStaticInput', function () {

    return {
        restrict: 'AE',
        replace: true,
        scope: {
            label: '=',
            model: '=',
            id: '=?',
            required: '=?',
            inputClass: '=?',
            labelClass: '=?',
            textClass: '=?'
        },
        templateUrl: 'modules/commons/elements/pma-static-input.html'
    };
});
'use strict';

var pmaLayout = angular.module('pma.layout', [
    'templates-main',
    'ngRoute',
    'pma.config'
]);

pmaLayout.controller('HeaderCtrl', function($scope, $location, $window, config) {

    $scope.url = $window.location.href;
    $scope.title = config.title;
    $scope.api = config.api;
    $scope.dropdown = [
        {
            'text': '<i class=\'fa fa-download\'></i>&nbsp;Another action',
            'href': '#anotherAction'
        },
        {
            'text': '<i class=\'fa fa-globe\'></i>&nbsp;Display an alert',
            'click': '$alert(\'Holy guacamole!\')'
        },
        {
            'text': '<i class=\'fa fa-external-link\'></i>&nbsp;External link',
            'href': '/auth/facebook',
            'target': '_self'
        },
        {
            'divider': true
        },
        {
            'text': 'Separated link',
            'href': '#separatedLink'
        }
    ];

    $scope.openHelpPopup = function() {
        $window.open('modules/layout/customer-help.html', 'newwindow', 'width=780, height=800');
    };

});

pmaLayout.controller('FooterCtrl',
    function ($scope, $location, domeenService, domeenEnum) {
        var jaluseTekstMap = {
            '/maaparandus': domeenEnum.JALUS_TEKST.elemendid.JALUS_MAAPARANDUS,
            '/mahe': domeenEnum.JALUS_TEKST.elemendid.JALUS_MAHEPOLLUMAJANDUS,
            '/taimekaitse': domeenEnum.JALUS_TEKST.elemendid.JALUS_TAIMEKAITSE,
            '/vaetis': domeenEnum.JALUS_TEKST.elemendid.JALUS_VAETIS,
            '/taimetervis': domeenEnum.JALUS_TEKST.elemendid.JALUS_TAIMETERVIS,
            '/ettekirjutus': domeenEnum.JALUS_TEKST.elemendid.JALUS_ETTEKIRJUTUS
        };

        $scope.$watch(function () {
            return $location.path();
        }, function (value) {

            _.each(jaluseTekstMap, function (jalusTekst, path) {
                if ($location.path().includes(path)) {
                    domeenService.klassifikaatorElementQuery(jalusTekst).$promise.then(function (result) {
                        $scope.jalusTekst = result;
                    });
                }
            });

        });

    }
);

pmaLayout.directive('pmaActiveMenu', [
    '$location',
    function($location) {
        return {
            restrict: 'A',
            link: function(scope, element, attrs) {
                var linkPath = attrs.href.substring(1); // cut '#' off
                scope.$on('$routeChangeSuccess', function() {
                    if ($location.path() && $location.path().indexOf(linkPath) === 0) {
                        element.parent().addClass('active');
                    } else {
                        element.parent().removeClass('active');
                    }
                });
            }
        };
    }
]);

pmaLayout.directive('pmaActiveLink', [
    '$location', function ($location) {
        
        function MakeElementActiveIfUserOnPage(fullPath, hrefWithoutLeadingHashTag, manipulatedElement) {
            if (fullPath.startsWith(hrefWithoutLeadingHashTag)) {
                $(manipulatedElement).addClass('active');
            } else {
                $(manipulatedElement).removeClass('active');
            }
        }
        return {
            restrict: 'A',
            scope: {
                destination: '@to'
            },
            link: function (scope, element, attrs) {

                var hrefWithoutLeadingHashTag = attrs.href.substring(1);
                var fullPath = $location.path();

                //for example <li><a></a></li>, if we use 'pma-active-link to="parent"' in <a> element, then <li> element will be affected.
                var manipulatedElement = (scope.destination && scope.destination === 'parent') ? element.parent() : element;
                MakeElementActiveIfUserOnPage(fullPath, hrefWithoutLeadingHashTag, manipulatedElement);
            }
        };
    }
]);


    pmaLayout.controller('MenuCtrl', [
    '$scope',
    function($scope) {
        $scope.menuItems = [
            {
                path: '/maaparandus',
                title: 'avalik.vaade.menuu.maaparandus',
                visible: true
            },
            {
                path: '/mahe',
                title: 'avalik.vaade.menuu.mahe',
                visible: true
            },
            {
                path: '/taimekaitse',
                title: 'avalik.vaade.menuu.taimekaitse',
                visible: true
            },
            // vaetised
             {
                 path: '/vaetis',
                 title: 'avalik.vaade.menuu.vaetised',
                 visible: true
             },
            {
                path: '/taimetervis',
                title: 'avalik.vaade.menuu.taimetervis',
                visible: true
            },
          	{
                path: '/sort',
                title: 'avalik.vaade.menuu.sordid',
                visible: true
            },
            {
              path: '/ettekirjutus',
              title: 'avalik.vaade.menuu.ettekirjutused',
              visible: true
            }
            
        ];
    }
]);

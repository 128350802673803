'use strict';

angular.module('register.taimekaitse.taimekaitsevahend.detailvaade').directive('pmaTeTkvKasutamineDirective',
    function (notifyService,
              domeenEnum,
              domeenService) {
        return {
            restrict: 'E',
            scope: {
                taimekaitsevahend: '=',
                language: '='
            },
            templateUrl: 'modules/taimekaitse/taimekaitsevahend/detailvaade/komponendid/1-kasutamine/te-tk-tkv-kasutamine-directive.html',
            link: function ($scope) {

                $scope.$watch('taimekaitsevahend', function (newTaimekaitsevahend, oldTaimekaitsevahend) {
                    if (newTaimekaitsevahend) {
                        $scope.taimekaitsevahend = newTaimekaitsevahend;
                    }
                });

                $scope.taimekaitsevahend.tkvKasutusList = _.sortBy($scope.taimekaitsevahend.tkvKasutusList, function (sde) {
                    return domeenService.getTekstForLanguage(sde.kultuurKood, $scope.language);
                });


                $scope.formatMinMax = function (min, max) {
                    if (min && max) {
                        return min + ' - ' + max;
                    } else {
                        return min || max;
                    }
                };
            }
        };
    });

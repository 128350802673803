(function () {
    'use strict';
    angular.module('commons.slash.translate', []  ).filter("slashTranslate", function ($q, $translate) {

        return function (value, langList) {
            if (!langList) {
                langList = ['et', 'en'];
            }
            if (!angular.isArray(langList)) {
                langList = [langList];
            }
            var translateResultList = [];
            if (langList.length === 1) {
                return $translate.instant(value, null, null, langList[0], null);
            }
            angular.forEach(langList, function (lang) {
                var translatedValue = $translate.instant(value, null, null, lang, null);
                translateResultList.push(translatedValue);
            });
            return translateResultList.join(" / ");
        };
    });
})();

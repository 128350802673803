'use strict';

angular.module('commons.notify.service', []).factory('notifyStorageService', ['$q', 'config', 'notifyConstants',
    function ($q, config, notifyConstants) {

        var retainNotifications = false;
        var registeredUnnamedRegionHandlers = {};
        var registeredRegionHandlers = {};
        var registerAsRegionNotificationHandler = function(id, name) {
            var referenceHolder = { notifications: [] };
            if (name) {
                registeredRegionHandlers[name] = referenceHolder;
            } else {
                registeredUnnamedRegionHandlers[id] = referenceHolder;
            }
            return referenceHolder;
        };
        var unregisterAsRegionNotificationHandler = function(id, name) {
            if (name) {
                delete registeredRegionHandlers[name];
            } else {
                delete registeredUnnamedRegionHandlers[id];
            }
        };

        var registeredFieldHandlers = {};
        var registerAsFieldNotificationHandler = function(id) {
            var referenceHolder = { notifications: {} };
            registeredFieldHandlers[id] = referenceHolder;
            return referenceHolder;
        };
        var unregisterAsFieldNotificationHandler = function(id) {
            delete registeredFieldHandlers[id];
        };

        function thisOrEmptyArray(array) {
            if (angular.isArray(array)) {
                return array;
            }
            return [];
        }

        function thisOrEmptyObject(object) {
            return object || {};
        }

        var notify = function(responseBody) {
            var newRegionNotifications = responseBody.regionNotifications || {};
            if (retainNotifications) {
                _.each(registeredUnnamedRegionHandlers, function(handler, id) {
                    handler.notifications = handler.notifications.concat(thisOrEmptyArray(newRegionNotifications[notifyConstants.UNNAMED_REGION_NAME]));
                });
                _.each(registeredRegionHandlers, function(handler, name) {
                    handler.notifications = handler.notifications.concat(thisOrEmptyArray(newRegionNotifications[name]));
                });
            } else {
                _.each(registeredUnnamedRegionHandlers, function(handler, id) {
                    handler.notifications = thisOrEmptyArray(newRegionNotifications[notifyConstants.UNNAMED_REGION_NAME]);
                });
                _.each(registeredRegionHandlers, function(handler, name) {
                    handler.notifications = thisOrEmptyArray(newRegionNotifications[name]);
                });
            }

            var newFieldNotifications = responseBody.fieldNotifications || {};
            if (!_.isEmpty(registeredFieldHandlers) && retainNotifications) {
                newFieldNotifications = _.extend({}, newFieldNotifications, _.values(registeredFieldHandlers)[0].notifications);
            }
            _.each(registeredFieldHandlers, function(handler, name) {
                handler.notifications = newFieldNotifications;
            });
        };

        var setRetainNotifications = function(value){
            retainNotifications = value;
        };
        var getRetainNotifications = function(){
            return retainNotifications;
        };
        return {
            registerAsRegionNotificationHandler: registerAsRegionNotificationHandler,
            unregisterAsRegionNotificationHandler: unregisterAsRegionNotificationHandler,
            registerAsFieldNotificationHandler: registerAsFieldNotificationHandler,
            unregisterAsFieldNotificationHandler: unregisterAsFieldNotificationHandler,
            setRetainNotifications: setRetainNotifications,
            getRetainNotifications: getRetainNotifications,
            notify: notify
        };
    }
]);


'use strict';

angular.module('register.maaparandus.teenus').controller('ProjTingimustedTaotlusDetailvaadeCtrl',
    function($scope, $routeParams, TeenusDetail, TeenusMuutmine, MaaparandusTeenusService) {

        $scope.independent = true;

        $scope.teenusId = parseInt($routeParams.teenusId);

        function loadTeenusData(teenusId) {
            TeenusDetail.getDetailsForTeenus(teenusId).then(function(teenusData) {
                $scope.teenus = teenusData;
                $scope.teenusSeadeId = teenusData.teenusSeadistusId;
                $scope.kliendiNr = teenusData.kliendiNr;
                $scope.teenusId = teenusData.teenusId;
            });
        }

        function loadData() {

            loadTeenusData($scope.teenusId);

            MaaparandusTeenusService.getProjTingimusedTaotlus($scope.teenusId).$promise.then(function(projTingimusted) {
                $scope.projTingimusted = projTingimusted;
            });
        }

        loadData();

        $scope.avaTeenus = function (teenusId) {
            TeenusMuutmine.doRedirect(teenusId, false);
        };
        
    }
);

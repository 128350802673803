'use strict';

angular.module('register.taimekaitse.taimekaitsevahend.detailvaade').directive('pmaTeTkvKlassifikatsioon',
    function (notifyService,
              domeenEnum,
              domeenService) {
        return {
            restrict: 'E',
            scope: {
                taimekaitsevahend: '=',
                language: '='
            },
            templateUrl: 'modules/taimekaitse/taimekaitsevahend/detailvaade/komponendid/4-klassifikatsioon/te-tk-tkv-klassifikatsioon-directive.html',
            link: function ($scope) {
                $scope.domeenEnum = domeenEnum;
                $scope.$watch('taimekaitsevahend', function (newTaimekaitsevahend, oldTaimekaitsevahend) {
                    if (newTaimekaitsevahend) {

                    }
                });
            }
        }
    });

'use strict';

angular.module('dokument.directive', []).directive('dokument', ['$rootScope', 'DokumentService', 'domeenService',
    'domeenEnum', 'notifyService', 'FileDownloadService',
    function ($rootScope, DokumentService, domeenService, domeenEnum, notifyService, FileDownloadService) {

        var link = function (scope) {

            domeenService.klassifikaatorQuery(domeenEnum.DOKUMENT_TYYP.nimi).$promise.then(function (data) {
                    scope.dokumendiTyybid = data.entities;
                }
            );

            scope.getFileDownloadUrl = function (dokumentFailId) {
                return FileDownloadService.getFileDownloadUrl(dokumentFailId);
            };

        };

        return {
            restrict: 'E',
            scope: {
                dokument: '=',
                readOnly: '='
            },
            link: link,
            templateUrl: 'modules/commons/dokument/dokument.html'
        };
    }
]);
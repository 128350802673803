'use strict';

angular.module('pma.layout')

.directive('pmaMain', function($rootScope) {


    return {
        restrict: 'E',
        templateUrl: 'modules/layout/main.html',
        controller: function($scope) {

        }
    };

})
;
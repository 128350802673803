'use strict';

angular.module('register.taimekaitse.koolitus').controller('KoolitusTunnistusOtsingCtrl', function ($scope, $q, KoolitusTunnistusService, notifyConstants) {
        $scope.activeTab = 'tkKoolitusTunnistus';

        $scope.selection = 'tk-koolitus-tunnistus';

        $scope.wrapper = {};

        $scope.ALERT_CSS_CLASSES = notifyConstants.ALERT_CSS_CLASSES;
        $scope.GLYPHICON_CSS_CLASSES = notifyConstants.GLYPHICON_CSS_CLASSES;

        $scope.searchByIk = function () {
            KoolitusTunnistusService.getKoolitusTunnistusQuery($scope.wrapper.isikukood).$promise.then(function (result) {
                $scope.isikTunnistused = result.entities;
            });
        };

    }
);

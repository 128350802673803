'use strict';

angular.module('register.mater').controller('MaterEttevoteCtrl', function($scope, $routeParams, MaterService) {

    $scope.ettevoteId = $routeParams.ettevoteId;
    $scope.ettekirjutused = [];

    MaterService.getEttevoteByEttevoteId($scope.ettevoteId).$promise.then(function(ettevote) {
      $scope.ettevote = ettevote;
    });

    MaterService.getEttekirjutused($scope.ettevoteId).then(function(result) {
      $scope.ettekirjutused = result.entities;
    });


    $scope.onAktiivne = function(spetsialist) {
      return function(spetsialist) {
        return spetsialist && spetsialist.valdkonnad.length > 0;
      };
    };

  }
);

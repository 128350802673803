'use strict';

angular.module('register.taimekaitse.taimekaitsevahend.detailvaade').directive('pmaTeTkvYldandmedDirective',
    function (notifyService,
              domeenEnum) {
        return {
            restrict: 'E',
            scope: {
                taimekaitsevahend: '=',
                language: '='
            },
            templateUrl: 'modules/taimekaitse/taimekaitsevahend/detailvaade/komponendid/2-tkv-yldandmed/te-tk-tkv-yldandmed-directive.html',
            link: function ($scope) {
                $scope.domeenEnum = domeenEnum;
                $scope.$watch('taimekaitsevahend', function (newTaimekaitsevahend, oldTaimekaitsevahend) {

                });
            }
        };
    });


angular.module('commons.state', ['ngCookies']).service('StateReminderService', function($location) {

        var states = [];
        var statesByKeyword = [];
        var isPreviousStatePreserved = false;
        var isGoBackEvent = false;

        function State(url, scope) {
            this.url = url;
            this.scope = scope;
        }
        function StateByKeyword(keyword, scope) {
            this.keyword = keyword;
            this.scope = scope;
        }

        this.preserveState = function(scopeState) {
            if (scopeState.tableParams) {
                scopeState.tableParams
            }
            states.push(new State($location.url(), scopeState));
            isPreviousStatePreserved = true;
        };


        this.preserveStateByKeyword = function(keyWord, scopeState) {
            statesByKeyword.push(new StateByKeyword(keyWord, scopeState));
        };

        this.getPreviousStateByKeyword = function (keyword) {
            for (var i = 0; i < statesByKeyword.length; i++) {
                if (statesByKeyword[i] && keyword === statesByKeyword[i].keyword) {
                    var returnState = {};
                    angular.extend(returnState, statesByKeyword[i]);
                    delete statesByKeyword[i];
                    return returnState;
                }
            }
            return undefined;
        };


        this.getPreviousState = function () {
            var state = states.pop();
            return state;
        };

        this.onLocationChange = function(url) {
            if (states.length != 0 && url == states[states.length - 1].url) {
                isGoBackEvent = true;
                return states[states.length - 1].scope;
            }
            if (isGoBackEvent) {
                isGoBackEvent = false;
            } else {
                if (!isPreviousStatePreserved) {
                    states = [];
                }
            }
            isPreviousStatePreserved = false;
        };

        this.hasPreviousState = function () {
            return states.length > 0;
        };
});

'use strict';

angular.module('pma')
    .filter('dateInRange', function() {
      return function(items, dateRanges, key, format) {
        var startDate = dateRanges.startDate;
        var endDate = dateRanges.endDate;
        var validItems = [];
        angular.forEach(items, function(item) {
          var date = moment(item[key], format);
          if (moment().range(startDate, endDate).contains(date)) {
            validItems.push(item);
          }
        });
        return validItems;
      };
    });
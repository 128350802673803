'use strict';

angular.module('commons.tables', []).factory('editableTableFactory', function(
                                                       notifyService,
                                                       editableTableParam) {


    var LIFECYCLE_STATE = {
        ADDED: 'ADDED',
        MODIFIED: 'MODIFIED',
        REMOVED: 'REMOVED',
        UNCHANGED: 'UNCHANGED',
        IGNORED: 'IGNORED'
    };

    var isActive = function(item) {
        return !item.lifecycleState || (item.lifecycleState !== LIFECYCLE_STATE.REMOVED && item.lifecycleState !== LIFECYCLE_STATE.IGNORED);
    };

    function EditableTable(list, params) {
        this.list = list;
        this.editedElement = undefined;
        this.onAdd = params[editableTableParam.onAdd];
        this.onSave = params[editableTableParam.onSave];
        this.onRemove = params[editableTableParam.onRemove];
        this.removeConfirmationLabel = params[editableTableParam.removeConfirmationLabel];
        this.onCancelEdit = params[editableTableParam.onCancelEdit];
        this.initNewElement = params[editableTableParam.initNewElement];
        this.initEditableElement = params[editableTableParam.initEditableElement];
        this.canEdit = params[editableTableParam.canEdit];
        this.newElement = this.initNewElement ? this.initNewElement() : {};
        this.lifecycleMode = params[editableTableParam.lifecycleMode];
        this.refresh = params[editableTableParam.refresh];

    }

    EditableTable.prototype.isActive = isActive;

    return {
        newEditableTable: function(list, params) {
            return new EditableTable(list, params ? params : {});
        },
        isActive: isActive
    };

});

angular.module('commons.tables.constants', []).constant('editableTableParam', {
    onValidate: 'onValidate',
    onValidateAndProcessAsync: 'onValidateAndProcessAsync',
    onAdd: 'onAdd',
    onSave: 'onSave',
    onRemove: 'onRemove',
    initNewElement: 'initNewElement',
    initEditableElement: 'initEditableElement',
    canEdit: 'canEdit',
    onCancelEdit: 'onCancelEdit',
    lifecycleMode: 'lifecycleMode',
    removeConfirmationLabel: 'removeConfirmationLabel',
    refresh: 'refresh'
});
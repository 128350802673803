'use strict';

angular.module('commons.counties', []).directive('pmaCountySelection', function (domeenEnum, domeenService) {
    var COUNTY_CODE_SEPARATOR = '++';

    return {
        restrict: 'E',

        scope: {
            id: '@?',
            ngModel: '=',
            ngDisabled: '=?',
            placeholder: '@?',
            noResultsPlaceholder: '=?'
        },

        templateUrl: 'modules/commons/elements/pma-county-selection-directive.html',
        

        compile: function(){
            return {
                pre: function(scope){
                    this.setPlaceholderDefaultValueIfNotDefined(scope);
                    this.setNoResultsPlaceholderDefaultValueIfNotDefined(scope);
                    this.createGetCountyCodes(scope);
                },

                post: function(scope){
                    this.getCounties(scope);
                }
            }
        },

        setPlaceholderDefaultValueIfNotDefined: function (scope) {
            if (scope.placeholder === undefined) {
                scope.placeholder = ' ';
            }
        },

        setNoResultsPlaceholderDefaultValueIfNotDefined: function (scope) {
            if (scope.noResultsPlaceholder === undefined) {
                scope.noResultsPlaceholder = ' ';
            }
        },

        createGetCountyCodes: function (scope) {
            scope.getCountyCodes = function (countyObject) {
                var countyCodes = [];
                for (var attributeId in countyObject.atribuudid) {
                    countyCodes.push(countyObject.atribuudid[attributeId].atribuutVaartus);
                }
                return countyCodes.join(COUNTY_CODE_SEPARATOR);
            }
        },

        getCounties: function (scope) {
            domeenService.klassifikaatorAtribuutidegaQuery(domeenEnum.ADS_TASE_1_OTSING.nimi).$promise.then(function (response) {
                scope.counties = response.entities;
            });
        }
    };
});
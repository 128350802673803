'use strict';
var pmaTeenusSort = angular.module('register.sort');

pmaTeenusSort.directive('pmaSortAndmedSordiKohta',
  function (domeenEnum, pmaTranslateSdeFilter, domeenService) {
    return {
      restrict: 'E',

      scope: {
        taotlus: '=',
        readOnly: '<'
      },

      templateUrl: 'modules/sort/sort-otsing/directive/sort-andmed-sordi-kohta-directive.html',
      link: function (scope) {
        scope.liikideKlassifikaatorid = [];
        scope.kultuurideKlassifikaatorid = [];
        scope.liigiKultuurideKlassifikaatorid = {entities: []};
        scope.liigiEestiJaInglisekeelneNimi = '';

        domeenService.klassifikaatorQuery(domeenEnum.PMA_TAIM_LIIK.nimi, function(klassifikaatorid) {
          scope.liikideKlassifikaatorid = klassifikaatorid;
          changeClassificationBasedOutputs(scope.taotlus);
        });

        domeenService.klassifikaatorQuery(domeenEnum.PMA_TAIM_LIIK_KULTUUR.nimi, function(klassifikaatorid) {
          scope.kultuurideKlassifikaatorid = klassifikaatorid;
          changeClassificationBasedOutputs(scope.taotlus);
        });

        scope.$watch('taotlus.liik', function(uusLiik) {
          scope.changeLiigiEestiJaInglisekeelneNimi(uusLiik);
          scope.changeLiigiKultuurideKlassifikaatorid(uusLiik);
        });

        scope.$watch('taotlus', function(uusTaotlus) {
          changeClassificationBasedOutputs(uusTaotlus);
        });

        function changeClassificationBasedOutputs(taotluseAndmed) {
          if (!taotluseAndmed || !taotluseAndmed.liik) {
             return;
          }
          scope.changeLiigiEestiJaInglisekeelneNimi(taotluseAndmed.liik);
          scope.changeLiigiKultuurideKlassifikaatorid(taotluseAndmed.liik);
        }

        scope.changeLiigiEestiJaInglisekeelneNimi = function (liigiKood) {
          if (!liigiKood || !scope.liikideKlassifikaatorid || !scope.liikideKlassifikaatorid.entities) {
            return;
          }
          for (var i in scope.liikideKlassifikaatorid.entities) {
            if (scope.liikideKlassifikaatorid.entities[i].kood === liigiKood) {
              scope.liigiEestiJaInglisekeelneNimi = pmaTranslateSdeFilter(scope.liikideKlassifikaatorid.entities[i], 'et') + ' / ' + pmaTranslateSdeFilter(scope.liikideKlassifikaatorid.entities[i], 'en');
              return;
            }
          }
        };

        scope.getLatinTranslatedText = function(kbSde) {
          return pmaTranslateSdeFilter(kbSde, 'la');
        };

        scope.changeLiigiKultuurideKlassifikaatorid = function (liigiKood) {
          if (!scope.kultuurideKlassifikaatorid.entities || !liigiKood) {
            return;
          }
          scope.liigiKultuurideKlassifikaatorid.entities = scope.kultuurideKlassifikaatorid.entities.filter(function(klassifikaator) {
            return klassifikaator.vanemKood === liigiKood;
          });
        };
      }
    }
  }
);




'use strict';


angular.module('register.maaparandus').controller('MaaparandusArhiivTaotlusCtrl', function ($scope, $routeParams, MaaparandusArhiivService) {

    $scope.taotlusId = $routeParams.taotlusId;

    MaaparandusArhiivService.getArhiivTaotlus($scope.taotlusId).$promise.then(function (taotlus) {
        $scope.taotlus = taotlus;
    });

});

'use strict';

angular.module('commons.textareasimple', []).directive('pmaTextareaSimple', function () {
    return {
        restrict: 'E',
        scope: {
            model: '=',
            id: '=?',
            isDisabled: '=?',
            rows: '=?'
        },
        templateUrl: 'modules/commons/elements/pma-textarea-simple.html',
        link: function (scope) {
            if (!scope.rows) {
                scope.rows = 1;
            }
        }
    };
});

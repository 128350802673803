'use strict';

angular.module('register.mater').controller('MaterRegisterOtsingCtrl', function ($scope, ngTableHelperFactory, StateReminderService, notifyService, klientService) {

        $scope.datePickerOptions = {};
        $scope.ettevotteOtsing = {
            sisestatud: {
                startDate: null, //moment().startOf('month'),
                endDate: null // moment()
            }
        };

        $scope.tableParams = ngTableHelperFactory.newNgTableParams('/v1/public/register/maaparandus/ettevote/otsing', $scope.ettevotteOtsing, {
			'ettevotja_nimi': 'asc'});

        if (StateReminderService.hasPreviousState()) {
            var state = StateReminderService.getPreviousState();
            $scope.tableParams = state.scope.tableParams;
            $scope.ettevotteOtsing = state.scope.ettevotteOtsing;
            $scope.tableParams.reload();
        }
        $scope.preserveState = function () {
            StateReminderService.preserveState($scope);
        };

        $scope.otsiEttevotteid = function () {
            notifyService.clearNotifications();
            $scope.tableParams.search();
        };

        $scope.resetSearch = function () {
            $scope.ettevotteOtsing = {};
            $scope.tableParams.reset($scope.ettevotteOtsing);
        };

        $scope.getKlients = function(klient) {
            return klientService.getKlientsByPartialNimi(klient).then();
        };
    }
);


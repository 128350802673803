'use strict';

(function() {
    angular.module('commons.date').directive('pmaPopupDateRangePicker', function($modal, $filter, notifyService, notifyConstants) {
        return {
            restrict: 'E',
            scope: {
                model: '=',
                id: '=?',
                isDisabled : '=?',
                slashtranslate: '=?',
                isWithBothEndsSelectedOnly : '=?',
                minDate : '=?',
                maxDate : '=?',
                popoverText: '=?',
                language: '=?'
            },
            templateUrl: function (elem, attrs) {
                if (attrs.hasOwnProperty('popoverText')) {
                    return 'modules/commons/elements/pma-popup-date-range-picker-popover.html';
                }
                return 'modules/commons/elements/pma-popup-date-range-picker.html';
            },
            link : function(scope, element) {
                var noRangeBorderDisplayValue = '...';
                var rangeDatesDelimiterDisplayValue = ' - ';
                var directiveScope = scope;

                if (!directiveScope.language){
                    directiveScope.language = 'et';
                }
                scope.displayValue = '';

                var calculateDisaplayValueOnModel = function () {
                    if (!directiveScope.model || !(directiveScope.model.startDate || directiveScope.model.endDate)) {
                        directiveScope.displayValue = '';
                    } else {
                        directiveScope.displayValue = angular.isDate(directiveScope.model.startDate) ? $filter('pmaDate')(directiveScope.model.startDate, 'date') : noRangeBorderDisplayValue;
                        directiveScope.displayValue = directiveScope.displayValue + rangeDatesDelimiterDisplayValue;
                        directiveScope.displayValue = directiveScope.displayValue  + (angular.isDate(directiveScope.model.endDate) ? $filter('pmaDate')(directiveScope.model.endDate, 'date') : noRangeBorderDisplayValue);
                    }
                };

                scope.clearSelection = function () {
                    directiveScope.model = null;
                    calculateDisaplayValueOnModel();
                };

                scope.openForSelection = function () {
                    var modalInstance = $modal.open({
                        templateUrl: 'modules/commons/elements/pma-popup-date-range-picker-modal.html',
                        backdrop: 'static',
                        windowClass: 'narrow',
                        resolve : {
                            languageWrapper : {language: directiveScope.language},
                            minDate : directiveScope.minDate,
                            maxDate : directiveScope.maxDate,
                            slashtranslate: directiveScope.slashtranslate
                        },
                        controller: function($scope, languageWrapper, minDate, maxDate, slashtranslate) {
                            var _this = this;
                            _this.localStartDate =  directiveScope.model && directiveScope.model.startDate ? directiveScope.model.startDate : null;
                            _this.localEndDate =  directiveScope.model && directiveScope.model.endDate ? directiveScope.model.endDate : null;

                            $scope.minDate = minDate;
                            $scope.maxDate = maxDate;
                            $scope.slashtranslate = slashtranslate;
                            $scope.language = languageWrapper.language;

                            var isValidDates = function() {
                                if (!_this.localStartDate && !_this.localEndDate) {
                                    notifyService.displayRegionErrors(['yld.vorm.veateade.valja_taitmine_kohustuslik'], notifyConstants.UNNAMED_REGION_NAME);
                                    return false;
                                }
                                if (!_this.localStartDate && directiveScope.isWithBothEndsSelectedOnly) {
                                    notifyService.displayFieldErrors({'startDate' : 'yld.vorm.veateade.valja_taitmine_kohustuslik'});
                                    return false;
                                }
                                if (!_this.localEndDate && directiveScope.isWithBothEndsSelectedOnly) {
                                    notifyService.displayFieldErrors({'endDate' : 'yld.vorm.veateade.valja_taitmine_kohustuslik'});
                                    return false;
                                }
                                if (_this.localStartDate && _this.localEndDate && _this.localStartDate > _this.localEndDate) {
                                    notifyService.displayFieldErrors({'endDate' : 'yld.vorm.veateade.kehtivus_lopp_vaiksem_algusest'});
                                    return false;
                                }
                                return true;
                            };

                            this.selectButtonHandler = function () {
                                if (!isValidDates()) {
                                    return;
                                }
                                notifyService.clearNotifications();
                                $scope.$close({
                                    startDate : _this.localStartDate,
                                    endDate : _this.localEndDate
                                });
                            };

                            this.cancelButtonHandler = function() {
                                notifyService.clearNotifications();
                                $scope.$dismiss();
                            };

                        },
                        controllerAs: 'dateRangePopupPickerCtrl'
                    });

                    modalInstance.result.then(function(selectedRange) {
                        scope.model = selectedRange;
                        calculateDisaplayValueOnModel();
                    });
                };

                scope.$watch('model', calculateDisaplayValueOnModel);
            }
        };

    });
}());

'use strict';

angular.module('commons.kaart', []).directive('kaart', [
    function () {
        return {
            restrict: 'E',
            scope: {
                kaardiKood: '=?',
                kaart: '=?',
                kihid: '=?',
                metainfo: '=?',
                center: '=?',
                highlights: '=?'
            },
            controller: ['$scope', '$http', 'olData', 'domeenEnum', '$filter', '$resource', 'config', function($scope, $http, olData, domeenEnum, $filter, $resource, config) {

                proj4.defs("EPSG:3301","+proj=lcc +lat_1=59.33333333333334 +lat_2=58 +lat_0=57.51755393055556 +lon_0=24 +x_0=500000 +y_0=6375000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +axis=neu +no_defs");

                $scope.center = {
                    lat: 6513417.97,
                    lon: 516493.16,
                    zoom: 10,
                    projection: 'EPSG:3301'
                };

                $scope.defaults = {
                    events: {
                        map: ['singleclick']
                    },
                    interactions: {
                        mouseWheelZoom: true
                    },
                    controls: {
                        zoom: {
                            position: 'topright'
                        }
                    },
                    view: {
                        projection: 'EPSG:3301',
                        extent: [282560.67, 6381157.44, 734255.01, 6658861.37]
                    }
                };

                function tostaEsile(layers) {
                  if ($scope.highlights && $scope.highlights.length > 0) {
                    for (var i = 0; i <  $scope.highlights.length; i++) {
                      var vectorLayer = {
                        source: {
                          type: 'GeoJSON',
                          active: true,
                          geojson: {
                            object: $scope.highlights[i],
                            projection: 'EPSG:3301'
                          },
                        },
                        style: {
                          stroke: {
                            color: $scope.highlights[i].properties.color,
                            lineDash: [4],
                            width: 3
                          }
                        },
                        name: $scope.highlights[i].properties.typeName + i,
                        text: $scope.highlights[i].properties.typeName + i,
                        active: true,
                        visible: true,
                        zIndex: 100 + i
                      };
                      layers.push(vectorLayer);
                    }
                  }
                }

                function init(){
                    if($scope.kaardiKood){
                        $resource(config.api + '/v1/public/system/kaart/').get({kood: $scope.kaardiKood}).$promise.then(function (result) {
                            var kaart = result;
                            $scope.kaart = kaart;
                        });
                    }
                }

                init();

                $scope.$watch("kaart", function(){
                    looKaart();
                });

                $scope.$watch("highlights", function(){
                  tostaEsile($scope.layers);
                });

                function looKaart(){
                    if(!$scope.kaart){
                        return;
                    }

                    var layers = [];
                    var kihid = [];

                    var osad = $filter('orderBy')($scope.kaart.osad, "indeks");

                    angular.forEach(osad, function(osa){

                        if (osa.osaTyyp.kood == domeenEnum.KAARDIOSA_TYYP.elemendid.KIHT) {

                            var kiht = osa.kiht;

                            var layer = looLayer(kiht);

                            kihid.push(kiht);
                            layers.push(layer);

                        } else if (osa.osaTyyp.kood == domeenEnum.KAARDIOSA_TYYP.elemendid.KIHT_GRUPP){

                            angular.forEach(osa.grupp.kihid, function(kiht){
                                var layer = looLayer(kiht);

                                kihid.push(kiht);
                                layers.push(layer);
                            });

                        }
                    });

                    $scope.kihid = kihid;
                    $scope.layers = layers;
                }

                function looLayer(kiht) {

                    if (kiht.allikaTyyp.kood == domeenEnum.KAARDIKIHT_ALLIKAS_TYYP.elemendid.WMS
                        || kiht.allikaTyyp.kood == domeenEnum.KAARDIKIHT_ALLIKAS_TYYP.elemendid.WMS_1_1_0
                        || kiht.allikaTyyp.kood == domeenEnum.KAARDIKIHT_ALLIKAS_TYYP.elemendid.WMS_1_1_1
                        || kiht.allikaTyyp.kood == domeenEnum.KAARDIKIHT_ALLIKAS_TYYP.elemendid.WMS_1_3_0) {

                        var version = '1.3.0';

                        if (kiht.allikaTyyp.kood == domeenEnum.KAARDIKIHT_ALLIKAS_TYYP.elemendid.WMS_1_1_0) {
                            version = '1.1.0';
                        } else if (kiht.allikaTyyp.kood == domeenEnum.KAARDIKIHT_ALLIKAS_TYYP.elemendid.WMS_1_1_1) {
                            version = '1.1.1';
                        } else if (kiht.allikaTyyp.kood == domeenEnum.KAARDIKIHT_ALLIKAS_TYYP.elemendid.WMS_1_1_1) {
                            version = '1.3.0';
                        }

                        var layer = {
                            name: kiht.kood,
                            text: kiht.nimetus,
                            active: true,
                            visible: true,
                            source: {
                                type: 'ImageWMS',
                                url: kiht.allikaUrl,
                                params: {VERSION: version}
                            },
                            projection: 'EPSG:3301',
                            minZoom: kiht.minZoom,
                            maxZoom: kiht.maxZoom
                        };

                        kiht.layer = layer;

                        return layer;
                    }

                    return null;
                }

                $scope.$watch("center.zoom", function(zoom) {
                    angular.forEach($scope.layers, function(layer){
                        if(layer.minZoom && zoom < layer.minZoom){
                            layer.active = false;
                        } else if(layer.maxZoom && zoom > layer.maxZoom){
                            layer.active = false;
                        } else {
                            layer.active = true;
                        }
                    });
                });

                $scope.$on('openlayers.map.singleclick', function (event, data) {
                    $scope.$apply(function () {
                        laeMetainfo(data.coord);
                    });
                });

                function laeMetainfo(coord) {

                    olData.getMap().then(function (map) {

                        $scope.metainfo = [];

                        var layers = map.getLayers().getArray();
                        var view = map.getView();

                        for (var i = 0; i < layers.length; i++) {

                            var layer = layers[i];

                            var kood = layer.get('name');
                            var kiht = _.findWhere($scope.kihid, {kood: kood});

                            if(!kiht.metainfo){
                                continue;
                            }

                            var url = layer.getSource().getGetFeatureInfoUrl(coord, view.getResolution(), view.getProjection(),
                                {'INFO_FORMAT': 'application/vnd.ogc.gml', 'FEATURE_COUNT': 50});

                            $http({method: 'GET', url: url, kiht: kiht }).then(function (response) {
                                //response.data.kiht = response.config.kiht;

                                var x2js = new X2JS();
                                var xml = x2js.xml_str2json(response.data);

                                var metainfo = {features: []};

                                if(xml.MultiFeatureCollection && xml.MultiFeatureCollection.FeatureCollection
                                    && xml.MultiFeatureCollection.FeatureCollection.FeatureMembers){

                                    var xmlFeatures = [];
                                    if(angular.isArray(xml.MultiFeatureCollection.FeatureCollection.FeatureMembers.Feature)){
                                        xmlFeatures = xml.MultiFeatureCollection.FeatureCollection.FeatureMembers;
                                    }else{
                                        xmlFeatures.push(xml.MultiFeatureCollection.FeatureCollection.FeatureMembers.Feature);
                                    }

                                    angular.forEach(xmlFeatures, function(xmlFeature){
                                        var f = {properties:{}};
                                        angular.forEach(xmlFeature.Val, function(v){
                                            f.properties[v._ref] = v.__text;
                                        });
                                        metainfo.features.push(f);
                                    });

                                }

                                metainfo.kiht = response.config.kiht

                                $scope.metainfo.push(metainfo);
                            }, function (response) {
                                console.log(response);
                            });
                        }

                    });
                }

            }],
            replace:true,
            templateUrl: 'modules/commons/kaart/kaart.html'
        };
    }
]);

'use strict';

angular.module('commons.customfields', []).directive('pmaNumber', function() {

    /* Number field to allow both ',' and '.' as decimal separator
    *
    * There is a rumour going around that when you use this you're not
    * supposed to use type="number", use type="text" instead.
    *
    * Nobody knows why, if you find out please comment here.
    * */

    var FLOAT_REGEXP = /^\-?\d+((\.|\,)\d+)?$/;

    return {
        require: 'ngModel',
        controller: 'pmaNumberController',
        link: function(scope, elem, attrs, ctrl) {

            elem.bind('keypress', function(event) {
                if (!scope.isKeydownEventAllowed(event, true)) {
                    event.preventDefault();
                }
            });

            ctrl.$parsers.unshift(function(viewValue) {

                if (!viewValue) {
                    ctrl.$setValidity('float', true);
                    return viewValue;
                }

                if (FLOAT_REGEXP.test(viewValue)) {

                    ctrl.$setValidity('float', true);
                    if (typeof viewValue === 'number') {
                        return viewValue;
                    }
                    return parseFloat(viewValue.replace(',', '.'));

                }

                ctrl.$setValidity('float', false);
                return undefined;

            });
        }
    };
}).directive('pmaTextNumber', function() {

    /* Number field to allow both ',' and '.' as decimal separator
    *
    * There is a rumour going around that when you use this you're not
    * supposed to use type="number", use type="text" instead.
    *
    * Nobody knows why, if you find out please comment here.
    * */

    var FLOAT_REGEXP = /^\-?\d+((\.|\,)\d+)?$/;

    return {
        require: 'ngModel',
        controller: 'pmaNumberController',
        link: function(scope, elem, attrs, ctrl) {

            elem.bind('keypress', function(event) {
                if (!scope.isKeydownEventAllowed(event, true)) {
                    event.preventDefault();
                }
            });
        }
    };
}).directive('pmaInteger', function() {
    /*
     * Kontrollib kas sisestatud väärtus on täisarv, vaid ei kontrolli minimaalne ja maksimaalne pikkust. Koopia
     * AngularJS dokumentatsioonist.
     */
    var INTEGER_REGEXP = /^\-?\d+$/;
    return {
        require: 'ngModel',
        controller: 'pmaNumberController',
        link: function(scope, elem, attrs, ctrl) {
            elem.bind('keypress', function(event) {
                if (!scope.isKeydownEventAllowed(event, false)) {
                    event.preventDefault();
                }
            });
            ctrl.$validators.integer = function(modelValue, viewValue) {
                if (ctrl.$isEmpty(modelValue)) {
                    // consider empty models to be valid
                    return true;
                }

                if (INTEGER_REGEXP.test(viewValue)) {
                    return true;
                }
                return false;
            };
        }
    };
})
.controller('pmaNumberController', function($scope) {

    var zeroIndex = 48;
    var nineIndex = 57;
    var commaIndex = 44;
    var periodIndex = 46;
    var backSpaceIndex = 8;

    $scope.isKeydownEventAllowed = function(event, isDecimal) {
        var enableAction = false;

        // kui on ctrl alla vajutatud, luba tegevus
        if (event.ctrlKey) {
            enableAction = true;
        }

        // metaklahvid on lubatud
        if (event.which === 0) {
            enableAction = true;
        }

        // numbrid on lubatud
        else if (event.which >= zeroIndex && event.which <= nineIndex) {
            enableAction = true;
        }

        // komakoha eraldajad on lubatud
        else if (isDecimal && (event.which == commaIndex || event.which == periodIndex)) {
            enableAction = true;
        }

        // navigeerimisnupud on lubatud
        else if (event.which == backSpaceIndex) {
            enableAction = true;
        }

        return enableAction;
    };
});
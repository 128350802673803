'use strict';

angular.module('register.vaetis').controller ('VaetisRegisterHeaderCtrl', function ($scope, $location) {

        $scope.tabs = [
            {title: 'register.vaetised_yld.pealkiri', path: '/vaetis/vaetised', tabId: 'vaetis', visible: true},
            {title: 'register.vaetised_yld.elvaetised.pealkiri', path: '/vaetis/elvaetised', tabId: 'elvaetis', visible: true}
        ];

        if (!$scope.activeTab) {
            $location.path($scope.tabs[0].path);
        }

    }
);

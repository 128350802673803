'use strict';

angular.module('commons.text-input',[]).directive('pmaTextInput', function() {

    return {
        restrict: 'E',
        scope: {
            label: '=?',
            model: '=',
            id: '=?',
            required: '=?',
            isDisabled: '=?',
            inputClass: '=?',
            labelClass: '=?',
            maxlength: '=?'
        },
        templateUrl: function (elem, attrs) {
            if (attrs.hasOwnProperty('simple')) {
                return 'modules/commons/elements/pma-text-input-simple.html';
            }
            return 'modules/commons/elements/pma-text-input.html';
        },
        link: function(scope) {
            scope.$watch('model', function(val) {
                scope.model = val;
            });
        }
    };
});
'use strict';

angular.module('commons.checkbox', []).directive('pmaCheckbox', function() {

    return {
        restrict: 'E',
        replace: true,
        scope: {
            label: '=?',
            model: '=',
            valueLabel: '=',
            id: '=?',
            required: '=?',
            isDisabled: '=?',
            inputClass: '=?',
            labelClass: '=?',
            onClick: '=?',
            showNullAsNo: '=?' // defaults to false
        },
        templateUrl: function (elem, attrs) {
            if (attrs.hasOwnProperty('simple')) {
                return 'modules/commons/elements/pma-number-input-simple.html';
            }
            return 'modules/commons/elements/pma-checkbox.html';
        }
    };
});

'use strict';

angular.module('commons.kaart').directive('kaartMetainfo', [
    function () {
        return {
            restrict: 'E',
            scope: {
                metainfo: '='
            },
            controller: ['$scope', function($scope) {

                $scope.showAndmevali = function (kiht, kood) {
                    var result = _.findWhere(kiht.andmevaljad, {kood: kood});
                    if (result) {
                        return true;
                    }
                    return false;
                };

                $scope.translateAndmevali = function (kiht, kood) {
                    var result = _.findWhere(kiht.andmevaljad, {kood: kood});
                    if (result) {
                        return result.nimetus;
                    }
                    return kood;
                };

            }],
            replace:true,
            templateUrl: 'modules/commons/kaart/kaart-metainfo.html'
        };
    }
]);
'use strict';

angular.module('dokument.minified.directive', []).directive('dokumentMinified',
    function (FileDownloadService) {
        return {
            restrict: 'E',
            scope: {
                objectId: '=',
                kood: '=',
                failid: '=',
                language: '=?'
            },
            templateUrl: 'modules/commons/dokument/minified/dokument-minified.html',
            link: function (scope) {
                if (!scope.language) {
                    scope.language = 'et';
                }

                scope.getFileDownloadUrl = function (kood, dokumentFailId, objectId) {
                    return FileDownloadService.getPublicFileDownloadUrl(kood, objectId, dokumentFailId);
                };
            }
        };
    });
'use strict';
var pmaReg = angular.module('register.taimetervis');
    pmaReg.filter('pmaTegevusalaList', function (pmaTranslateSdeFilter) {
    return function (list, valdkond) {
        var returnable = '', index = [], tegevusalaMap = [], addToTaimedTegevusalaMap = function (dto) {
            if (!_.contains(index, dto.tegevusala.kood)) {
                // Antud tüüpi tegevusala arrays veel ei eksisteeri, lisame uue instansi.
                index.push(dto.tegevusala.kood);
                tegevusalaMap.push({
                    tegevusalaKood: dto.tegevusala.kood,
                    tegevusalaNimi: pmaTranslateSdeFilter(dto.tegevusala),
                    tegevusalaSubjektCsv: pmaTranslateSdeFilter(dto.saadus)
                });
            } else {
                // Antud tüüpi tegevusala juba eksisteerib, täiendame subjekti listi
                for (var i = 0, len = tegevusalaMap.length; i < len; i++) {
                    if (tegevusalaMap[i].tegevusalaKood === dto.tegevusala.kood) {
                        tegevusalaMap[i].tegevusalaSubjektCsv = tegevusalaMap[i].tegevusalaSubjektCsv + ', ' + pmaTranslateSdeFilter(dto.saadus);
                        break;
                    }
                }
            }
        }, addToSeemnedTegevusalaMap = function (dto) {
            var mapEntry = {
                tegevusalaKood: dto.tegevusala.kood,
                tegevusalaNimi: pmaTranslateSdeFilter(dto.tegevusala),
                tegevusalaSubjektCsv: ''
            };
            for (var i = 0, len = dto.seemned.length; i < len; i++) {
                mapEntry.tegevusalaSubjektCsv = mapEntry.tegevusalaSubjektCsv + pmaTranslateSdeFilter(dto.seemned[i]);
                if (i < len - 1) {
                    mapEntry.tegevusalaSubjektCsv = mapEntry.tegevusalaSubjektCsv + ', ';
                }
            }
            tegevusalaMap.push(mapEntry);
        };

        if (!_.isEmpty(list)) {
            for (var i = 0, len = list.length; i < len; i++) {
                if (typeof valdkond !== 'undefined' && valdkond.toLowerCase() === 'taimed') {
                    addToTaimedTegevusalaMap(list[i]);
                } else if (typeof valdkond !== 'undefined' && valdkond.toLowerCase() === 'seemned') {
                    addToSeemnedTegevusalaMap(list[i]);
                }
            }
        }

        _.each(tegevusalaMap, function (tegevusala) {
            returnable = returnable + tegevusala.tegevusalaNimi + ' (' + tegevusala.tegevusalaSubjektCsv + '); ';
        });

        return returnable;
    };
});

pmaReg.filter('pmaSaadusNimetusList', function (pmaTranslateSdeFilter) {
    return function (list) {
        var returnable = '';
        if (!_.isEmpty(list)) {
            _.each(list, function (element) {
                returnable = returnable + pmaTranslateSdeFilter(element.saadus) + ' (' + pmaTranslateSdeFilter(element.tegevusala) + '); ';
            });
        }
        return returnable;
    };
});
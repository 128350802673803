'use strict';

angular.module('register.maaparandus.teenus').controller('KasutusotstarveLopetamineTaotlusDetailvaadeCtrl',
    function($scope, $routeParams, TeenusDetail, MaaparandusTeenusService) {

        $scope.teenusId = parseInt($routeParams.teenusId);
        $scope.independent = true;

        function loadTeenusData(teenusId) {
            TeenusDetail.getDetailsForTeenus(teenusId).then(function(teenusData) {
                $scope.teenus = teenusData;
                $scope.teenusSeadeId = teenusData.teenusSeadistusId;
                $scope.kliendiNr = teenusData.kliendiNr;
                $scope.teenusId = teenusData.teenusId;
            });
        }

        function loadData() {

            loadTeenusData($scope.teenusId);

            MaaparandusTeenusService.getKasutusotstarveTaotlus($scope.teenusId).$promise.then(function(kasutusotstarve) {
                $scope.kasutusotstarve = kasutusotstarve;
            });
        }

        loadData();

    }
);

'use strict';

angular.module('register.mater').factory('MaterService', ['$resource', 'config', '$q', function($resource, config, $q) {

  var materService = {};

  materService.ettevoteDto = $resource(config.api + '/v1/public/register/maaparandus/ettevote/:ettevoteId', {});

  materService.getEttevoteByEttevoteId = function(ettevoteId) {
    return materService.ettevoteDto.get({
      'ettevoteId': ettevoteId
    }, function(result) {

    });
  };

  materService.spetsialistDto = $resource(config.api + '/v1/public/register/maaparandus/ettevote/:ettevoteId/:spetsialistId', {});

  materService.getSpetsialistByEttevoteIdAndSpetsialistId = function(ettevoteId, spetsialistId) {
    return materService.spetsialistDto.get({
      'ettevoteId': ettevoteId,
      'spetsialistId': spetsialistId
    }, function(result) {

    });
  };

  materService.leiaEttevoteByRegistrikood = function(registrikood) {
    var delay = $q.defer();
    $resource(config.api + '/v1/public/register/maaparandus/ettevote/otsing', {
      'filter': 'registrikood::' + registrikood,
      'limit': 10,
      'offset': 0
    }, {
      'get': {
        method: 'GET',
        isArray: false
      }
    }).get(function(result) {
      delay.resolve(result);
    });
    return delay.promise;
  };

  materService.getEttekirjutused = function(ettevoteId) {
    var delay = $q.defer();
    $resource(config.api + '/v1/public/register/ettekirjutused', {
      'ettevoteId': ettevoteId,
      'limit': 100,
      'offset': 0
    }, {
      'get': {
        method: 'GET',
        isArray: false
      }
    }).get(function(result) {
      delay.resolve(result);
    });
    return delay.promise;
  };

  return materService;
}]);
'use strict';

angular.module('register.vaetis').controller('RegisterVaetisCtrl', function ($scope, $routeParams, $filter, $q,
                                                                             ngTableHelperFactory, StateReminderService, notifyService, domeenService, domeenEnum, RegisterVaetisService) {
    $scope.domeenEnum = domeenEnum;

    $scope.activeTab = 'vaetis';

    $scope.naitaVaetiseKaitleja = domeenService.klassifikaatorElementAtribuutidegaQuery(domeenEnum.PMA_VAETIS_OTSING_VAETISE_KAITLEJA.elemendid.VAETISE_KAITLEJA);
    $scope.naitaVaetiseLiiginimetus = domeenService.klassifikaatorElementAtribuutidegaQuery(domeenEnum.PMA_VAETIS_OTSING_VAETISE_LIIGINIMETUS.elemendid.VAETISE_LIIGINIMETUS);
    $scope.naitaVaetiseKoostis = domeenService.klassifikaatorElementAtribuutidegaQuery(domeenEnum.PMA_VAETIS_OTSING_VAETISE_KOOSTIS.elemendid.VAETISE_KOOSTIS);
    $scope.naitaParitoluRiik = domeenService.klassifikaatorElementAtribuutidegaQuery(domeenEnum.PMA_VAETIS_OTSING_PARITOLU_RIIK.elemendid.PARITOLU_RIIK);
    $scope.naitaKoostisosaSisaldus = domeenService.klassifikaatorElementAtribuutidegaQuery(domeenEnum.PMA_VAETIS_OTSING_KOOSTISOSA_SISALDUS.elemendid.KOOSTISOSA_SISALDUS);

    $scope.datePickerOptions = {};
    $scope.filter = {
        registreerimineKp: {startDate: null, endDate: null},
        viimatiMuudetudKp: {startDate: null, endDate: null},
        paritoluRiikKood: [],
        tegevusalad: [],
        liikNimi: [],
        mtLiik: [domeenEnum.PMA_VAETIS_MAJANDUSTEGEVUSTEADE.elemendid.VAETISEREGISTRISSE_KANDMINE]
    };

    $scope.commafyList = function (list) {
        var returnable = '';
        if (!_.isEmpty(list)) {
            for (var i = 0, len = list.length; i < len; i++) {
                returnable = returnable + $filter('pmaTranslateSde')(list[i]);
                if (i < len - 1) {
                    returnable = returnable + ', ';
                }
            }
        }
        return returnable;
    };
    domeenService.klassifikaatorQuery(domeenEnum.PMA_VAETIS_KN_KOOD.nimi).$promise.then(function (data) {
        $scope.vaetisKnKoodKL = data.entities;
    });
    RegisterVaetisService.getCustomerList(domeenEnum.PMA_REGISTER.elemendid.PMA_REGISTER_VAETIS).$promise.then(function (data) {
        $scope.kliendid = data.entities;
    });
    RegisterVaetisService.getKaubandusLiigid().$promise.then(function (data) {
        $scope.kaubandusLiigid = data.entities;
    });
    RegisterVaetisService.getRiikKlfid().$promise.then(function (data) {
        $scope.riikKL = data.entities;
    });
    domeenService.klassifikaatorQuery(domeenEnum.REGISTER_STAATUS.nimi).$promise.then(function (data) {
        $scope.registriStaatused = data.entities;
    });
    domeenService.klassifikaatorQuery(domeenEnum.PMA_VAETIS_SEADUS_TEGEVUSALA.nimi).$promise.then(function (data) {
        $scope.vaetiseseadusTegevusalaKL = data.entities;
    });
    domeenService.klassifikaatorQuery(domeenEnum.PMA_VAETIS_KOOSTIS.nimi).$promise.then(function (data) {
        $scope.vaetisKoostisKL = data.entities;
    });

    $scope.tableParams = ngTableHelperFactory.newNgTableParamsAutoLoad('/v1/public/register/vaetised',
        $scope.filter, {
            registreerimineKp: {
                startDate: moment(),
                endDate: moment()
            }
        });

    $scope.search = function () {
        $scope.tableParams.search();
    };

    $scope.containsSubString = function (actual, expected) {
        var lowerStr = (actual + "").toLowerCase();
        return lowerStr.indexOf(expected.toLowerCase()) !== -1;
    };


    $scope.preserveState = function () {
        StateReminderService.preserveState($scope);
    };

    if (StateReminderService.hasPreviousState()) {
        var state = StateReminderService.getPreviousState();
        $scope.stateGiven = true;
        $scope.tableParams = state.scope.tableParams;
        $scope.filter = state.scope.filter;
        $scope.tableParams.reload();
    }

    $scope.reset = function () {
        $scope.filter = {
            registreerimineKp: {startDate: null, endDate: null},
            viimatiMuudetudKp: {startDate: null, endDate: null},
            paritoluRiikKood: [],
            tegevusalad: [],
            liikNimi: [],
            mtLiik: [domeenEnum.PMA_VAETIS_MAJANDUSTEGEVUSTEADE.elemendid.VAETISEREGISTRISSE_KANDMINE]
        };

        $scope.tableParams.reset($scope.filter);
    };


    $scope.checkForPopover = function (atribuudid) {
        if (getPopOverAtribuut(atribuudid)) {
            return true;
        }
    };

    $scope.getPopover = function (atribuudid) {
        return getPopOverAtribuut(atribuudid);
    };

    $scope.getKlf = function (atribuudid) {
        if (atribuudid.entities) {
            return atribuudid.entities[0].element;
        }
    };

    var getPopOverAtribuut = function (atribuudid) {
        var vaartus = _.find(atribuudid.entities, function (atribuut) {
            return $scope.domeenEnum.PMA_SYS_DOMEEN_ATRIBUUT_TYYP.elemendid.ABIINFO_ET === atribuut.atribuutTyyp.kood;
        });
        if (vaartus) {
            return vaartus.atribuutVaartus;
        }
    };


});
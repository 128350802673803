'use strict';

angular.module('teenus.common', []).directive('pmaTeenusKlientView', function() {
    return {
        restrict: 'E',
        scope: {
            register: '=',
            showTtRegister: '=',
            klient: '=' // XmlKlientType
        },
        templateUrl: 'modules/commons/teenus/teenus-klient-view.html'
    };
/*}).directive('pmaTeenusEsitajaView', function() {
    return {
        restrict: 'E',
        scope: {
            esitaja: '=',
            headline: '='
        },
        templateUrl: 'modules/commons/pma-teenus-esitaja-view.html'
    };*/
}).directive('teenusKontaktaadressidView', function() {
    return {
        restrict: 'E',
        scope: {
            aadress: '=' // KbAadressDto
        },
        templateUrl: 'modules/commons/teenus/teenus-kontaktaadressid.html'
    };
/*}).directive('pmaTeenusKontaktidView', function() {
    return {
        restrict: 'E',
        scope: {
            kontakt: '=' // KbValdkondKontaktDto
        },
        templateUrl: 'modules/commons/pma-teenus-kontaktid-view.html'
    };*/
}).directive('teenusTegevuskohadView', function(generalUtil) {
    return {
        restrict: 'E',
        scope: {
            tegevuskohad: '=' // List<XmlTegevuskohtType>
        },
        templateUrl: 'modules/commons/teenus/teenus-tegevuskohad-view.html',
        link: function(scope) {
            scope.addToArrayIfNotEmpty = generalUtil.addToArrayIfNotEmpty;
        }
    };
}).directive('teenusYldandmedView', function(domeenEnum) {
    var link = function(scope) {
        scope.isLihtteenus = false;
        scope.$watch(function() {
            return scope.teenus;
        }, function(newValue, oldValue) {
            if (newValue !== oldValue) {
                scope.teenusId = scope.teenus.teenusId;
                if (scope.teenus.teenusSeadistusMenetlusTyyp && scope.teenus.teenusSeadistusMenetlusTyyp.kood === domeenEnum.MENETLUS_TYYP.elemendid.LIHT) {
                    scope.isLihtteenus = true;
                }

            }
        });
    };
    return {
        restrict: 'E',
        scope: {
            isVormDetailView: '=',
            teenus: '=' // TeenusKinnitamineStep4LoadDto
        },
        link: link,
        templateUrl: 'modules/commons/teenus/teenus-yldandmed-view.html'
    };
/*}).directive('pmaMaakond', function(domeenEnum, domeenService) {
    var link = function(scope) {
        domeenService.klassifikaatorQuery(domeenEnum.ADS_TASE_1.nimi).$promise.then(function(data) {
            scope.maakonnad = data.entities;
        });

        var findMaakondSdeByKood = function(kood) {
            var returnable = {
                value: null
            };
            if (typeof scope.maakonnad === 'undefined') {
                domeenService.klassifikaatorQuery(domeenEnum.ADS_TASE_1.nimi).$promise.then(function(data) {
                    scope.maakonnad = data.entities;
                    doSearch(kood, returnable);
                });
            } else {
                doSearch(kood, returnable);
            }
            return returnable.value;
        };

        function doSearch(kood, returnable) {
            _.each(scope.maakonnad, function(maakond) {
                if (maakond.kood === kood) {
                    returnable.value = maakond;
                }
            });
        }

        scope.onMaakondKoodSelect = function(kood) {
            if (scope.onSelect) {
                scope.onSelect(kood);
            }
        };

        scope.$watch(function() {
            return scope.teenus;
        }, function(newValue, oldValue) {
            if (newValue !== oldValue && typeof newValue !== 'undefined') {
                if (typeof scope.teenus.maakond !== 'undefined' && scope.teenus.maakond === null && !scope.doNotInit) {
                    scope.teenus.maakond = findMaakondSdeByKood(domeenEnum.ADS_TASE_1.elemendid.HARJU);
                }

            }
        });

    };
    return {
        restrict: 'E',
        scope: {
            readOnly: '=',
            teenus: '=',
            doNotInit: '=?',
            onSelect: '=?'
        },
        link: link,
        templateUrl: 'modules/commons/pma-teenus-maakond.html'
    };*/
/*}).directive('pmaMaakonnaKeskus', ['domeenEnum', 'domeenService', function(domeenEnum, domeenService) {
    var link = function(scope) {
        domeenService.klassifikaatorQuery(domeenEnum.PMA_MAAKONNAKESKUS.nimi).$promise.then(function(data) {
            scope.maakonnakeskused = data.entities;
            // if (typeof scope.teenus.keskus !== 'undefined' && scope.teenus.keskus === null) {
            // scope.teenus.keskus = findKeskusSdeByKood(domeenEnum.PMA_MAAKONNAKESKUS.elemendid.HARJU);
            // }
        });

        /!*
         * var findKeskusSdeByKood = function (kood) { var returnable = {value: null}; doSearch(kood, returnable);
         * return returnable.value; };
         * 
         * function doSearch(kood, returnable) { _.each(scope.maakonnakeskused, function (keskus) { if (keskus.kood ===
         * kood) { returnable.value = keskus; } }); }
         * 
         * scope.$watch(function () { return scope.teenus; }, function (newValue) { if (typeof newValue !== 'undefined') {
         * if (typeof scope.teenus.keskus !== 'undefined' && scope.teenus.keskus === null) { scope.teenus.keskus =
         * findKeskusSdeByKood(domeenEnum.PMA_MAAKONNAKESKUS.elemendid.HARJU); } } });
         *!/

        // KASPAR: analüütikud arvasid et seda ei pruugi enam vaja minna, vaatame
    };
    return {
        restrict: 'E',
        scope: {
            readOnly: '=',
            teenus: '='

        },
        link: link,
        templateUrl: 'modules/commons/pma-teenus-maakonnakeskus.html'
    };*/
}).directive('teenusRiigiloiv', function() {
    return {
        restrict: 'E',
        scope: {
            show: '&',
            model: '=',
            view: '&', // if true then will be displayed in 'view' mode; false by default
            change: '&',
            disabled: '='
        },
        templateUrl: 'modules/commons/teenus/teenus-riigiloiv.html'
    };
/*}).directive('pmaTeenusSeostatudProtokollid', function(SeostatavadProtokollidDto, notifyService) {
    return {
        restrict: 'E',
        scope: {
            seotudProtokollid: '=',
            disabled: '=',
            teenusId: '=',
            reload: '='
        },
        link: function(scope) {
            scope.kustutaProtokollSeos = function(seosId) {
                notifyService.retainNotifications();
                SeostatavadProtokollidDto.deleteSeos({
                    seosId: seosId
                }).$promise.then(scope.reload);
            };
        },
        templateUrl: 'modules/commons/pma-teenus-seostatud-protokollid.html'
    };*/
/*
}).service('teenusCommonService', function($resource, config, domeenEnum) {

    var modifiableStaatused = [domeenEnum.TEENUS_STAATUS.elemendid.SISESTAMISEL,
        domeenEnum.TEENUS_STAATUS.elemendid.SISESTAMISEL_PUUDUS, domeenEnum.TEENUS_STAATUS.elemendid.UUS];
    this.isModifiable = function(teenusStaatusKood) {
        return modifiableStaatused.indexOf(teenusStaatusKood) >= 0;
    };

    var nonConfirmableStaatused = [domeenEnum.TEENUS_STAATUS.elemendid.SISESTAMINE_LOPETATUD,
        domeenEnum.TEENUS_STAATUS.elemendid.LOPETATUD, domeenEnum.TEENUS_STAATUS.elemendid.TAGASI_LYKATUD];
    this.isConfirmable = function(teenusStaatusKood) {
        return nonConfirmableStaatused.indexOf(teenusStaatusKood) == -1;
    };

    this.getTeenusSammDto = function() {
        return $resource(config.api + '/v1/public/teenused/:id/sammud/:sammNr', {}, {
            update: {
                method: 'PUT',
                params: {
                    id: '@id',
                    sammNr: '@sammNr'
                }
            }
        });
    };

    this.getTeenus = function(id) {
        return $resource(config.api + '/v1/public/teenused/:id/vaatamine', {
            id: id
        }).get().$promise;
    };
*/

/*}).service('TeenusSamm3Dto', function($resource, config) {
    return $resource(config.api + '/v1/public/teenused/:id', {}, {
        get: {
            method: 'GET',
            params: {
                keelKood: '@keelKood'
            }
        },
        save: {
            method: 'POST'
        }
    });
}).service('TeenusStatus', function($resource, config) {
    return $resource(config.api + '/v1/public/teenused/:id/status/:status', {}, {
        update: {
            method: 'PUT',
            params: {
                id: '@id',
                status: '@status'
            }
        }
    });
}).service('TeenusProtokollid', function($resource, config) {
    return $resource(config.api + '/v1/public/teenused/:id/protokollid', {}, {
        get: {
            method: 'GET',
            params: {
                id: '@id'
            },
            isArray: true
        }
    });*/
});

'use strict';

angular.module('commons.textarea', []).directive('pmaTextarea', function() {
    return {
        restrict: 'E',
        replace: true,
        scope: {
            label: '=',
            model: '=',
            id: '=?',
            required: '=?',
            margin:'=?',
            isDisabled: '=?',
            inputClass: '=?',
            labelClass: '=?',
            rows:'=?'
        },
        templateUrl: 'modules/commons/elements/pma-textarea.html'
    };
}).directive('pmaResizeableTextarea', function() {
    return function(scope, element, attr) {
        var optimalHeight = '32';

        attr.$set('style', 'overflow: hidden; word-wrap: break-word; resize:vertical; height: 32px;');
        attr.$set('row', '5');
        var update = function() {
            if (element[0].scrollHeight < 32) {
                element.css('height', optimalHeight + 'px');
            } else {
                element.css('height', element[0].scrollHeight + 'px');
            }
        };
        scope.$watch(function () {
            return element[0].scrollHeight;
        }, function() {
            update();
        });
        scope.$watch(attr.ngModel, function(newVal, oldVal) {
            if (!oldVal && newVal) {
                update();
            }
        });

        element.bind('focus propertychange paste input keyup keydown backspace change blur', update);
        update();
    }
});

angular.module('commons.general', []).service('generalUtil', function() {

    this.undefineObjectProperties = function(object) {
        for(var field in object){
            object[field] = undefined;
        }
        return object;
    };

    this.addToArrayIfNotEmpty = function(args) {
        if (!args) {
            return;
        }
        var resultArray = [];
        for ( var i in args) {
            if (args[i]) {
                resultArray.push(args[i]);
            }
        }
        return resultArray;
    };

});

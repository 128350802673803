'use strict';

angular.module('commons.date').directive('pmaDateInput', function(dateService) {

    return {
        restrict: 'E',
        replace: true,
        scope: {
            label: '=',
            model: '=',
            id: '=?',
            required: '=?',
            isDisabled: '=?',
            slashtranslate: '=?',
            inputClass: '=?',
            labelClass: '=?',
            minDate: "=?" ,
            maxDate: "=?",
            language: "=?"
        },
        templateUrl: function (elem, attrs) {
            if (attrs.hasOwnProperty('simple')) {
                return 'modules/commons/elements/pma-date-input-simple.html';
            }
            return 'modules/commons/elements/pma-date-input.html';
        },
        link: {
            pre: function($scope, $element, $attributes) {
                $scope.conf = dateService.newDatePicker($scope.options);
                $scope.clearSelection = function () {
                    $scope.model = null;
                };
            }
        }
    };
})

.directive('pmaDateField', function(dateService) {
    return {
        restict: 'E',
        scope: {
            id: '@',
            name: '@',
            modelObject: '=',
            required: '@',
            isDisabled: '=?',
            options: '=',
            minDate: "=?" ,
            maxDate: "=?"
        },
        templateUrl: 'modules/commons/elements/pma-date-field.html',
        link: {
            pre: function($scope, $element, $attributes) {
                $scope.conf = dateService.newDatePicker($scope.options);
                $scope.clearSelection = function () {
                    $scope.modelObject = null;
                };
            }
        }
    };
});

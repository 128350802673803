'use strict';

angular.module('register.maaparandus').controller('MaaparandusRegisterArhiivOtsingCtrl',
    function ($scope, notifyService, regioService, ngTableHelperFactory, StateReminderService, domeenService, domeenEnum, TeenusMuutmine,
              TeenusSeadistusOtsing, $location, $q) {
        $scope.activeTab = 'toimingud';
        $scope.teenusOtsing = {
            sisestatud: {
                startDate: null, //moment().startOf('month'),
                endDate: null // moment()
            },
            staatusKood: '',
            teenusNr: '',
            klientNimi: '',
            dokumentNimi: '',
            registreerimisKp: {startDate: null, endDate: null},
            loomisKp: {startDate: null, endDate: null}
        };
        $scope.otsingutunnus = 'asukoht';

        $scope.searched = false;

        $scope.tableParams = ngTableHelperFactory.newNgTableParams('/v1/public/register/maaparandus/otsing/teenus', $scope.teenusOtsing, {
            'loomisKp': 'desc'
        });

        TeenusSeadistusOtsing.leiaTeenusSeadistused(domeenEnum.VALDKOND.elemendid.MAAPARANDUS).then(function (result) {
            $scope.teenused = [];

            angular.forEach(result.entities, function (item) {
                if (item.nimi !== 'Majandustegevusteate andmete muutmine' && item.nimi !== 'Majandustegevusteate lisamine' && item.menetlusTyyp.kood !== 'MENETLUS_TYYP_LIHT') {
                    $scope.teenused.push(item);
                }
            });

        });

        $scope.changeTeenusTuupKood = function (teenus){
            $scope.teenusOtsing.teenusSeadistusKood = teenus.kood;
            if(teenus.kood === 'PMA_TE_MP_KOOSKOLASTUS_TAOTLUS' || teenus.kood === 'PMA_TE_MP_KOOSKOLASTUS' || teenus.kood === 'PMA_TE_MP_KOOSKOLASTUS_KEELD'){
                $scope.showKooskolastusLiik = true;

                domeenService.klassifikaatorQuery(domeenEnum.PMA_KOOSKOLASTUS_LIIK.nimi).$promise.then(function(kooskolastusLiikResponse) {
                    $scope.kooskolastusLiikKl = kooskolastusLiikResponse;
                });

            } else {
                $scope.showKooskolastusLiik = false;
                $scope.teenusOtsing.kooskolastusLiik = null;
            }
        };

        if (StateReminderService.hasPreviousState()) {
            var state = StateReminderService.getPreviousState();
            $scope.tableParams = state.scope.tableParams;
            $scope.teenusOtsing = state.scope.teenusOtsing;
            $scope.tableParams.reload();
        }
        $scope.preserveState = function () {
            StateReminderService.preserveState($scope);
        };

        $scope.otsi = function () {
            notifyService.clearNotifications();
            $scope.searched = true;
            $scope.tableParams.search();
        };

        $scope.resetSearch = function () {
            $scope.teenusOtsing = {};
            $scope.aadress = {};
            $scope.searched = false;
            $scope.tableParams.reset($scope.teenusOtsing);
        };

        $scope.otsingutunnusChanged = function () {
            $scope.teenusOtsing.teenusNr = null;
            $scope.teenusOtsing.aadress = null;

            $scope.teenusOtsing.asukohtTase1 = null;
            $scope.teenusOtsing.asukohtTase2 = null;
            $scope.teenusOtsing.asukohtTase3 = null;

            $scope.teenusOtsing.systeemikood = null;
            $scope.teenusOtsing.katastritunnus = null;
        };

        $scope.getAddress = function (searchValue) {
            $scope.teenusOtsing.aadress = searchValue;
            $scope.aadress = {};
            return regioService.getAddresses(searchValue);
        };

        $scope.aadressSelect = function (regioAadress) {
            regioService.convertToAadress(regioAadress, $scope.aadress);
            $scope.teenusOtsing.aadress = $scope.aadress.aadress;
            $scope.teenusOtsing.asukohtTase1 = $scope.aadress.detailid.tase1Ehak;
            $scope.teenusOtsing.asukohtTase2 = $scope.aadress.detailid.tase2Ehak;
            $scope.teenusOtsing.asukohtTase3 = $scope.aadress.detailid.tase3Ehak;
        };

        $scope.avaTeenus = function (teenusId, teenusTyyp) {
            StateReminderService.preserveState($scope);
            if (teenusTyyp == 'TEENUS') {
                TeenusMuutmine.doRedirect(teenusId, false);
            } else if (teenusTyyp == 'ARHIIV_TAOTLUS') {
                $location.url('/maaparandus/arhiiv/taotlus/' + teenusId);
            } else if (teenusTyyp == 'ARHIIV_TEATIS') {
                $location.url('/maaparandus/arhiiv/teatis/' + teenusId);
            }
        };

        $scope.showFilter = function () {
            return $scope.searched;
        }

    }
);

'use strict';
var pmaReg = angular.module('register.maaparandus');

pmaReg.controller('RegisterMaaparandusMsrOrMaterHeader',
    function ($scope, $location, $window) {

        $scope.smallMenu1 = [
            {title: 'register.menyy.maaparandus.detailne', path: '/maaparandus/msr', menuId: 'msrId', visible: true},
            {title: 'register.menyy.maaparandus.mater.detailne', path: '/maaparandus/mater', menuId: 'materId',  visible: true}
        ];
        function RedirectToDefaultMsrPage() {
            if ($location.path() == '/maaparandus') {
                $window.location.href = '#/maaparandus/msr/systeemi-otsing';
            }
        }

        RedirectToDefaultMsrPage();
    });
'use strict';


angular.module('register.maaparandus.teenus', []).factory('MaaparandusTeenusService', ['$resource', '$q', 'config', function ($resource, $q, config) {

        var maaparandusTeenusService = {};

        maaparandusTeenusService.getEhitamiseAlgusTeatis = function (teenusId) {
            return $resource(config.api + '/v1/public/teenused/:teenusId/maaparandus/teMpEhitamiseAlgusTeatis', {}).get({
                'teenusId': teenusId
            }, function (result) {

            });
        };

        maaparandusTeenusService.getEhitusluba = function (teenusId) {
            return $resource(config.api + '/v1/public/teenused/:teenusId/maaparandus/teMpEhitusluba', {}).get({
                'teenusId': teenusId
            }, function (result) {

            });
        };

        maaparandusTeenusService.getEhituslubaKeeld = function (teenusId) {
            return $resource(config.api + '/v1/public/teenused/:teenusId/maaparandus/ehituslubaKeeld', {}).get({
                'teenusId': teenusId
            }, function (result) {

            });
        };

        maaparandusTeenusService.getEhituslubaKehtetusOtsus = function (teenusId) {
            return $resource(config.api + '/v1/public/teenused/:teenusId/maaparandus/teMpEhituslubaKehtetusOtsus', {}).get({
                'teenusId': teenusId
            }, function (result) {

            });
        };

        maaparandusTeenusService.getEhituslubaKehtetusTaotlus = function (teenusId) {
            return $resource(config.api + '/v1/public/teenused/:teenusId/maaparandus/teMpEhituslubaKehtetusTaotlus', {}).get({
                'teenusId': teenusId
            }, function (result) {

            });
        };

        maaparandusTeenusService.getEhituslubaTaotlus = function (teenusId) {
            return $resource(config.api + '/v1/public/teenused/:teenusId/maaparandus/teMpEhituslubaTaotlus', {}).get({
                'teenusId': teenusId
            }, function (result) {

            });
        };

        maaparandusTeenusService.getEhitusprojektiKooskolastus = function (teenusId) {
            return $resource(config.api + '/v1/public/teenused/:teenusId/maaparandus/teMpEhitusprojektiKooskolastus', {}).get({
                'teenusId': teenusId
            }, function (result) {

            });
        };

        maaparandusTeenusService.getKasutusluba = function (teenusId) {
            return $resource(config.api + '/v1/public/teenused/:teenusId/maaparandus/teMpKasutusluba', {}).get({
                'teenusId': teenusId
            }, function (result) {

            });
        };

        maaparandusTeenusService.getKasutuslubaKeeld = function (teenusId) {
            return $resource(config.api + '/v1/public/teenused/:teenusId/maaparandus/kasutuslubaKeeld', {}).get({
                'teenusId': teenusId
            }, function (result) {

            });
        };

        maaparandusTeenusService.getKasutuslubaTaotlus = function (teenusId) {
            return $resource(config.api + '/v1/public/teenused/:teenusId/maaparandus/teMpKasutuslubaTaotlus', {}).get({
                'teenusId': teenusId
            }, function (result) {

            });
        };

        maaparandusTeenusService.getKasutusotstarveTaotlus = function (teenusId) {
            return $resource(config.api + '/v1/public/teenused/:teenusId/maaparandus/teMpKasutusotstarveLopetamineTaotlus', {}).get({
                'teenusId': teenusId
            }, function (result) {

            });
        };


        maaparandusTeenusService.getKooskolastusTaotlus = function (teenusId) {
            return $resource(config.api + '/v1/public/teenused/:teenusId/maaparandus/teMpKooskolastusTaotlus', {}).get({
                'teenusId': teenusId
            }, function (result) {

            });
        };

        maaparandusTeenusService.getKooskolastus = function (teenusId) {
            return $resource(config.api + '/v1/public/teenused/:teenusId/maaparandus/teMpKooskolastus', {}).get({
                'teenusId': teenusId
            }, function (result) {

            });
        };

        maaparandusTeenusService.getKooskolastusKeeld = function (teenusId) {
            return $resource(config.api + '/v1/public/teenused/:teenusId/maaparandus/teMpKooskolastusKeeld', {}).get({
                'teenusId': teenusId
            }, function (result) {

            });
        };

        maaparandusTeenusService.getProjTingimused = function (teenusId) {
            return $resource(config.api + '/v1/public/teenused/:teenusId/maaparandus/teMpProjTingimused', {}).get({
                'teenusId': teenusId
            }, function (result) {

            });
        };

        maaparandusTeenusService.getProjTingimusedKeeldumiseTeatis = function (teenusId) {
            return $resource(config.api + '/v1/public/teenused/:teenusId/maaparandus/teMpProjTingimusedKeeldumiseTeatis', {}).get({
                'teenusId': teenusId
            }, function (result) {

            });
        };

        maaparandusTeenusService.getProjTingimusedTaotlus = function(teenusId) {
            return $resource(config.api + '/v1/public/teenused/:teenusId/maaparandus/teMpProjTingimusedTaotlus', {}).get({
                'teenusId': teenusId
            }, function(result) {

            });
        };


        return maaparandusTeenusService;
    }

]);

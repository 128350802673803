'use strict';

angular.module('teenus.teenusdetailvaadedto', []).factory('TeenusDetailDto', ['$resource', 'config', function ($resource, config) {
    return $resource(config.api + '/v1/public/teenused/:teenusId/detailvaade', {}, {
        query: {
            method: 'GET',
            isArray: false
        },
        change: {
            method: 'PUT',
            params: {teenusId: '@teenusId'}
        }
    });
}]);

angular.module('teenus.detailvaade.service', []).factory('TeenusDetail', ['$q', '$resource', '$http', 'TeenusDetailDto',
    function ($q, $resource, $http, TeenusDetailDto) {

        var getDetailsForTeenus = function (teenusId) {
            var delay = $q.defer();
            TeenusDetailDto.query({teenusId: teenusId}, function (data) {
/*                    data.esitaja = TeenusKinnitamine.getEsitaja(data.klient, data.esitajaKliendiNr);
                    data.tegevuskohad = TeenusKinnitamine.getTegevuskohad(data.klient, data.tegevuskohtGlobalIdList);*/
                    delay.resolve(data);
                }, function () {
                }
            );
            return delay.promise;
        };

        return {
            getDetailsForTeenus: function (teenusId) {
                return getDetailsForTeenus(teenusId);
            }
        };
    }
]);

/*

/!**
 * Kui käivitatakse iseseisva komponendina, siis peab olema spetsifitseeritud $routeParams.teenusId.
 * Kui käivitatakse teise komponendi seest, siis peab olema spetsifitseeritud väljakutsuva komponendi $scope.initParams.teenusId, mida antud komponent pärib.
 * $scope.initParams.independent näitab kas kuvat peab näitama 'iseseisvana' ehk täielikus mahus või 'sisse ehitatuna' ehk lühendatud (nt. ilma pealkirja ja nupudeta).
 *!/
pmaTeenus.controller('TeenusDetailvaadeCtrl',
    function ($scope, $routeParams, $log, TeenusDetail, FileDownloadService, domeenEnum, TeenusKinnitamine, notifyService, ProtokollLoomineService, SeostatavadProtokollidDto,
              ProtokollSeostamineService, ProtokollSeosTyyp, teenusSuunamineService, PmaTeenusDetailvaadeMuudaBtnService, TeenusMuutmine, TeenusSecurityService, initTeenusSuunaCallback) {
        if ($scope.initParams) {
            $log.info('Initsializeerin lihtteenuse detailvaade initParams abil', $scope.initParams);
            $scope.teenusId = parseInt($scope.initParams.teenusId);
            $scope.independent = $scope.initParams.independent;
        } else {
            $log.info('Initsializeerin lihtteenuse detailvaade routeParams abil', $routeParams);
            $scope.teenusId = parseInt($routeParams.teenusId);
            $scope.independent = true;
        }

        $scope.initTeenusSuunaCallback = initTeenusSuunaCallback;

        $scope.loadTeenusData = function () {
            notifyService.retainNotifications();
            TeenusDetail.getDetailsForTeenus($scope.teenusId).then(
                function (teenusData) {
                    $scope.teenus = teenusData;
                    $scope.isTegevuskohad = TeenusKinnitamine.getIsSectionPresent(teenusData.tegevuskohad);
                    $scope.teenusSeadeId = teenusData.teenusSeadistusId;
                    $scope.kliendiNr = teenusData.kliendiNr;
                    $scope.staatusKontrollimisel = teenusData.teenusStaatus.kood === domeenEnum.TEENUS_STAATUS.elemendid.KONTROLLIMISEL;
                    $scope.isAssignable = teenusSuunamineService.isAssignable({staatusKood: teenusData.teenusStaatus.kood});

                    $scope.disabled = !TeenusSecurityService.isAuthorizedEdit(teenusData.menetlejaId, teenusData.teenusStaatus.kood, teenusData.teenusSeadistusKood);

                    // buttons
                    if (!$scope.disabled && $scope.teenus.operations.showLooProtokoll) {
                        ProtokollLoomineService.getTeenusSeadistusLoodavadProtokollid(teenusData.teenusSeadistusId).then(function (collectionDto) {
                            if (!_.isEmpty(collectionDto.entities)) {
                                $scope.looProtokoll = ProtokollLoomineService.createProtokollAlusegaLoomineFunction(ProtokollSeosTyyp.TEENUS, $scope.teenusId, collectionDto.entities);
                                setButtons();
                            }
                        });
                    }
                    if ($scope.teenus.operations.showSeostaProtokoll) {
                        $scope.seostaProtokoll = ProtokollSeostamineService.createProtokollTeenusSeos(ProtokollSeosTyyp.TEENUS, $scope.teenusId, $scope.loadTeenusData);
                    }
                    $scope.showStartChangeBtn = TeenusMuutmine.showStartChangeBtn($scope.teenus);
                    if ($scope.showStartChangeBtn) {
                        $scope.startChange = function () {
                            PmaTeenusDetailvaadeMuudaBtnService.getStartChangeConfirmationModal().then(function () {
                                TeenusMuutmine.replicate($scope.teenus.teenusId, $scope.teenus.teenusStaatus.kood);
                            });
                        };
                    }
                    ;
                    setButtons();
                });
        }

        $scope.lopetaMenetlus = function () {
            TeenusKinnitamine.getMsgObjForModal('lopetaMenetlus').then(function () {
                    TeenusKinnitamine.lopetaMenetlus($scope.teenusId).then(function () {
                        $scope.loadTeenusData();
                    });
                }
            );
        };

        $scope.getFileDownloadUrl = function (dokumentFailId) {
            return FileDownloadService.getFileDownloadUrl(dokumentFailId);
        };

        $scope.loadTeenusData();

        var setButtons = function () {
            var buttons = [];

            if ($scope.independent && $scope.showStartChangeBtn) {
                buttons.push({name: 'teenus.detailvaade.nupp.alusta_muudatust', onClick: $scope.startChange});
            }
            if ($scope.staatusKontrollimisel) {
                buttons.push({name: 'teenus.detailvaade.lopeta_menetlus', onClick: $scope.lopetaMenetlus});
            }
            if ($scope.teenus.operations.showSeostaProtokoll) {
                buttons.push({name: 'teenus.detailvaade.seosta_protokoll', onClick: $scope.seostaProtokoll});
            }
            if ($scope.teenus.operations.showLooProtokoll) {
                buttons.push({name: 'teenus.detailvaade.loo_protokoll', onClick: $scope.looProtokoll});
            }
            $scope.buttons = buttons;
        };

    }
);
*/

'use strict';

angular.module('register.taimekaitse.taimekaitsevahend.detailvaade').directive('pmaTeTkvTaiendavInformatsioonDirective',
    function (notifyService,
              domeenEnum,
              domeenService) {
        return {
            restrict: 'E',
            scope: {
                taimekaitsevahend: '=',
                language: '='
            },
            templateUrl: 'modules/taimekaitse/taimekaitsevahend/detailvaade/komponendid/7-taiendav-informatsioon/te-tk-tkv-taiendav-informatsioon-directive.html',
            link: function ($scope) {
            }
        };
    });

angular.module('commons.date', []).factory('dateService', function(config) {

    var DatePicker = function(options) {
        this.dateFormat = config.dateFormat;
        this.opened = false;
        this.options = angular.extend({
            'starting-day': 1,
            'show-weeks': false,
            'min-date': null,
            'max-date': null
        }, options);
    };

    DatePicker.prototype.toggle = function($event) {
        $event.preventDefault();
        $event.stopPropagation();
        this.opened = !this.opened;
    };

    return {
        newDatePicker: function(options) {
            return new DatePicker(options);
        }
    };

}).filter('pmaDate', function($filter, $translate, config) {
    var angularDateFilter = $filter('date');

    return function(date, mode, lang) {
        if (!lang) {
            lang = 'et';
        }
        if (!date) {
            return $translate.instant('yld.maaramata', null, null, lang, null);
        }

        var format;
        switch (mode) {
            case 'date':
                format = config.dateFormat;
                break;
            case 'time':
                format = config.timeFormat;
                break;
            default:
                format = config.dateTimeFormat;
        }
        return angularDateFilter(date, format);
    };
}).filter('pmaPeriod', function($filter, $translate) {
    var pmaDateFilter = $filter('pmaDate');
    var whitespaceDash = ' - ';

    return function(start, end) {
        if (start && end) {
            return pmaDateFilter(start, 'date') + whitespaceDash + pmaDateFilter(end, 'date');
        } else if (start && !end) {
            return pmaDateFilter(start, 'date') + whitespaceDash + $translate.instant('yld.maaramata');
        } else if (!start && end) {
            return $translate.instant('yld.maaramata') + whitespaceDash + pmaDateFilter(end, 'date');
        } else {
            return $translate.instant('yld.maaramata');
        }
    };
}).run(function($locale, $translate, config) {
    moment.locale(config.languageCode);

    _.mixin({
        toDateFromResponse: function(dateFromResponse) {
            return dateFromResponse ? moment(dateFromResponse, moment.defaultFormat).toDate() : null;
        },
        toDateFromResponse2: function(dateFromResponse) {
            if (dateFromResponse && angular.isNumber(dateFromResponse)) {
                return new Date(dateFromResponse);
            }
            return dateFromResponse;
        },
        toDateStringFromAnyResponse: function(dateFromResponse) {
            return dateFromResponse ? moment(dateFromResponse).format('YYYY-MM-DD') : null;
        }
    });
});

'use strict';

angular.module('tabel.asukoht', []).directive('asukohtTabel', [
    function () {
        return {
            restrict: 'E',
            scope: {
                taotlus: '='
            },
            controller: ['$scope', function(scope) {
            }],
            templateUrl: 'modules/commons/tabel/tabel-asukoht.html'
        };
    }
]);

'use strict';

angular.module('commons.general')
    .directive('pmaSearchButton', function () {
        return {
            restrict: 'E',
            scope: {
                ngClick: '&'
            },
            templateUrl: 'modules/commons/elements/pma-search-button.html'
        };
    });
    
'use strict';

angular.module('register.sort').controller('SortOtsingCtrl', function ($rootScope, $scope, $routeParams, $filter, $q, ngTableHelperFactory, StateReminderService, notifyService, domeenService, domeenEnum) {
        $scope.language = $routeParams.lang;
        $scope.domeenEnum = domeenEnum;


        $scope.datePickerOptions = {};
        $scope.activeTab = 'sort';
        $scope.registreerimineStaatusKl = [];
        $scope.sordilehtStaatusKl = [];
        $scope.sordikaitseStaatusKl = [];
        $scope.geneetilineRessurssSordilehtStaatusKl = [];
        $scope.heterogeenneMaterjalSordilehtStaatusKl = [];
        $scope.gruppKl = [];
        $scope.kultuurKl = [];

        $scope.filter = {};

        domeenService.klassifikaatorQuery(domeenEnum.PMA_SORT_REGISTREERIMINE_STAATUS.nimi, function (result) {
            $scope.registreerimineStaatusKl = result;
        });

        domeenService.klassifikaatorQuery(domeenEnum.PMA_SORT_SL_STAATUS.nimi, function (result) {
            $scope.sordilehtStaatusKl = result;
        });

        domeenService.klassifikaatorQuery(domeenEnum.PMA_SORT_SK_STAATUS.nimi, function (result) {
            $scope.sordikaitseStaatusKl = result;
        });

        domeenService.klassifikaatorQuery(domeenEnum.PMA_SORT_GR_STAATUS.nimi, function (result) {
            $scope.geneetilineRessurssSordilehtStaatusKl = result;
        });

        domeenService.klassifikaatorQuery(domeenEnum.PMA_TAIM_LIIK_KULTUUR.nimi, function (result) {
            $scope.kultuurKl = result;
        });

        domeenService.klassifikaatorQuery(domeenEnum.PMA_TAIM_GRUPP.nimi, function (result) {
            $scope.gruppKl = result;
        });

        domeenService.klassifikaatorQuery(domeenEnum.PMA_SORT_HETM_STAATUS.nimi, function (result) {
          $scope.heterogeenneMaterjalSordilehtStaatusKl = result;
        });

        $scope.activeTab = 'sortOtsing';

        $scope.preserveState = function () {
            StateReminderService.preserveState($scope);
        };
        if (StateReminderService.hasPreviousState()) {
            var state = StateReminderService.getPreviousState();
            $scope.stateGiven = true;
            $scope.tableParams = state.scope.tableParams;
            $scope.filter = state.scope.filter;
            $scope.tableParams.reload();
        }
        else {
            $scope.tableParams = ngTableHelperFactory.newNgTableParamsAutoLoad('/v1/public/register/sort/nimekiri', $scope.filter, {
                'muutmisAeg': 'desc'
            });
        }

        $scope.search = function () {
            $scope.tableParams.search();
        };
        $scope.navigateTeenus = function (teenusId) {
            $scope.preserveState();
        };
    }
);

    
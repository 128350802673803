'use strict';

angular.module('register.taimetervis')
    .directive('taimetervisTegevusaladDirective', function () {
        return {
            restrict: 'E',
            scope: {
                registerOtsingDto: "="
            },
            templateUrl: 'modules/taimetervis/taimetervis-tegevusalad.html',
            link: function (scope) {
                scope.tegevusalad = scope.registerOtsingDto.tegevusalad;
            }
        }
    });
